import React, { Component } from "react";
import { Link } from "react-router-dom";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  Row,
  Col,
  Media,
} from "reactstrap";
import SimpleBar from "simplebar-react";
import SocketComponent from "../../SocketComponent/SocketComponent";
//i18b
import { withTranslation } from "react-i18next";
import notificationSound from "./Chat-Alert.wav";
import { toast } from "react-toastify"; // Import toast
import { getAdminNotificationList } from "../../../Endpoint/Admin";
//Import images
import avatar3 from "../../../assets/images/users/avatar-3.jpg";
import avatar4 from "../../../assets/images/users/avatar-4.jpg";
import { Field } from "redux-form";

class NotificationDropdown extends Component {
  constructor(props) {
    super(props);
    this.state = {
      menu: false,
      notifications: [],
    };

    this.toggle = this.toggle.bind(this);
    this.audio = new Audio(notificationSound);
  }

  componentDidMount() {
    // Call the API when the component mounts
    this.fetchNotifications();
  }

  fetchNotifications() {
    // Call your API to fetch notifications
    getAdminNotificationList()
      .then((response) => {
        // Assuming the API response is an array of notifications
        this.setState({ notifications: response.data });
        console.log(response);
      })
      .catch((error) => {
        console.error("Error fetching notifications:", error);
      });
  }

  //   1, // Food
  //   2, // Room
  //   3, // Table
  //   4, // Custom
  //   5, // Taxi
  //   6, //Chat

  getNotificationPath = (notificationType) => {
    switch (notificationType) {
      case 1:
        return "FoodOrder"; // Replace with the actual path for Chat notifications
      case 2:
        return "Booking";
      case 3:
        return "TableBooking";
      case 4:
        return "/";
      case 5:
        return "Taxi";
      case 6:
        return "ChatList";
      case "OtherType":
        return "OtherPath"; // Replace with the actual path for OtherType notifications
      // Add more cases for different notification types and their respective paths
      default:
        return "/";
    }
  };

  getNotificationInfoIcon = (notificationType) => {
    switch (notificationType) {
      case 1:
        return "ri-restaurant-line";
      case 2:
        return "ri-hotel-bed-fill";
      case 3:
        return "ri-table-fill";
      case 4:
        return "ri-forbid-fill";
      case 5:
        return "ri-taxi-line";
      case 6:
        return "ri-message-2-fill";
      case "OtherType":
        return "ri-forbid-fill";
      default:
        return "ri-forbid-fill";
    }
  };

  handleNewNotification = (data) => {
    this.setState((prevState) => ({
      //   menu: true, // Open the dropdown when a new notification is received
      notifications: [...prevState.notifications, data],
    }));

    this.audio.play();
    console.log("data ala re bhau:", data);
    toast.success(data, {
      position: "bottom-left",
      autoClose: false,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  toggle() {
    const { menu } = this.state;

    // If the dropdown is being opened
    if (!menu) {
      this.fetchNotifications();
    }

    this.setState((prevState) => ({
      menu: !prevState.menu,
    }));
  }
  render() {
    const { notifications } = this.state;

    return (
      <React.Fragment>
        <Dropdown
          isOpen={this.state.menu}
          toggle={this.toggle}
          className="d-inline-block"
        >
          <DropdownToggle
            tag="button"
            className="btn header-item noti-icon waves-effect"
            id="page-header-notifications-dropdown"
          >
            <i className="ri-notification-3-line"></i>
            <span className="noti-dot"></span>
          </DropdownToggle>
          <DropdownMenu
            right
            className="dropdown-menu-lg p-0"
            aria-labelledby="page-header-notifications-dropdown"
          >
            <div className="p-3">
              <Row className="align-items-center">
                <Col>
                  <h6 className="m-0"> {this.props.t("Notifications")} </h6>
                </Col>
                {/* <div className="col-auto">
                  <Link to="#" className="small">
                    {" "}
                    {this.props.t("View All")}
                  </Link>
                </div> */}
              </Row>
            </div>
            {/* <SimpleBar style={{ maxHeight: "230px" }}>
              <Link to="#" className="text-reset notification-item">
                <Media>
                  <div className="avatar-xs mr-3">
                    <span className="avatar-title bg-primary rounded-circle font-size-16">
                      <i className="ri-shopping-cart-line"></i>
                    </span>
                  </div>
                  <Media body>
                    <h6 className="mt-0 mb-1">
                      {this.props.t("Your order is placed")}
                    </h6>
                    <div className="font-size-12 text-muted">
                      <p className="mb-1">
                        {this.props.t(
                          "If several languages coalesce the grammar"
                        )}
                      </p>
                      <p className="mb-0">
                        <i className="mdi mdi-clock-outline"></i>{" "}
                        {this.props.t("3 min ago")}
                      </p>
                    </div>
                  </Media>
                </Media>
              </Link>
              <Link to="#" className="text-reset notification-item">
                <Media>
                  <img
                    src={avatar3}
                    className="mr-3 rounded-circle avatar-xs"
                    alt="user-pic"
                  />
                  <Media body>
                    <h6 className="mt-0 mb-1">
                      {this.props.t("James Lemire")}
                    </h6>
                    <div className="font-size-12 text-muted">
                      <p className="mb-1">
                        {this.props.t("It will seem like simplified English.")}
                      </p>
                      <p className="mb-0">
                        <i className="mdi mdi-clock-outline"></i>{" "}
                        {this.props.t("1 hours ago")}
                      </p>
                    </div>
                  </Media>
                </Media>
              </Link>
              <Link to="#" className="text-reset notification-item">
                <Media>
                  <div className="avatar-xs mr-3">
                    <span className="avatar-title bg-success rounded-circle font-size-16">
                      <i className="ri-checkbox-circle-line"></i>
                    </span>
                  </div>
                  <Media body>
                    <h6 className="mt-0 mb-1">
                      {this.props.t("Your item is shipped")}
                    </h6>
                    <div className="font-size-12 text-muted">
                      <p className="mb-1">
                        {this.props.t(
                          "If several languages coalesce the grammar"
                        )}
                      </p>
                      <p className="mb-0">
                        <i className="mdi mdi-clock-outline"></i>{" "}
                        {this.props.t("3 min ago")}
                      </p>
                    </div>
                  </Media>
                </Media>
              </Link>

              <Link to="#" className="text-reset notification-item">
                <Media>
                  <img
                    src={avatar4}
                    className="mr-3 rounded-circle avatar-xs"
                    alt="user-pic"
                  />
                  <Media body>
                    <h6 className="mt-0 mb-1">
                      {this.props.t("Salena Layfield")}
                    </h6>
                    <div className="font-size-12 text-muted">
                      <p className="mb-1">
                        {this.props.t(
                          "As a skeptical Cambridge friend of mine occidental."
                        )}
                      </p>
                      <p className="mb-0">
                        <i className="mdi mdi-clock-outline"></i>{" "}
                        {this.props.t("1 hours ago")}
                      </p>
                    </div>
                  </Media>
                </Media>
              </Link>
            </SimpleBar> */}
            <SimpleBar style={{ maxHeight: "230px" }}>
              {/* Map over notifications and create dynamic notification items */}
              {Array.isArray(this.state.notifications) &&
                this.state.notifications.map((notification) => (
                  <Link
                    to={this.getNotificationPath(notification.type)}
                    className="text-reset notification-item"
                    key={notification._id}
                  >
                    <Media>
                      <div className="avatar-xs mr-3">
                        <span className="avatar-title bg-primary rounded-circle font-size-16">
                          <i
                            className={this.getNotificationInfoIcon(
                              notification.type
                            )}
                          ></i>
                        </span>
                      </div>
                      <Media body>
                        <h6 className="mt-0 mb-1">{notification.title}</h6>
                        <div className="font-size-12 text-muted">
                          <p className="mb-1">{notification.details}</p>
                          <p className="mb-0">
                            <i className="mdi mdi-clock-outline"></i>{" "}
                            {new Date(notification.createdAt).toLocaleString()}
                          </p>
                        </div>
                      </Media>
                    </Media>
                  </Link>
                ))}
            </SimpleBar>
            {/* <div className="p-2 border-top">
              <Link
                to="#"
                className="btn btn-sm btn-link font-size-14 btn-block text-center"
              >
                <i className="mdi mdi-arrow-right-circle mr-1"></i>
                {this.props.t(" View More")}
              </Link>
            </div> */}
          </DropdownMenu>
        </Dropdown>
        <SocketComponent onNewNotification={this.handleNewNotification} />
      </React.Fragment>
    );
  }
}
export default withTranslation()(NotificationDropdown);
