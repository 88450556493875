import React, { Component } from "react";
import {
  MDBDataTable,
  MDBTableHead,
  MDBTableBody,
  MDBTable,
  MDBBtn,
} from "mdbreact";
import {
  AvInput,
  Card,
  CardBody,
  Col,
  Form,
  Row,
  FormGroup,
  Label,
  Input,
  CustomInput,
  Button,
  Modal,
  Container,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import {
  createTable,
  updateTable,
  removetable,
  getTableList,
  gettableId,
  updatetablestatus,
} from "../../Endpoint/Admin/index";
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

import { ToastContainer, toast } from "react-toastify";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider from "react-bootstrap-table2-toolkit";

import { AvForm, AvField } from "availity-reactstrap-validation";

class FormElements extends Component {
  constructor(props) {
    super(props);
    this.state = {
      invalidFile: false,
      previewImage: undefined,
      items: [],
      isLoaded: false,
      updatemodal: false,
      modal: false,
      tableNo: "",
      tableId: null,
      finalAmount: "",
      person: "",
      seats: "",
      success: {},
      breadcrumbItems: [
        { title: "", link: "#" },
        { title: " ", link: "#" },
      ],
      customchk: true,
      toggleSwitch: true,
      tableData: {
        columns: [
          {
            label: "Table No",
            field: "tableNo",
            sort: "asc",
            width: 60,
          },
          {
            label: "Person (Single, Couple, Family)",
            field: "person",
            sort: "asc",
            width: 60,
          },
          {
            label: "Seats",
            field: "seats",
            sort: "asc",
            width: 60,
          },
          {
            label: "price",
            field: "finalAmount",
            sort: "asc",
            width: 60,
          },

          {
            label: "Update",
            field: "update",
            sort: "asc",
            width: 100,
          },

          {
            label: "Status",
            field: "remove",
            sort: "asc",
            width: 100,
          },

          {
            label: "Occupied/Free",
            field: "freeTable",
            sort: "asc",
            width: 100,
          },
        ],
        rows: [],
      },
    };
    this.reader = new FileReader();
    this.handleChange = this.handleChange.bind(this);

    this.handleSubmit = this.handleSubmit.bind(this);
    this.toggle = this.toggle.bind(this);

    this.handleCloseModal = this.handleCloseModal.bind(this);
  }
  toggle() {
    this.setState({
      modal: !this.state.modal,
      tableNo: "",
      tableId: "",
      finalAmount: "",
      person: "",
      seats: "",
    });
  }
  updatetoggle() {
    this.setState({
      updatemodal: !this.state.updatemodal,
    });
  }

  handleCloseModal() {
    this.setState({
      updatemodal: false,
      modal: false,
    });
  }

  handleChange = (e) => {
    this.setState(
      {
        [e.target.id]: e.target.value,
      },
      () => {}
    );
  };

  handleSubmit(e) {
    e.preventDefault();

    const tableData = {
      tableNo: this.state.tableNo,
      tableId: this.state.tableId,
      finalAmount: this.state.finalAmount,
      seats: this.state.seats,
      person: this.state.person,
    };
    // console.log(tableData);

    if (this.state.tableId == "" || this.state.tableId == null) {
      createTable(tableData)
        .then((response) => {
          console.log(response);
          if (response.status !== 200) {
            toast.error(response.message, {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          } else {
            this.setState({
              handleResponse: {
                isSuccess: response.status === 200,
                message: "success",
                tableNo: "",
                tableId: "",
                finalAmount: "",
                seats: "",
                person: "",
              },
            });
            this.getALlluser();

            this.handleCloseModal();

            toast.success("Table added successfully");
          }
        })
        .catch((error) => {
          toast.error("error");
        });
    } else {
      updateTable(tableData)
        .then((updateResults) => {
          if (updateResults.status !== 200) {
            toast.error(updateResults.message, {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          } else {
            this.setState({
              handleResponse: {
                isSuccess: updateResults.status === 200,
                message: "success",
                tableId: "",
              },
            });
            this.getALlluser();

            this.handleCloseModal();

            toast.success("Table updated successfully");
          }
        })
        .catch((error) => {
          toast.error("error");
        });
    }
  }

  removeTable(id, isActive) {
    const data = {
      tableId: id,
      isActive: isActive,
    };

    removetable(data)
      .then((results) => {
        console.log(results);
        if (results.status !== 200) {
          toast.error(results.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        } else {
          toast.success("Table status updated successfully");
        }
        this.getALlluser();
      })
      .catch((error) => {
        toast.error("error");
      });
  }

  freeTable(id, isOccupied) {
    const data = {
      tableId: id,
      isOccupied: isOccupied,
    };
    console.log(data);
    updatetablestatus(data)
      .then((results) => {
        console.log(results);
        if (results.status !== 200) {
          toast.error(results.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        } else {
          toast.success("Table Occupied  status updated successfully");
        }
        this.getALlluser();
      })
      .catch((error) => {
        toast.error("error");
      });
  }

  getALlluser() {
    getTableList().then((result) => {
      this.setState({
        isLoaded: true,
        items: result.data,
        tableData: {
          ...this.state.tableData,
          rows: result.data,
          list: result.data.map((x) => {
            x.isActive = x.isActive === true ? false : true;
            x.isOccupied = x.isOccupied === true ? false : true;
            x.update = (
              <button
                className="btn btn-primary"
                onClick={() => this.editTable(x._id.toString(), x)}
              >
                Update
              </button>
            );
            x.remove = (
              <button
                className="btn btn-danger"
                onClick={() => this.removeTable(x._id.toString(), x.isActive)}
              >
                {x.isActive === true ? "Deactive" : "Active"}
              </button>
            );
            x.freeTable = (
              <button
                className="btn btn-danger"
                onClick={() => this.freeTable(x._id.toString(), x.isOccupied)}
              >
                {x.isOccupied === true ? "Free" : "Occupied"}
              </button>
            );
            return x;
          }),
        },
      });
    });
  }

  componentDidMount() {
    this.getALlluser();
  }
  editTable(id) {
    gettableId(id)
      .then((results) => {
        console.log(results);
        this.setState({
          tableNo: results.data.tableNo,
          tableId: results.data._id,
          finalAmount: results.data.finalAmount,
          seats: results.data.seats,
          person: results.data.person,
        });
      })
      .catch((error) => {
        toast.error("error");
      });
    this.toggle();
  }

  render() {
    const { handleResponse, items } = this.state;

    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Breadcrumbs
              title=""
              breadcrumbItems={this.state.breadcrumbItems}
            />

            <Row>
              <Col xs={12}>
                <Card>
                  <CardBody>
                    <Button color="primary" onClick={this.toggle}>
                      Add Table
                    </Button>
                    <Modal
                      isOpen={this.state.modal}
                      toggle={this.toggle}
                      className={this.props.className}
                    >
                      <ModalHeader toggle={this.toggle}>
                        Table Details
                      </ModalHeader>
                      <ModalBody>
                        <AvForm onValidSubmit={this.handleSubmit}>
                          <Row form>
                            <Col md={6}>
                              <FormGroup>
                                <Label for="email">Table Number</Label>
                                <AvField
                                  type="number"
                                  min={1}
                                  value={this.state.tableNo}
                                  onChange={this.handleChange}
                                  id="tableNo"
                                  name="tableNo"
                                  required
                                />
                              </FormGroup>
                            </Col>

                            <Col md={6}>
                              <FormGroup>
                                <Label for="email">Amount (in usd)</Label>
                                <AvField
                                  type="number"
                                  min={1}
                                  value={this.state.finalAmount}
                                  onChange={this.handleChange}
                                  id="finalAmount"
                                  name="finalAmount"
                                  required
                                />
                              </FormGroup>
                            </Col>
                          </Row>

                          <Row form>
                            <Col md={6}>
                              <FormGroup>
                                <Label for="email">
                                  Total Seat (No of people can sit on the table)
                                </Label>
                                <AvField
                                  type="text"
                                  value={this.state.seats}
                                  onChange={this.handleChange}
                                  id="seats"
                                  name="seats"
                                  required
                                />
                              </FormGroup>
                            </Col>

                            <Col md={6}>
                              <FormGroup>
                                <Label for="email">Table Occupancy Type</Label>
                                <Input
                                  type="select"
                                  value={this.state.person}
                                  onChange={this.handleChange}
                                  id="person"
                                  name="person"
                                  required
                                >
                                  <option>Select Person</option>
                                  <option value="Single">Single</option>
                                  <option value="Couple">Couple</option>
                                  <option value="Family">Family</option>
                                </Input>
                              </FormGroup>
                            </Col>
                          </Row>

                          <div className="text-center mt-4">
                            <Button color="primary" size="sm">
                              Submit
                            </Button>{" "}
                          </div>
                        </AvForm>
                      </ModalBody>
                    </Modal>
                  </CardBody>
                </Card>
              </Col>
            </Row>

            <Breadcrumbs
              title="Data Tables"
              breadcrumbItems={this.state.breadcrumbItems}
            />

            <Row>
              <Col xs={12}>
                <Card>
                  <CardBody>
                    <h4 className="card-title">Table </h4>
                    <p className="card-title-desc"></p>

                    <MDBDataTable
                      responsive
                      bordered
                      data={this.state.tableData}
                    />
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
        <ToastContainer />
      </React.Fragment>
    );
  }
}

export default FormElements;
