import React, { Component } from "react";
import {
  MDBDataTable,
  MDBTableHead,
  MDBTableBody,
  MDBTable,
  MDBBtn,
} from "mdbreact";
import {
  AvInput,
  Card,
  CardBody,
  Col,
  Form,
  Row,
  FormGroup,
  Label,
  Input,
  CustomInput,
  Button,
  Modal,
  Container,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import Select from "react-select";
import {
  createRoom,
  getRoomFeatureList,
  removeRoom,
  getRoomList,
  getRoomTypeList,
  getRoomHospitalityServiceList,
  getRoomById,
  updateRoom,
} from "../../Endpoint/Admin/index";
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

import { ToastContainer, toast } from "react-toastify";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider from "react-bootstrap-table2-toolkit";

import { AvForm, AvField } from "availity-reactstrap-validation";

class FormMask extends Component {
  constructor(props) {
    super(props);
    this.state = {
      invalidFile: false,
      roomId: "",
      items: [],
      roomtypeData: [],
      roomFeaturesData: [],
      hospitalityServicesData: [],
      isLoaded: false,
      updatemodal: false,
      modal: false,
      availability: true,
      roomName: "",
      roomType: "",
      beds: "",
      bedType: "",
      bathroomType: "",
      foodAndBeverages: "",
      kitchenFeatures: "",
      entertainment: "",
      roomFeatures: null,
      hospitalityServices: null,
      selectedFile: null,
      previewImage: [],
      invalidImage: null,
      roomFurnitureAndFurnishing: "",
      reviews: "",
      selectedFileRoom: null,
      previewImageRoom: [],
      invalidRoomImage: null,
      bath: "",
      success: {},
      breadcrumbItems: [
        { title: "", link: "#" },
        { title: " ", link: "#" },
      ],
      customchk: true,
      toggleSwitch: true,
      tableData: {
        columns: [
          {
            label: "Room Name",
            field: "roomName",
            sort: "asc",
            width: 60,
          },

          {
            label: "Update",
            field: "update",
            sort: "asc",
            width: 100,
          },

          {
            label: "Status",
            field: "remove",
            sort: "asc",
            width: 100,
          },
        ],
        rows: [],
      },
    };
    this.reader = new FileReader();
    this.handleChange = this.handleChange.bind(this);
    this.onFileChange = this.onFileChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.toggle = this.toggle.bind(this);
    this.onRoomFileChange = this.onRoomFileChange.bind(this);
    this.ShortlistedUser = this.ShortlistedUser.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
    this.handlefeatureChange = this.handlefeatureChange.bind(this);
    this.handlehospitalityChange = this.handlehospitalityChange.bind(this);
  }
  toggle() {
    this.setState({
      modal: !this.state.modal,
      roomId: "",
      roomName: "",
      roomType: "",
      beds: "",
      bedType: "",
      availability: "",
      bathroomType: "",
      foodAndBeverages: "",
      kitchenFeatures: "",
      entertainment: "",
      roomFeatures: "",
      hospitalityServices: "",
      selectedFile: "",
      previewImage: "",
      reviews: "",
      selectedFileRoom: "",
      previewImageRoom: "",
      bath: "",
      invalidImage: null,
      invalidRoomImage: null,
    });
  }
  updatetoggle() {
    this.setState({
      updatemodal: !this.state.updatemodal,
    });
  }

  handleCloseModal() {
    this.setState({
      updatemodal: false,
      modal: false,
    });
  }

  ShortlistedUser() {
    this.setState({
      availability: !this.state.availability,
    });
  }

  handleChange = (e) => {
    this.setState(
      {
        [e.target.id]: e.target.value,
      },
      () => {}
    );
  };

  handlefeatureChange = (selectedOptions) => {
    // const selected = [];

    // let selectedOption = (selectedOptions);
    // for (let i = 0; i < selectedOption.length; i++) {

    //   selected.push(selectedOption[i].value)

    // }

    this.setState({
      roomFeatures: [...selectedOptions],
      // roomFeatures: selected
    });
  };

  handlehospitalityChange = (selectedOptions) => {
    console.log("selectedOptions", selectedOptions);
    // const selected = [];

    // let selectedOption = (selectedOptions);
    // for (let i = 0; i < selectedOption.length; i++) {

    //   selected.push(selectedOption[i].value)

    // }

    this.setState({
      hospitalityServices: [...selectedOptions],
      // hospitalityServices: selected
    });
  };

  onFileChange(event) {
    const imageFile = event.target.files;
    for (let i = 0; i < imageFile.length; i++) {
      if (!imageFile[i].name.match(/\.(jpg|jpeg|png)$/)) {
        this.setState({
          invalidImage: "Please select valid image.",
          previewImage: [...event.target.files].map(URL.createObjectURL),
        });
        return false;
      } else {
        this.setState({
          selectedFile: event.target.files,
          previewImage: [...event.target.files].map(URL.createObjectURL),
          invalidImage: null,
        });
      }
    }
    // this.setState({
    //   selectedFile: event.target.files,
    //   previewImage: [...event.target.files].map(URL.createObjectURL),

    // });
  }
  onRoomFileChange(event) {
    const imageFile = event.target.files;
    for (let i = 0; i < imageFile.length; i++) {
      if (!imageFile[i].name.match(/\.(jpg|jpeg|png)$/)) {
        this.setState({
          invalidRoomImage: "Please select valid image.",
          previewImageRoom: [...event.target.files].map(URL.createObjectURL),
        });
        return false;
      } else {
        this.setState({
          selectedFileRoom: event.target.files,
          previewImageRoom: [...event.target.files].map(URL.createObjectURL),
          invalidRoomImage: null,
        });
      }
    }
    // this.setState({
    //   selectedFileRoom: event.target.files,
    //   previewImageRoom:  [...event.target.files].map(URL.createObjectURL)

    // });
  }

  roomtypes() {
    getRoomTypeList()
      .then((result) => {
        this.setState({
          roomtypeData: result.data,
        });
      })
      .catch((error) => {
        console.log("error ---> ", error);
      });
  }

  RoomFeatureList() {
    getRoomFeatureList()
      .then((result) => {
        this.setState({
          roomFeaturesData: result.data,
        });
        const dropDownValues = this.state.roomFeaturesData.map((response) => ({
          value: response._id,
          label: response.featureName,
        }));
        this.setState({
          dropDownOpts: dropDownValues,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  }
  HospitalityServiceList() {
    getRoomHospitalityServiceList()
      .then((result) => {
        this.setState({
          hospitalityServicesData: result.data,
        });
        const dropDownValue = this.state.hospitalityServicesData.map(
          (response) => ({
            value: response._id,
            label: response.hospitalityServiceName,
          })
        );
        this.setState({
          dropDownOpt: dropDownValue,
        });
      })
      .catch((error) => {});
  }
  handleSubmit(e) {
    e.preventDefault();

    let hospitality = [];
    this.state.hospitalityServices.map((each) => {
      hospitality.push(each.value);
      return each;
    });

    let room = [];
    this.state.roomFeatures.map((each) => {
      room.push(each.value);
      return each;
    });
    console.log("In handleSubmit", hospitality);
    const formData = new FormData();
    formData.append("bath", this.state.bath);
    formData.append("roomName", this.state.roomName);
    formData.append("roomTypeId", this.state.roomType);
    formData.append("beds", this.state.beds);
    formData.append("bedType", this.state.bedType);
    formData.append("availability", this.state.availability);
    formData.append("foodAndBeverages", this.state.foodAndBeverages);
    formData.append("bathroomType", this.state.bathroomType);
    formData.append("kitchenFeatures", this.state.kitchenFeatures);
    formData.append("entertainment", this.state.entertainment);
    formData.append("roomFeatures", room);
    formData.append("hospitalityServices", hospitality);
    for (var x = 0; x < this.state.selectedFileRoom.length; x++) {
      formData.append("roomImage", this.state.selectedFileRoom[x]);
    }
    for (var i = 0; i < this.state.selectedFile.length; i++) {
      formData.append("roomFurnitureAndFurnishing", this.state.selectedFile[i]);
    }
    formData.append("roomId", this.state.roomId);
    console.log(this.state.roomFeatures);
    console.log(this.state.selectedFile);

    if (this.state.roomId == "" || this.state.roomId == null) {
      createRoom(formData)
        .then((response) => {
          if (response.status !== 200) {
            toast.error(response.message, {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          } else {
            this.setState({
              handleResponse: {
                isSuccess: response.status === 200,
                message: "success",
                roomName: "",
                roomType: "",
                availability: false,
                beds: "",
                bedType: "",
                bathroomType: "",
                foodAndBeverages: "",
                kitchenFeatures: "",
                entertainment: "",
                roomFeatures: "",
                hospitalityServices: "",
                selectedFile: "",
                previewImage: "",
                reviews: "",
                invalidImage: "",
                invalidRoomImage: "",
              },
            });
            this.getALlluser();

            this.handleCloseModal();

            toast.success("Room  added successfully");
          }
        })
        .catch((error) => {
          toast.error("error");
        });
    } else {
      for (let [key, value] of formData.entries()) {
        console.log(key, value);
      }
      updateRoom(formData)
        .then((updateResults) => {
          if (updateResults.status !== 200) {
            toast.error(updateResults.message, {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          } else {
            this.setState({
              handleResponse: {
                isSuccess: updateResults.status === 200,
                message: "",
                roomId: "",
              },
            });
            this.getALlluser();

            this.handleCloseModal();

            toast.success("Room  updated successfully");
          }
        })
        .catch((error) => {
          toast.error("error");
        });
    }
  }

  removeRoom(id, isActive) {
    const data = {
      roomId: id,
      isActive: isActive,
    };
    removeRoom(data)
      .then((results) => {
        if (results.status !== 200) {
          toast.error(results.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        } else {
          toast.success("Room status changed successfully");
        }
        this.getALlluser();
      })
      .catch((error) => {
        toast.error("error");
      });
  }

  getALlluser() {
    getRoomList().then((result) => {
      this.setState({
        isLoaded: true,
        items: result.data,
        tableData: {
          ...this.state.tableData,
          rows: result.data,
          list: result.data.map((x) => {
            x.isActive = x.isActive === true ? false : true;
            x.update = (
              <button
                className="btn btn-primary"
                onClick={() => this.editRoom(x._id.toString(), x)}
              >
                Update
              </button>
            );
            x.remove = (
              <button
                className="btn btn-danger"
                onClick={() => this.removeRoom(x._id.toString(), x.isActive)}
              >
                {x.isActive === true ? "Deactive" : "Active"}
              </button>
            );

            return x;
          }),
        },
      });
    });
  }
  editRoom(id) {
    console.log(id);
    getRoomById(id)
      .then((results) => {
        console.log(results);
        let roomFeaturescopy = [];
        let hospitalityServicescopy = [];
        let copyroompreview = [];
        let copyroomfile = [];
        let copyfurniturepreview = [];
        let copyrurniturefile = [];
        for (var x = 0; x < results.data.roomImages.length; x++) {
          copyroompreview.push(results.data.roomImages[x].image);
          copyroomfile.push(results.data.roomImages[x].imageFile);
        }

        roomFeaturescopy = results.data.roomFeatures.map((response) => ({
          value: response._id,
          label: response.featureName,
        }));

        // for (let y = 0; y < results.data.roomFeatures.length; y++) {
        //   roomFeaturescopy.push(results.data.roomFeatures[y]._id)

        // }
        hospitalityServicescopy = results.data.hospitalityServices.map(
          (response) => ({
            value: response._id,
            label: response.hospitalityServiceName,
          })
        );
        // for (let k = 0; k < results.data.hospitalityServices.length; k++) {
        //   hospitalityServicescopy.push(results.data.hospitalityServices[k]._id)

        // }

        for (
          let j = 0;
          j < results.data.roomFurnitureAndFurnishing.length;
          j++
        ) {
          copyfurniturepreview.push(
            results.data.roomFurnitureAndFurnishing[j].image
          );
          copyrurniturefile.push(
            results.data.roomFurnitureAndFurnishing[j].imageFile
          );
        }

        this.setState({
          roomName: results.data.roomName,
          beds: results.data.beds,
          roomType: results.data.roomType._id,
          bedType: results.data.bedType,
          bathroomType: results.data.bathroomType,
          foodAndBeverages: results.data.foodAndBeverages,
          entertainment: results.data.entertainment,
          kitchenFeatures: results.data.kitchenFeatures,
          hospitalityServices: hospitalityServicescopy,
          availability: results.data.availability,
          // roomFeatures:roomFeaturescopy,
          bath: results.data.bath,
          selectedFile: copyrurniturefile,
          previewImage: copyfurniturepreview,
          selectedFileRoom: copyroomfile,
          previewImageRoom: copyroompreview,
          roomId: results.data._id,
          roomFeatures: roomFeaturescopy,
        });
      })
      .catch((error) => {
        console.log(error);
        toast.error("error");
      });
    this.toggle();
  }

  componentDidMount() {
    this.roomtypes();
    this.getALlluser();
    this.RoomFeatureList();
    this.HospitalityServiceList();
  }

  render() {
    const {
      handleResponse,
      invalidImage,
      invalidRoomImage,
      items,
      previewImage,
      previewImageRoom,
    } = this.state;

    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Breadcrumbs
              title=""
              breadcrumbItems={this.state.breadcrumbItems}
            />

            <Row>
              <Col xs={12}>
                <Card>
                  <CardBody>
                    <Button color="primary" onClick={this.toggle}>
                      Add Room
                    </Button>
                    <Modal
                      isOpen={this.state.modal}
                      toggle={this.toggle}
                      className={this.props.className}
                    >
                      <ModalHeader toggle={this.toggle}>
                        Room Details
                      </ModalHeader>
                      <ModalBody>
                        <AvForm onValidSubmit={this.handleSubmit}>
                          <Row form>
                            <Col md={6}>
                              <FormGroup>
                                <Label for="email">Room Name</Label>
                                <AvField
                                  type="text"
                                  value={this.state.roomName}
                                  onChange={this.handleChange}
                                  id="roomName"
                                  name="roomName"
                                  required
                                />
                              </FormGroup>
                            </Col>
                            <Col md={6}>
                              <FormGroup>
                                <Label for="roomType">RoomType</Label>
                                <Input
                                  type="select"
                                  name="roomType"
                                  id="roomType"
                                  value={this.state.roomType}
                                  onChange={this.handleChange}
                                  required
                                >
                                  <option>Select RoomType</option>
                                  {this.state.roomtypeData.map((e, key) => {
                                    return (
                                      <option key={key} value={e._id}>
                                        {e.type}
                                      </option>
                                    );
                                  })}
                                </Input>
                              </FormGroup>
                            </Col>
                          </Row>
                          <Row form>
                            <Col md={6}>
                              <FormGroup>
                                <Label for="username">No. of Beds</Label>
                                <AvField
                                  type="number"
                                  min={1}
                                  value={this.state.beds}
                                  onChange={this.handleChange}
                                  id="beds"
                                  name="beds"
                                  required
                                />
                              </FormGroup>
                            </Col>
                            <Col md={6}>
                              <FormGroup>
                                <Label for="University">BedType</Label>
                                <AvField
                                  type="text"
                                  value={this.state.bedType}
                                  onChange={this.handleChange}
                                  id="bedType"
                                  name="bedType"
                                  required
                                />
                              </FormGroup>
                            </Col>
                          </Row>
                          <Row form>
                            <Col md={6}>
                              <FormGroup>
                                <Label for="username">BathroomType</Label>
                                <AvField
                                  type="text"
                                  value={this.state.bathroomType}
                                  onChange={this.handleChange}
                                  id="bathroomType"
                                  name="bathroomType"
                                  required
                                />
                              </FormGroup>
                            </Col>
                            <Col md={6}>
                              <FormGroup>
                                <Label for="University">FoodAndBeverages</Label>
                                <AvField
                                  type="text"
                                  value={this.state.foodAndBeverages}
                                  onChange={this.handleChange}
                                  id="foodAndBeverages"
                                  name="foodAndBeverages"
                                  required
                                />
                              </FormGroup>
                            </Col>
                          </Row>
                          <Row form>
                            <Col md={6}>
                              <FormGroup>
                                <Label for="username">Bath</Label>
                                <AvField
                                  type="number"
                                  min={1}
                                  value={this.state.bath}
                                  onChange={this.handleChange}
                                  id="bath"
                                  name="bath"
                                  required
                                />
                              </FormGroup>
                            </Col>
                          </Row>
                          <Row form>
                            <Col md={6}>
                              <FormGroup>
                                <Label for="username">KitchenFeatures</Label>
                                <AvField
                                  type="text"
                                  value={this.state.kitchenFeatures}
                                  onChange={this.handleChange}
                                  id="kitchenFeatures"
                                  name="kitchenFeatures"
                                  required
                                />
                              </FormGroup>
                            </Col>
                            <Col md={6}>
                              <FormGroup>
                                <Label for="University">Entertainment</Label>
                                <AvField
                                  type="text"
                                  value={this.state.entertainment}
                                  onChange={this.handleChange}
                                  id="entertainment"
                                  name="entertainment"
                                  required
                                />
                              </FormGroup>
                            </Col>
                          </Row>

                          <Row form>
                            <Col md={6}>
                              <Label
                                htmlFor="example-number-input"
                                className="col-md-12 col-form-label"
                              >
                                RoomFurnitureAndFurnishing{" "}
                              </Label>

                              <div className="custom-file">
                                <AvField
                                  tag={CustomInput}
                                  name="file"
                                  id="file"
                                  type="file"
                                  accept="image/*"
                                  onChange={this.onFileChange}
                                  multiple
                                />
                                <Label
                                  className="custom-file-label"
                                  htmlFor="customFile"
                                >
                                  Choose file
                                </Label>
                              </div>
                              {(previewImage || []).map((url) => (
                                <img
                                  style={{
                                    borderRadius: "50%",
                                    width: 80,
                                    height: 80,
                                    // background: "red",
                                    display: "block",
                                  }}
                                  className="img-fluid"
                                  src={url}
                                  alt=""
                                />
                              ))}
                              <div>
                                {invalidImage && (
                                  <p className="error">{invalidImage}</p>
                                )}
                                <span> {this.state.selectedFileName}</span>
                                {handleResponse && (
                                  <p
                                    className={
                                      handleResponse.isSuccess ? "" : "error"
                                    }
                                  >
                                    {handleResponse.message}
                                  </p>
                                )}
                              </div>
                            </Col>

                            <Col md={6}>
                              <Label
                                htmlFor="example-number-input"
                                className="col-md-12 col-form-label"
                              >
                                Room Images{" "}
                              </Label>

                              <div className="custom-file">
                                <AvField
                                  tag={CustomInput}
                                  name="file"
                                  id="file"
                                  type="file"
                                  accept="image/*"
                                  onChange={this.onRoomFileChange}
                                  multiple
                                />
                                <Label
                                  className="custom-file-label"
                                  htmlFor="customFile"
                                >
                                  Choose file
                                </Label>
                              </div>
                              {(previewImageRoom || []).map((url) => (
                                <img
                                  style={{
                                    borderRadius: "50%",
                                    width: 80,
                                    height: 80,
                                    // background: "red",
                                    display: "block",
                                  }}
                                  className="img-fluid"
                                  src={url}
                                  alt=""
                                />
                              ))}

                              <div>
                                {invalidRoomImage && (
                                  <p className="error">{invalidRoomImage}</p>
                                )}
                                <span> {this.state.selectedFileName}</span>
                                {handleResponse && (
                                  <p
                                    className={
                                      handleResponse.isSuccess ? "" : "error"
                                    }
                                  >
                                    {handleResponse.message}
                                  </p>
                                )}
                              </div>
                            </Col>
                          </Row>

                          {/* <Row form>
<Col md={6}>
<Label htmlFor="example-number-input" className="col-md-12 col-form-label">Room Images </Label>

<div className="custom-file">
  
<AvField
              tag={CustomInput}
              name="file"
              id="file"
              type="file"
              accept="image/*"
              onChange={this.onRoomFileChange}
               
            />
<Label className="custom-file-label" htmlFor="customFile">Choose file</Label>
</div>    
{previewImageRoom && (
          <div>
            
            <img style={{
          borderRadius: "50%",
          width: 80,
          height: 80,
          // background: "red",
          display: "block"
        }} className="img-fluid" src={previewImageRoom}  alt="" />
          </div>
        )}
</Col>
</Row> */}
                          <div></div>
                          <Row style={{ marginTop: 16 }} form>
                            <Col md={6}>
                              <FormGroup>
                                <Label for="username">RoomFeatures</Label>

                                <Select
                                  className="dropdown"
                                  placeholder="Select Option"
                                  value={this.state.roomFeatures}
                                  onChange={this.handlefeatureChange}
                                  options={this.state.dropDownOpts} // set list of the data
                                  // assign onChange function

                                  isMulti
                                  isClearable
                                  // touchUi={false}
                                />
                              </FormGroup>
                            </Col>
                            <Col md={6}>
                              <FormGroup>
                                <Label for="University">
                                  HospitalityServices
                                </Label>
                                <Select
                                  className="dropdown"
                                  placeholder="Select Option"
                                  options={this.state.dropDownOpt} // set list of the data
                                  value={this.state.hospitalityServices}
                                  onChange={this.handlehospitalityChange} // assign onChange function
                                  isMulti
                                  isClearable
                                />
                                {/* <Input type="select" name="hospitalityServices" id="hospitalityServices" value={this.state.hospitalityServices} onChange={this.handleChange} required>
              <option>Select hospitalityServices</option>  
              {this.state.hospitalityServicesData.map((e, key) => {  
return <option key={key} value={e._id}>{e.hospitalityServiceName}</option>;  
})}  
        </Input> */}
                              </FormGroup>
                            </Col>
                          </Row>
                          <Row form>
                            <Col md={6}>
                              <FormGroup
                                style={{ paddingLeft: 20, paddingTop: 40 }}
                              >
                                <Label check>
                                  <Input
                                    type="checkbox"
                                    checked={this.state.availability}
                                    onChange={this.ShortlistedUser}
                                  />
                                  Availability
                                </Label>
                              </FormGroup>
                            </Col>
                          </Row>

                          <div className="text-center mt-4">
                            <Button color="primary" size="sm">
                              Submit
                            </Button>{" "}
                          </div>
                        </AvForm>
                      </ModalBody>
                    </Modal>
                  </CardBody>
                </Card>
              </Col>
            </Row>

            <Breadcrumbs
              title="Data Tables"
              breadcrumbItems={this.state.breadcrumbItems}
            />

            <Row>
              <Col xs={12}>
                <Card>
                  <CardBody>
                    <h4 className="card-title">Room DataTable </h4>
                    <p className="card-title-desc"></p>

                    <MDBDataTable
                      responsive
                      bordered
                      data={this.state.tableData}
                    />
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
        <ToastContainer />
      </React.Fragment>
    );
  }
}

export default FormMask;
