import axios from "axios";
import { getToken } from "../../config/TokenHelper";
export const AdminLogin = (data) => {
  return axios
    .post(process.env.REACT_APP_DEFAULTAUTH + "/api/admin/adminLogin", data, {
      headers: {
        "content-type": "application/json",
      },
    })
    .then((response) => {
      const { data } = response;
      return data;
    })
    .catch((issu) => {
      console.log("Issu ----> ", issu);
      return null;
    });
};

export const createRoomHospitalityService = (data) => {
  return axios
    .post(
      process.env.REACT_APP_DEFAULTAUTH +
        "/api/admin/createRoomHospitalityService",
      data,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          authToken: getToken().authT,
          authKey: getToken().authk,
        },
      }
    )
    .then((response) => {
      const { data } = response;
      return data;
    })
    .catch((issu) => {
      console.log("Issu ----> ", issu);
      return null;
    });
};

export const getRoomList = () => {
  return axios
    .get(
      process.env.REACT_APP_DEFAULTAUTH + "/api/admin/getRoomListAdmin",

      {
        headers: {
          authToken: getToken().authT,
          authKey: getToken().authk,
        },
      }
    )
    .then((response) => {
      const { data } = response;

      return data;
    })
    .catch((issu) => {
      console.log("Issu ----> ", issu);
      return null;
    });
};

export const getAdminNotificationList = () => {
  return axios
    .get(
      process.env.REACT_APP_DEFAULTAUTH +
        "/api/admin/getNotificationListAdminById",

      {
        headers: {
          authToken: getToken().authT,
          authKey: getToken().authk,
        },
      }
    )
    .then((response) => {
      const { data } = response;

      return data;
    })
    .catch((issu) => {
      console.log("Issu ----> ", issu);
      return null;
    });
};

export const getRoomFeatureList = () => {
  return axios
    .get(
      process.env.REACT_APP_DEFAULTAUTH + "/api/user/getRoomFeatureList",

      {
        headers: {
          authToken: getToken().authT,
          authKey: getToken().authk,
        },
      }
    )
    .then((response) => {
      const { data } = response;

      return data;
    })
    .catch((issu) => {
      console.log("Issu ----> ", issu);
      return null;
    });
};

export const createRoomFeature = (data) => {
  return axios
    .post(
      process.env.REACT_APP_DEFAULTAUTH + "/api/admin/createRoomFeature",
      data,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          authToken: getToken().authT,
          authKey: getToken().authk,
        },
      }
    )
    .then((response) => {
      const { data } = response;
      return data;
    })
    .catch((issu) => {
      console.log("Issu ----> ", issu);
      return null;
    });
};

export const editRoomFeatures = (roomFeatureId) => {
  console.log(roomFeatureId);
  return axios
    .post(
      process.env.REACT_APP_DEFAULTAUTH + "/api/user/returnRoomFeatureById",
      { roomFeatureId },
      {
        headers: {
          "content-type": "application/json",
          authToken: getToken().authT,
          authKey: getToken().authk,
        },
      }
    )
    .then((response) => {
      const { data } = response;
      console.log(data);
      return data;
    })
    .catch((issu) => {
      console.log("Issu ----> ", issu);
      return null;
    });
};

export const updateRoomHospitalityService = (data) => {
  return axios
    .post(
      process.env.REACT_APP_DEFAULTAUTH +
        "/api/admin/updateRoomHospitalityService",
      data,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          authToken: getToken().authT,
          authKey: getToken().authk,
        },
      }
    )
    .then((response) => {
      const { data } = response;
      console.log("data", data);
      return data;
    })
    .catch((issu) => {
      console.log("Issu ----> ", issu);
      return null;
    });
};

export const removeRoomHospitalityService = (roomHospitalityServiceId) => {
  return axios
    .post(
      process.env.REACT_APP_DEFAULTAUTH +
        "/api/admin/removeRoomHospitalityService",
      { roomHospitalityServiceId },
      {
        headers: {
          "content-type": "application/json",
          authToken: getToken().authT,
          authKey: getToken().authk,
        },
      }
    )
    .then((response) => {
      const { data } = response;
      console.log("data", data);
      return data;
    })
    .catch((issu) => {
      console.log("Issu ----> ", issu);
      return null;
    });
};

export const updateRoomFeature = (data) => {
  return axios
    .post(
      process.env.REACT_APP_DEFAULTAUTH + "/api/admin/updateRoomFeature",
      data,

      {
        headers: {
          "Content-Type": "multipart/form-data",
          authToken: getToken().authT,
          authKey: getToken().authk,
        },
      }
    )
    .then((response) => {
      const { data } = response;
      return data;
    })
    .catch((issu) => {
      console.log("Issu ----> ", issu);
      return null;
    });
};

export const removeRoomFeature = (roomFeatureId) => {
  return axios
    .post(
      process.env.REACT_APP_DEFAULTAUTH + "/api/admin/removeRoomFeature",
      { roomFeatureId },
      {
        headers: {
          "content-type": "application/json",
          authToken: getToken().authT,
          authKey: getToken().authk,
        },
      }
    )
    .then((response) => {
      const { data } = response;
      console.log("data", data);
      return data;
    })
    .catch((issu) => {
      console.log("Issu ----> ", issu);
      return null;
    });
};

export const createRoomType = (data) => {
  return axios
    .post(
      process.env.REACT_APP_DEFAULTAUTH + "/api/admin/createRoomType",
      data,
      {
        headers: {
          "content-type": "application/json",
          authToken: getToken().authT,
          authKey: getToken().authk,
        },
      }
    )
    .then((response) => {
      const { data } = response;
      return data;
    })
    .catch((issu) => {
      console.log("Issu ----> ", issu);
      return null;
    });
};

export const getRoomTypeList = () => {
  return axios
    .get(
      process.env.REACT_APP_DEFAULTAUTH + "/api/admin/getRoomTypeList",

      {
        headers: {
          authToken: getToken().authT,
          authKey: getToken().authk,
        },
      }
    )
    .then((response) => {
      const { data } = response;

      return data;
    })
    .catch((issu) => {
      console.log("Issu ----> ", issu);
      return null;
    });
};

export const editRoomTypeById = (roomTypeId) => {
  return axios
    .post(
      process.env.REACT_APP_DEFAULTAUTH + "/api/admin/returnRoomTypeById",
      { roomTypeId },
      {
        headers: {
          "content-type": "application/json",
          authToken: getToken().authT,
          authKey: getToken().authk,
        },
      }
    )
    .then((response) => {
      const { data } = response;
      console.log(data);
      return data;
    })
    .catch((issu) => {
      console.log("Issu ----> ", issu);
      return null;
    });
};

export const updateRoomType = (data) => {
  return axios
    .post(
      process.env.REACT_APP_DEFAULTAUTH + "/api/admin/updateRoomType",
      data,
      {
        headers: {
          "content-type": "application/json",
          authToken: getToken().authT,
          authKey: getToken().authk,
        },
      }
    )
    .then((response) => {
      const { data } = response;
      return data;
    })
    .catch((issu) => {
      console.log("Issu ----> ", issu);
      return null;
    });
};

export const removeRoomType = (roomTypeId) => {
  return axios
    .post(
      process.env.REACT_APP_DEFAULTAUTH + "/api/admin/removeRoomType",
      { roomTypeId },
      {
        headers: {
          "content-type": "application/json",
          authToken: getToken().authT,
          authKey: getToken().authk,
        },
      }
    )
    .then((response) => {
      const { data } = response;
      console.log("data", data);
      return data;
    })
    .catch((issu) => {
      console.log("Issu ----> ", issu);
      return null;
    });
};

export const createRoom = (data) => {
  return axios
    .post(process.env.REACT_APP_DEFAULTAUTH + "/api/admin/createRoom", data, {
      headers: {
        "content-type": "multipart/form-data",
        authToken: getToken().authT,
        authKey: getToken().authk,
      },
    })
    .then((response) => {
      const { data } = response;
      return data;
    })
    .catch((issu) => {
      console.log("Issu ----> ", issu);
      return null;
    });
};

export const removeRoom = (data) => {
  return axios
    .post(process.env.REACT_APP_DEFAULTAUTH + "/api/admin/removeRoom", data, {
      headers: {
        "content-type": "application/json",
        authToken: getToken().authT,
        authKey: getToken().authk,
      },
    })
    .then((response) => {
      const { data } = response;
      console.log("data", data);
      return data;
    })
    .catch((issu) => {
      console.log("Issu ----> ", issu);
      return null;
    });
};

export const getRoomById = (roomId) => {
  return axios
    .post(
      process.env.REACT_APP_DEFAULTAUTH + "/api/admin/getRoomById",
      { roomId },
      {
        headers: {
          authToken: getToken().authT,
          authKey: getToken().authk,
        },
      }
    )
    .then((response) => {
      const { data } = response;

      return data;
    })
    .catch((issu) => {
      console.log("Issu ----> ", issu);
      return null;
    });
};

export const getRoomHospitalityServiceList = () => {
  return axios
    .get(
      process.env.REACT_APP_DEFAULTAUTH +
        "/api/admin/getRoomHospitalityServiceList",

      {
        headers: {
          authToken: getToken().authT,
          authKey: getToken().authk,
        },
      }
    )
    .then((response) => {
      const { data } = response;

      return data;
    })
    .catch((issu) => {
      console.log("Issu ----> ", issu);
      return null;
    });
};

export const createAmenityType = (data) => {
  return axios
    .post(
      process.env.REACT_APP_DEFAULTAUTH + "/api/admin/createAmenityType",
      data,
      {
        headers: {
          "content-type": "application/json",
          authToken: getToken().authT,
          authKey: getToken().authk,
        },
      }
    )
    .then((response) => {
      const { data } = response;
      return data;
    })
    .catch((issu) => {
      console.log("Issu ----> ", issu);
      return null;
    });
};

export const getAmenityTypeList = () => {
  return axios
    .get(
      process.env.REACT_APP_DEFAULTAUTH + "/api/admin/getAmenityTypeList",

      {
        headers: {
          authToken: getToken().authT,
          authKey: getToken().authk,
        },
      }
    )
    .then((response) => {
      const { data } = response;

      return data;
    })
    .catch((issu) => {
      console.log("Issu ----> ", issu);
      return null;
    });
};

export const getroomHospitalityServiceId = (roomHospitalityServiceId) => {
  return axios
    .post(
      process.env.REACT_APP_DEFAULTAUTH +
        "/api/admin/returnRoomHospitalityServiceById",
      { roomHospitalityServiceId },
      {
        headers: {
          "content-type": "application/json",
          authToken: getToken().authT,
          authKey: getToken().authk,
        },
      }
    )
    .then((response) => {
      const { data } = response;
      console.log(data);
      return data;
    })
    .catch((issu) => {
      console.log("Issu ----> ", issu);
      return null;
    });
};

export const updateAmenityType = (data) => {
  return axios
    .post(
      process.env.REACT_APP_DEFAULTAUTH + "/api/admin/updateAmenityType",
      data,
      {
        headers: {
          "content-type": "application/json",
          authToken: getToken().authT,
          authKey: getToken().authk,
        },
      }
    )
    .then((response) => {
      const { data } = response;
      return data;
    })
    .catch((issu) => {
      console.log("Issu ----> ", issu);
      return null;
    });
};

export const removeAmenityType = (amenityTypeId) => {
  console.log(amenityTypeId);
  return axios
    .post(
      process.env.REACT_APP_DEFAULTAUTH + "/api/admin/removeAmenityType",
      { amenityTypeId },
      {
        headers: {
          "content-type": "application/json",
          authToken: getToken().authT,
          authKey: getToken().authk,
        },
      }
    )
    .then((response) => {
      const { data } = response;
      console.log(data);
      return data;
    })
    .catch((issu) => {
      console.log("Issu ----> ", issu);
      return null;
    });
};

export const editAmenityType = (amenityTypeId) => {
  return axios
    .post(
      process.env.REACT_APP_DEFAULTAUTH + "/api/admin/getAmenityTypeById",
      { amenityTypeId },
      {
        headers: {
          authToken: getToken().authT,
          authKey: getToken().authk,
        },
      }
    )
    .then((response) => {
      const { data } = response;

      return data;
    })
    .catch((issu) => {
      console.log("Issu ----> ", issu);
      return null;
    });
};

export const totalDoctor = () => {
  return axios
    .get(
      process.env.REACT_APP_DEFAULTAUTH + "/api/admin/totalDoctor",

      {
        headers: {
          authToken: getToken(),
        },
      }
    )
    .then((response) => {
      const { data } = response;

      return data;
    })
    .catch((issu) => {
      console.log("Issu ----> ", issu);
      return null;
    });
};

export const totalUser = () => {
  return axios
    .get(
      process.env.REACT_APP_DEFAULTAUTH + "/api/admin/totalUser",

      {
        headers: {
          authToken: getToken(),
        },
      }
    )
    .then((response) => {
      const { data } = response;

      return data;
    })
    .catch((issu) => {
      console.log("Issu ----> ", issu);
      return null;
    });
};

export const createFoodCuisine = (data) => {
  return axios
    .post(
      process.env.REACT_APP_DEFAULTAUTH + "/api/admin/createFoodCuisine",
      data,
      {
        headers: {
          "content-type": "multipart/form-data",
          authToken: getToken().authT,
          authKey: getToken().authk,
        },
      }
    )
    .then((response) => {
      const { data } = response;
      return data;
    })
    .catch((issu) => {
      console.log("Issu ----> ", issu);
      return null;
    });
};

export const getFoodCuisineList = () => {
  return axios
    .get(
      process.env.REACT_APP_DEFAULTAUTH + "/api/admin/getFoodCuisineList",

      {
        headers: {
          authToken: getToken().authT,
          authKey: getToken().authk,
        },
      }
    )
    .then((response) => {
      const { data } = response;

      return data;
    })
    .catch((issu) => {
      console.log("Issu ----> ", issu);
      return null;
    });
};

export const getFoodCuisineById = (foodCuisineId) => {
  return axios
    .post(
      process.env.REACT_APP_DEFAULTAUTH + "/api/admin/getFoodCuisineById",
      { foodCuisineId },
      {
        headers: {
          authToken: getToken().authT,
          authKey: getToken().authk,
        },
      }
    )
    .then((response) => {
      const { data } = response;

      return data;
    })
    .catch((issu) => {
      console.log("Issu ----> ", issu);
      return null;
    });
};

export const updateFoodCuisine = (data) => {
  return axios
    .post(
      process.env.REACT_APP_DEFAULTAUTH + "/api/admin/updateFoodCuisine",
      data,
      {
        headers: {
          "content-type": "multipart/form-data",
          authToken: getToken().authT,
          authKey: getToken().authk,
        },
      }
    )
    .then((response) => {
      const { data } = response;
      return data;
    })
    .catch((issu) => {
      console.log("Issu ----> ", issu);
      return null;
    });
};

export const removeFoodCuisine = (foodCuisineId) => {
  return axios
    .post(
      process.env.REACT_APP_DEFAULTAUTH + "/api/admin/removeFoodCuisine",
      { foodCuisineId },
      {
        headers: {
          authToken: getToken().authT,
          authKey: getToken().authk,
        },
      }
    )
    .then((response) => {
      const { data } = response;

      return data;
    })
    .catch((issu) => {
      console.log("Issu ----> ", issu);
      return null;
    });
};

export const createFood = (data) => {
  return axios
    .post(process.env.REACT_APP_DEFAULTAUTH + "/api/admin/createFood", data, {
      headers: {
        "content-type": "multipart/form-data",
        authToken: getToken().authT,
        authKey: getToken().authk,
      },
    })
    .then((response) => {
      const { data } = response;
      return data;
    })
    .catch((issu) => {
      console.log("Issu ----> ", issu);
      return null;
    });
};
export const getFoodList = () => {
  return axios
    .get(
      process.env.REACT_APP_DEFAULTAUTH + "/api/admin/getFoodListAdmin",

      {
        headers: {
          authToken: getToken().authT,
          authKey: getToken().authk,
        },
      }
    )
    .then((response) => {
      const { data } = response;

      return data;
    })
    .catch((issu) => {
      console.log("Issu ----> ", issu);
      return null;
    });
};

export const getFoodById = (foodId) => {
  return axios
    .post(
      process.env.REACT_APP_DEFAULTAUTH + "/api/admin/getFoodById",
      { foodId },
      {
        headers: {
          authToken: getToken().authT,
          authKey: getToken().authk,
        },
      }
    )
    .then((response) => {
      const { data } = response;

      return data;
    })
    .catch((issu) => {
      console.log("Issu ----> ", issu);
      return null;
    });
};

export const updateFood = (data) => {
  return axios
    .post(process.env.REACT_APP_DEFAULTAUTH + "/api/admin/updateFood", data, {
      headers: {
        "content-type": "multipart/form-data",
        authToken: getToken().authT,
        authKey: getToken().authk,
      },
    })
    .then((response) => {
      const { data } = response;
      return data;
    })
    .catch((issu) => {
      console.log("Issu ----> ", issu);
      return null;
    });
};

export const createFoodAddOn = (data) => {
  return axios
    .post(
      process.env.REACT_APP_DEFAULTAUTH + "/api/admin/createFoodAddOn",
      data,
      {
        headers: {
          "content-type": "application/json",
          authToken: getToken().authT,
          authKey: getToken().authk,
        },
      }
    )
    .then((response) => {
      const { data } = response;
      return data;
    })
    .catch((issu) => {
      console.log("Issu ----> ", issu);
      return null;
    });
};

export const getFoodAddOnList = () => {
  return axios
    .get(
      process.env.REACT_APP_DEFAULTAUTH + "/api/admin/getFoodAddOnList",

      {
        headers: {
          "content-type": "application/json",
          authToken: getToken().authT,
          authKey: getToken().authk,
        },
      }
    )
    .then((response) => {
      const { data } = response;

      return data;
    })
    .catch((issu) => {
      console.log("Issu ----> ", issu);
      return null;
    });
};
export const getFoodAddOnById = (foodAddOnId) => {
  return axios
    .post(
      process.env.REACT_APP_DEFAULTAUTH + "/api/admin/getFoodAddOnById",
      { foodAddOnId },
      {
        headers: {
          "content-type": "application/json",
          authToken: getToken().authT,
          authKey: getToken().authk,
        },
      }
    )
    .then((response) => {
      const { data } = response;

      return data;
    })
    .catch((issu) => {
      console.log("Issu ----> ", issu);
      return null;
    });
};

export const updateFoodAddOn = (data) => {
  return axios
    .post(
      process.env.REACT_APP_DEFAULTAUTH + "/api/admin/updateFoodAddOn",
      data,
      {
        headers: {
          "content-type": "application/json",
          authToken: getToken().authT,
          authKey: getToken().authk,
        },
      }
    )
    .then((response) => {
      const { data } = response;
      return data;
    })
    .catch((issu) => {
      console.log("Issu ----> ", issu);
      return null;
    });
};
export const removeFoodAddOn = (foodAddOnId) => {
  return axios
    .post(
      process.env.REACT_APP_DEFAULTAUTH + "/api/admin/removeFoodAddOn",
      { foodAddOnId },
      {
        headers: {
          "content-type": "application/json",
          authToken: getToken().authT,
          authKey: getToken().authk,
        },
      }
    )
    .then((response) => {
      const { data } = response;

      return data;
    })
    .catch((issu) => {
      console.log("Issu ----> ", issu);
      return null;
    });
};

export const createTable = (data) => {
  return axios
    .post(process.env.REACT_APP_DEFAULTAUTH + "/api/admin/createTable", data, {
      headers: {
        "content-type": "application/json",
        authToken: getToken().authT,
        authKey: getToken().authk,
      },
    })
    .then((response) => {
      const { data } = response;
      return data;
    })
    .catch((issu) => {
      console.log("Issu ----> ", issu);
      return null;
    });
};
export const getTableList = () => {
  return axios
    .get(
      process.env.REACT_APP_DEFAULTAUTH + "/api/admin/getTableList",

      {
        headers: {
          "content-type": "application/json",
          authToken: getToken().authT,
          authKey: getToken().authk,
        },
      }
    )
    .then((response) => {
      const { data } = response;

      return data;
    })
    .catch((issu) => {
      console.log("Issu ----> ", issu);
      return null;
    });
};

export const gettableId = (tableId) => {
  return axios
    .post(
      process.env.REACT_APP_DEFAULTAUTH + "/api/admin/gettableId",
      { tableId },
      {
        headers: {
          "content-type": "application/json",
          authToken: getToken().authT,
          authKey: getToken().authk,
        },
      }
    )
    .then((response) => {
      const { data } = response;

      return data;
    })
    .catch((issu) => {
      console.log("Issu ----> ", issu);
      return null;
    });
};
export const removetable = (data) => {
  return axios
    .post(process.env.REACT_APP_DEFAULTAUTH + "/api/admin/removetable", data, {
      headers: {
        "content-type": "application/json",
        authToken: getToken().authT,
        authKey: getToken().authk,
      },
    })
    .then((response) => {
      const { data } = response;

      return data;
    })
    .catch((issu) => {
      console.log("Issu ----> ", issu);
      return null;
    });
};

export const updateTable = (data) => {
  return axios
    .post(process.env.REACT_APP_DEFAULTAUTH + "/api/admin/updateTable", data, {
      headers: {
        "content-type": "application/json",
        authToken: getToken().authT,
        authKey: getToken().authk,
      },
    })
    .then((response) => {
      const { data } = response;
      return data;
    })
    .catch((issu) => {
      console.log("Issu ----> ", issu);
      return null;
    });
};

//
export const addAttraction = (data) => {
  return axios
    .post(
      process.env.REACT_APP_DEFAULTAUTH + "/api/admin/createAttraction",
      data,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          authToken: getToken().authT,
          authKey: getToken().authk,
        },
      }
    )
    .then((response) => {
      const { data } = response;
      return data;
    })
    .catch((issu) => {
      console.log("Issu ----> ", issu);
      return null;
    });
};
//

export const attractionList = () => {
  return axios
    .get(
      process.env.REACT_APP_DEFAULTAUTH + "/api/admin/getAttractionList",

      {
        headers: {
          authToken: getToken().authT,
          authKey: getToken().authk,
        },
      }
    )
    .then((response) => {
      const { data } = response;

      return data;
    })
    .catch((issu) => {
      console.log("Issu ----> ", issu);
      return null;
    });
};
export const editAttraction = (attractionId) => {
  return axios
    .post(
      process.env.REACT_APP_DEFAULTAUTH + "/api/admin/getattractionById",
      { attractionId },
      {
        headers: {
          "Content-Type": "application/json",
          authToken: getToken().authT,
          authKey: getToken().authk,
        },
      }
    )
    .then((response) => {
      const data = response.data;
      return data;
    })
    .catch((error) => {
      return null;
    });
};
export const removeAttraction = (attractionId) => {
  return axios
    .post(
      process.env.REACT_APP_DEFAULTAUTH + "/api/admin/removeAttraction",
      { attractionId },
      {
        headers: {
          authToken: getToken().authT,
          authKey: getToken().authk,
        },
      }
    )
    .then((response) => {
      const data = response.data;
      return data;
    })
    .catch((error) => {
      return null;
    });
};

export const updateAttraction = (data) => {
  return axios
    .post(
      process.env.REACT_APP_DEFAULTAUTH + "/api/admin/updateAttraction",
      data,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          authToken: getToken().authT,
          authKey: getToken().authk,
        },
      }
    )
    .then((response) => {
      const { data } = response;
      return data;
    })
    .catch((issu) => {
      console.log("Issu ----> ", issu);
      return null;
    });
};

export const removeFood = (data) => {
  return axios
    .post(process.env.REACT_APP_DEFAULTAUTH + "/api/admin/removeFood", data, {
      headers: {
        authToken: getToken().authT,
        authKey: getToken().authk,
      },
    })
    .then((response) => {
      const data = response.data;
      return data;
    })
    .catch((error) => {
      return null;
    });
};

//taxi
export const createTaxi = (data) => {
  return axios
    .post(process.env.REACT_APP_DEFAULTAUTH + "/api/admin/createTaxi", data, {
      headers: {
        "Content-Type": "multipart/form-data",
        authToken: getToken().authT,
        authKey: getToken().authk,
      },
    })
    .then((response) => {
      const { data } = response;
      return data;
    })
    .catch((issu) => {
      console.log("Issu ----> ", issu);
      return null;
    });
};

export const updateTaxi = (data) => {
  return axios
    .post(process.env.REACT_APP_DEFAULTAUTH + "/api/admin/updateTaxi", data, {
      headers: {
        "Content-Type": "multipart/form-data",
        authToken: getToken().authT,
        authKey: getToken().authk,
      },
    })
    .then((response) => {
      const { data } = response;
      return data;
    })
    .catch((issu) => {
      console.log("Issu ----> ", issu);
      return null;
    });
};

//getlistById
export const getTaxiById = (taxiId) => {
  return axios
    .post(
      process.env.REACT_APP_DEFAULTAUTH + "/api/admin/getTaxiById",
      { taxiId },
      {
        headers: {
          authToken: getToken().authT,
          authKey: getToken().authk,
        },
      }
    )
    .then((response) => {
      const data = response.data;
      return data;
    })
    .catch((error) => {
      return null;
    });
};
//

export const removeTaxi = (taxiId) => {
  return axios
    .post(
      process.env.REACT_APP_DEFAULTAUTH + "/api/admin/removeTaxi",
      { taxiId },
      {
        headers: {
          authToken: getToken().authT,
          authKey: getToken().authk,
        },
      }
    )
    .then((response) => {
      const data = response.data;
      return data;
    })
    .catch((error) => {
      return null;
    });
};

export const getTaxiList = () => {
  return axios
    .get(
      process.env.REACT_APP_DEFAULTAUTH + "/api/admin/getTaxiList",

      {
        headers: {
          authToken: getToken().authT,
          authKey: getToken().authk,
        },
      }
    )
    .then((response) => {
      const { data } = response;

      return data;
    })
    .catch((issu) => {
      console.log("Issu ----> ", issu);
      return null;
    });
};

//SMS Notitication things
// Create SMS Notification
export const createSMSNotification = (data) => {
  return axios
    .post(
      process.env.REACT_APP_DEFAULTAUTH + "/api/admin/smsNotification",
      data,
      {
        headers: {
          authToken: getToken().authT,
          authKey: getToken().authk,
        },
      }
    )
    .then((response) => response.data)
    .catch((error) => {
      console.error("Error creating SMS notification:", error);
      return null;
    });
};

// Update SMS Notification
export const updateSMSNotification = (id, data) => {
  return axios
    .put(
      `${process.env.REACT_APP_DEFAULTAUTH}/api/admin/smsNotification/${id}`,
      data,
      {
        headers: {
          authToken: getToken().authT,
          authKey: getToken().authk,
        },
      }
    )
    .then((response) => response.data)
    .catch((error) => {
      console.error("Error updating SMS notification:", error);
      return null;
    });
};

// Get SMS Notifications
export const getSMSNotifications = () => {
  return axios
    .get(process.env.REACT_APP_DEFAULTAUTH + "/api/admin/smsNotification", {
      headers: {
        authToken: getToken().authT,
        authKey: getToken().authk,
      },
    })
    .then((response) => response.data)
    .catch((error) => {
      console.error("Error fetching SMS notifications:", error);
      return null;
    });
};

// Delete SMS Notification
export const deleteSMSNotification = (id) => {
  return axios
    .delete(
      `${process.env.REACT_APP_DEFAULTAUTH}/api/admin/smsNotification/${id}`,
      {
        headers: {
          authToken: getToken().authT,
          authKey: getToken().authk,
        },
      }
    )
    .then((response) => response.data)
    .catch((error) => {
      console.error("Error deleting SMS notification:", error);
      return null;
    });
};

export const listroomBookingCancellationRequest = () => {
  return axios
    .get(
      process.env.REACT_APP_DEFAULTAUTH +
        "/api/admin/listroomBookingCancellationRequest",

      {
        headers: {
          authToken: getToken().authT,
          authKey: getToken().authk,
        },
      }
    )
    .then((response) => {
      const { data } = response;

      return data;
    })
    .catch((issu) => {
      console.log("Issu ----> ", issu);
      return null;
    });
};

export const approveCancellationRequest = (roomBookingId) => {
  return axios
    .post(
      process.env.REACT_APP_DEFAULTAUTH +
        "/api/admin/approveCancellationRequest",
      { roomBookingId },
      {
        headers: {
          authToken: getToken().authT,
          authKey: getToken().authk,
        },
      }
    )
    .then((response) => {
      const data = response.data;
      return data;
    })
    .catch((error) => {
      return null;
    });
};

export const disapproveCancellationRequest = (roomBookingId) => {
  return axios
    .post(
      process.env.REACT_APP_DEFAULTAUTH +
        "/api/admin/disapproveCancellationRequest",
      { roomBookingId },
      {
        headers: {
          authToken: getToken().authT,
          authKey: getToken().authk,
        },
      }
    )
    .then((response) => {
      const data = response.data;
      return data;
    })
    .catch((error) => {
      return null;
    });
};

export const listRoomBookingRequest = () => {
  return axios
    .get(
      process.env.REACT_APP_DEFAULTAUTH + "/api/admin/roomBookingList",

      {
        headers: {
          authToken: getToken().authT,
          authKey: getToken().authk,
        },
      }
    )
    .then((response) => {
      const { data } = response;

      return data;
    })
    .catch((issu) => {
      console.log("Issu ----> ", issu);
      return null;
    });
};

export const updatePaymentStatus = (roomBookingId, isPaymentSuccess) => {
  return axios
    .post(
      process.env.REACT_APP_DEFAULTAUTH + "/api/admin/updateRoomstatus",
      { roomBookingId, isPaymentSuccess },
      {
        headers: {
          authToken: getToken().authT,
          authKey: getToken().authk,
        },
      }
    )
    .then((response) => {
      const data = response.data;
      return data;
    })
    .catch((error) => {
      return null;
    });
};

//
export const listofDeliveryBoy = () => {
  return axios
    .get(
      process.env.REACT_APP_DEFAULTAUTH + "/api/admin/listofDeliveryBoy",

      {
        headers: {
          authToken: getToken().authT,
          authKey: getToken().authk,
        },
      }
    )
    .then((response) => {
      const { data } = response;

      return data;
    })
    .catch((issu) => {
      console.log("Issu ----> ", issu);
      return null;
    });
};

export const foodOrderList = (data) => {
  return axios
    .post(
      process.env.REACT_APP_DEFAULTAUTH + "/api/admin/foodOrderList",
      data,
      {
        headers: {
          "Content-Type": "application/json",
          authToken: getToken().authT,
          authKey: getToken().authk,
        },
      }
    )
    .then((response) => {
      const { data } = response;
      return data;
    })
    .catch((issu) => {
      console.log("Issu ----> ", issu);
      return null;
    });
};

export const AssignDeliveryBoy = (data) => {
  return axios
    .post(
      process.env.REACT_APP_DEFAULTAUTH + "/api/admin/AssignDeliveryBoy",
      data,
      {
        headers: {
          "Content-Type": "application/json",
          authToken: getToken().authT,
          authKey: getToken().authk,
        },
      }
    )
    .then((response) => {
      const { data } = response;
      return data;
    })
    .catch((issu) => {
      console.log("Issu ----> ", issu);
      return null;
    });
};

//re
export const ReAssignDeliveryBoy = (data) => {
  return axios
    .post(
      process.env.REACT_APP_DEFAULTAUTH + "/api/admin/ReAssignDeliveryBoy",
      data,
      {
        headers: {
          "Content-Type": "application/json",
          authToken: getToken().authT,
          authKey: getToken().authk,
        },
      }
    )
    .then((response) => {
      const { data } = response;
      return data;
    })
    .catch((issu) => {
      console.log("Issu ----> ", issu);
      return null;
    });
};

//
export const OrderListbyId = (foodorderId) => {
  return axios
    .post(
      process.env.REACT_APP_DEFAULTAUTH + "/api/admin/OrderListbyId",
      { foodorderId },
      {
        headers: {
          "Content-Type": "application/json",
          authToken: getToken().authT,
          authKey: getToken().authk,
        },
      }
    )
    .then((response) => {
      const data = response.data;
      return data;
    })
    .catch((error) => {
      return null;
    });
};

//OrderFullfilled - API - OrderFullfilled
export const OrderFullfilled = (foodorderId) => {
  return axios
    .post(
      process.env.REACT_APP_DEFAULTAUTH + "/api/admin/OrderFullfilled",
      { foodorderId },
      {
        headers: {
          "Content-Type": "application/json",
          authToken: getToken().authT,
          authKey: getToken().authk,
        },
      }
    )
    .then((response) => {
      const data = response.data;
      return data;
    })
    .catch((error) => {
      return null;
    });
};

//Notification
export const createNotification = (data) => {
  return axios
    .post(
      process.env.REACT_APP_DEFAULTAUTH + "/api/admin/createNotification",
      data,
      {
        headers: {
          "Content-Type": "application/json",
          authToken: getToken().authT,
          authKey: getToken().authk,
        },
      }
    )
    .then((response) => {
      const data = response.data;
      return data;
    })
    .catch((error) => {
      return null;
    });
};

export const getNotificationAdmin = () => {
  return axios
    .get(
      process.env.REACT_APP_DEFAULTAUTH + "/api/admin/getNotificationAdmin",
      {
        headers: {
          "Content-Type": "application/json",
          authToken: getToken().authT,
          authKey: getToken().authk,
        },
      }
    )
    .then((response) => {
      const data = response.data;
      return data;
    })
    .catch((error) => {
      return null;
    });
};

//DeliveryBoy management
export const addFoodDeliveryBoy = (data) => {
  return axios
    .post(
      process.env.REACT_APP_DEFAULTAUTH + "/api/admin/addFoodDeliveryBoy",
      data,
      {
        headers: {
          "Content-Type": "application/json",
          authToken: getToken().authT,
          authKey: getToken().authk,
        },
      }
    )
    .then((response) => {
      const data = response.data;
      return data;
    })
    .catch((err) => {
      return null;
    });
};

export const getFoodDeliveryBoyList = () => {
  return axios
    .get(
      process.env.REACT_APP_DEFAULTAUTH + "/api/admin/getFoodDeliveryBoyList",
      {
        headers: {
          authToken: getToken().authT,
          authKey: getToken().authk,
        },
      }
    )
    .then((response) => {
      const data = response.data;
      return data;
    })
    .catch((err) => {
      return null;
    });
};

export const getFoodDeliveryBoyById = (dBoyId) => {
  return axios
    .post(
      process.env.REACT_APP_DEFAULTAUTH + "/api/admin/getFoodDeliveryBoyById",
      { dBoyId },

      {
        headers: {
          "Content-Type": "application/json",
          authToken: getToken().authT,
          authKey: getToken().authk,
        },
      }
    )
    .then((response) => {
      const data = response.data;
      return data;
    })
    .catch((err) => {
      return null;
    });
};

export const updateFoodDeliveryBoy = (data) => {
  return axios
    .post(
      process.env.REACT_APP_DEFAULTAUTH + "/api/admin/updateFoodDeliveryBoy",
      data,
      {
        headers: {
          "Content-Type": "application/json",
          authToken: getToken().authT,
          authKey: getToken().authk,
        },
      }
    )
    .then((response) => {
      const data = response.data;
      return data;
    })
    .catch((err) => {
      return null;
    });
};

export const removeFoodDeliveryBoy = (dBoyId) => {
  return axios
    .post(
      process.env.REACT_APP_DEFAULTAUTH + "/api/admin/removeFoodDeliveryBoy",
      { dBoyId },
      {
        headers: {
          "Content-Type": "application/json",
          authToken: getToken().authT,
          authKey: getToken().authk,
        },
      }
    )
    .then((response) => {
      const data = response.data;
      return data;
    })
    .catch((err) => {
      return null;
    });
};

//
export const getUserList = () => {
  return axios
    .get(process.env.REACT_APP_DEFAULTAUTH + "/api/admin/getUserList", {
      headers: {
        authToken: getToken().authT,
        authKey: getToken().authk,
      },
    })
    .then((response) => {
      const data = response.data;
      return data;
    })
    .catch((err) => {
      return null;
    });
};

//user management
export const addUser = (data) => {
  return axios
    .post(process.env.REACT_APP_DEFAULTAUTH + "/api/admin/addUser", data, {
      headers: {
        "Content-Type": "application/json",
        authToken: getToken().authT,
        authKey: getToken().authk,
      },
    })
    .then((response) => {
      const data = response.data;
      return data;
    })
    .catch((err) => {
      return null;
    });
};

export const updateUser = (data) => {
  return axios
    .post(process.env.REACT_APP_DEFAULTAUTH + "/api/admin/updateUser", data, {
      headers: {
        "Content-Type": "application/json",
        authToken: getToken().authT,
        authKey: getToken().authk,
      },
    })
    .then((response) => {
      const data = response.data;
      return data;
    })
    .catch((err) => {
      return null;
    });
};

export const getUsers = () => {
  return axios
    .get(process.env.REACT_APP_DEFAULTAUTH + "/api/admin/getUsers", {
      headers: {
        authToken: getToken().authT,
        authKey: getToken().authk,
      },
    })
    .then((response) => {
      const data = response.data;
      return data;
    })
    .catch((err) => {
      return null;
    });
};

//chat
export const getChatListAdmin = () => {
  return axios
    .get(process.env.REACT_APP_DEFAULTAUTH + "/api/admin/getChatListAdmin", {
      headers: {
        authToken: getToken().authT,
        authKey: getToken().authk,
      },
    })
    .then((response) => {
      const data = response.data;
      return data;
    })
    .catch((err) => {
      return null;
    });
};

export const sendMessageAdmin = (data) => {
  return axios
    .post(
      process.env.REACT_APP_DEFAULTAUTH + "/api/admin/sendMessageAdmin",
      data,
      {
        headers: {
          "Content-Type": "application/json",
          authToken: getToken().authT,
          authKey: getToken().authk,
        },
      }
    )
    .then((response) => {
      const data = response.data;
      return data;
    })
    .catch((err) => {
      return null;
    });
};

export const closeChat = (chatMasterId) => {
  return axios
    .post(
      process.env.REACT_APP_DEFAULTAUTH + "/api/admin/closeChat",
      { chatMasterId },
      {
        headers: {
          "Content-Type": "application/json",
          authToken: getToken().authT,
          authKey: getToken().authk,
        },
      }
    )
    .then((response) => {
      const data = response.data;
      return data;
    })
    .catch((err) => {
      return null;
    });
};
//byId
export const getChatListAdminById = (chatId) => {
  return axios
    .post(
      process.env.REACT_APP_DEFAULTAUTH + "/api/admin/getChatListAdminById",
      { chatId },
      {
        headers: {
          "Content-Type": "application/json",
          authToken: getToken().authT,
          authKey: getToken().authk,
        },
      }
    )
    .then((response) => {
      const data = response.data;
      return data;
    })
    .catch((err) => {
      return null;
    });
};

//Dashboard
export const totalUsers = () => {
  return axios
    .get(process.env.REACT_APP_DEFAULTAUTH + "/api/admin/totalUsers", {
      headers: {
        authToken: getToken().authT,
        authKey: getToken().authk,
      },
    })
    .then((response) => {
      const data = response.data;
      return data;
    })
    .catch((err) => {
      return null;
    });
};
//
export const totalDboy = () => {
  return axios
    .get(process.env.REACT_APP_DEFAULTAUTH + "/api/admin/totalDboy", {
      headers: {
        authToken: getToken().authT,
        authKey: getToken().authk,
      },
    })
    .then((response) => {
      const data = response.data;
      return data;
    })
    .catch((err) => {
      return null;
    });
};

export const totalOrders = () => {
  return axios
    .get(process.env.REACT_APP_DEFAULTAUTH + "/api/admin/totalOrders", {
      headers: {
        authToken: getToken().authT,
        authKey: getToken().authk,
      },
    })
    .then((response) => {
      const data = response.data;
      return data;
    })
    .catch((err) => {
      return null;
    });
};

export const getTotalData = (filter) => {
  return axios
    .get(process.env.REACT_APP_DEFAULTAUTH + "/api/admin/totalData", {
      headers: {
        authToken: getToken().authT,
        authKey: getToken().authk,
      },
      params: {
        filter: filter,
      },
    })
    .then((response) => {
      const data = response.data;
      return data;
    })
    .catch((err) => {
      return null;
    });
};

export const totalRoomsBooked = (filter) => {
  return axios
    .get(process.env.REACT_APP_DEFAULTAUTH + "/api/admin/totalRoomsBooked", {
      headers: {
        authToken: getToken().authT,
        authKey: getToken().authk,
      },
      params: { filter }, // pass filter as query parameter
    })
    .then((response) => {
      const data = response.data;
      return data;
    })
    .catch((err) => {
      return null;
    });
};

export const totalDeliveries = () => {
  return axios
    .get(process.env.REACT_APP_DEFAULTAUTH + "/api/admin/totalDeliveries", {
      headers: {
        authToken: getToken().authT,
        authKey: getToken().authk,
      },
    })
    .then((response) => {
      const data = response.data;
      return data;
    })
    .catch((err) => {
      return null;
    });
};

export const orderData = (data) => {
  return axios
    .post(process.env.REACT_APP_DEFAULTAUTH + "/api/admin/orderData", data, {
      headers: {
        "Content-Type": "application/json",
        authToken: getToken().authT,
        authKey: getToken().authk,
      },
    })
    .then((response) => {
      const data = response.data;
      return data;
    })
    .catch((err) => {
      return null;
    });
};

export const monthlyOrders = () => {
  return axios
    .get(process.env.REACT_APP_DEFAULTAUTH + "/api/admin/monthlyOrders", {
      headers: {
        authToken: getToken().authT,
        authKey: getToken().authk,
      },
    })
    .then((response) => {
      const data = response.data;
      return data;
    })
    .catch((err) => {
      return null;
    });
};

// monthlyOrdersTable
export const monthlyOrdersTable = () => {
  return axios
    .get(process.env.REACT_APP_DEFAULTAUTH + "/api/admin/monthlyOrdersTable", {
      headers: {
        authToken: getToken().authT,
        authKey: getToken().authk,
      },
    })
    .then((response) => {
      const data = response.data;
      return data;
    })
    .catch((err) => {
      return null;
    });
};

//
export const monthlyBookings = () => {
  return axios
    .get(process.env.REACT_APP_DEFAULTAUTH + "/api/admin/monthlyBookings", {
      headers: {
        authToken: getToken().authT,
        authKey: getToken().authk,
      },
    })
    .then((response) => {
      const data = response.data;
      return data;
    })
    .catch((err) => {
      return null;
    });
};

export const updateRoom = (data) => {
  return axios
    .post(process.env.REACT_APP_DEFAULTAUTH + "/api/admin/updateRoom", data, {
      headers: {
        "content-type": "multipart/form-data",
        authToken: getToken().authT,
        authKey: getToken().authk,
      },
    })
    .then((response) => {
      const { data } = response;
      return data;
    })
    .catch((issu) => {
      console.log("Issu ----> ", issu);
      return null;
    });
};

//amenities
export const createAmenity = (data) => {
  return axios
    .post(
      process.env.REACT_APP_DEFAULTAUTH + "/api/admin/createAmenity",
      data,
      {
        headers: {
          "content-type": "multipart/form-data",
          authToken: getToken().authT,
          authKey: getToken().authk,
        },
      }
    )
    .then((response) => {
      const { data } = response;
      return data;
    })
    .catch((issu) => {
      console.log("Issu ----> ", issu);
      return null;
    });
};

export const removeAmenity = (amenityId) => {
  return axios
    .post(
      process.env.REACT_APP_DEFAULTAUTH + "/api/admin/removeAmenity",
      { amenityId },
      {
        headers: {
          "Content-Type": "application/json",
          authToken: getToken().authT,
          authKey: getToken().authk,
        },
      }
    )
    .then((response) => {
      const data = response.data;
      return data;
    })
    .catch((err) => {
      return null;
    });
};

export const getAmenityList = () => {
  return axios
    .get(process.env.REACT_APP_DEFAULTAUTH + "/api/admin/getAmenityList", {
      headers: {
        authToken: getToken().authT,
        authKey: getToken().authk,
      },
    })
    .then((response) => {
      const data = response.data;
      return data;
    })
    .catch((err) => {
      return null;
    });
};

export const getAmenityById = (amenityId) => {
  return axios
    .post(
      process.env.REACT_APP_DEFAULTAUTH + "/api/admin/getAmenityById",
      { amenityId },
      {
        headers: {
          "Content-Type": "application/json",
          authToken: getToken().authT,
          authKey: getToken().authk,
        },
      }
    )
    .then((response) => {
      const data = response.data;
      return data;
    })
    .catch((err) => {
      return null;
    });
};

export const updateAmenity = (data) => {
  return axios
    .post(
      process.env.REACT_APP_DEFAULTAUTH + "/api/admin/updateAmenity",
      data,
      {
        headers: {
          "content-type": "multipart/form-data",
          authToken: getToken().authT,
          authKey: getToken().authk,
        },
      }
    )
    .then((response) => {
      const { data } = response;
      return data;
    })
    .catch((issu) => {
      console.log("Issu ----> ", issu);
      return null;
    });
};

export const OrderList = () => {
  return axios
    .get(process.env.REACT_APP_DEFAULTAUTH + "/api/admin/foodOrderList", {
      headers: {
        authToken: getToken().authT,
        authKey: getToken().authk,
      },
    })
    .then((response) => {
      const data = response.data;
      return data;
    })
    .catch((err) => {
      return null;
    });
};

export const updateFoodPaymentstatus = (data) => {
  return axios
    .post(
      process.env.REACT_APP_DEFAULTAUTH + "/api/admin/updateFoodPaymentstatus",
      data,
      {
        headers: {
          "Content-Type": "application/json",
          authToken: getToken().authT,
          authKey: getToken().authk,
        },
      }
    )
    .then((response) => {
      const data = response.data;
      return data;
    })
    .catch((err) => {
      return null;
    });
};

export const listTableBookingRequest = () => {
  return axios
    .get(
      process.env.REACT_APP_DEFAULTAUTH + "/api/admin/tableOrderList",

      {
        headers: {
          authToken: getToken().authT,
          authKey: getToken().authk,
        },
      }
    )
    .then((response) => {
      const { data } = response;

      return data;
    })
    .catch((issu) => {
      console.log("Issu ----> ", issu);
      return null;
    });
};

export const updateTablePaymentStatus = (tableorderId, isPaymentSuccess) => {
  return axios
    .post(
      process.env.REACT_APP_DEFAULTAUTH + "/api/admin/updatetablePaymentstatus",
      { tableorderId, isPaymentSuccess },
      {
        headers: {
          authToken: getToken().authT,
          authKey: getToken().authk,
        },
      }
    )
    .then((response) => {
      const data = response.data;
      return data;
    })
    .catch((error) => {
      return null;
    });
};

//
export const updatetablestatus = (data) => {
  return axios
    .post(
      process.env.REACT_APP_DEFAULTAUTH + "/api/admin/updatetablestatus",
      data,
      {
        headers: {
          "Content-Type": "application/json",
          authToken: getToken().authT,
          authKey: getToken().authk,
        },
      }
    )
    .then((response) => {
      const data = response.data;
      return data;
    })
    .catch((err) => {
      return null;
    });
};

//update food payment
export const updatepaymentstatus = (data) => {
  return axios
    .post(
      process.env.REACT_APP_DEFAULTAUTH + "/api/admin/updatePaymentStatus",
      data,
      {
        headers: {
          "Content-Type": "application/json",
          authToken: getToken().authT,
          authKey: getToken().authk,
        },
      }
    )
    .then((response) => {
      const data = response.data;
      return data;
    })
    .catch((err) => {
      return null;
    });
};

// Food Setting things
// Create Food Settings
export const createFoodSettings = (data) => {
  return axios
    .post(
      process.env.REACT_APP_DEFAULTAUTH + "/api/admin/createFoodSettings",
      data,
      {
        headers: {
          authToken: getToken().authT,
          authKey: getToken().authk,
        },
      }
    )
    .then((response) => response.data)
    .catch((error) => {
      console.error("Error creating food settings:", error);
      return null;
    });
};

// Get Food Settings
export const getFoodSettings = () => {
  return axios
    .get(process.env.REACT_APP_DEFAULTAUTH + "/api/admin/getFoodSettings", {
      headers: {
        authToken: getToken().authT,
        authKey: getToken().authk,
      },
    })
    .then((response) => response.data)
    .catch((error) => {
      console.error("Error fetching food settings:", error);
      return null;
    });
};

// Update Food Settings
export const updateFoodSettings = (data) => {
  return axios
    .put(
      process.env.REACT_APP_DEFAULTAUTH + "/api/admin/updateFoodSettings",
      data,
      {
        headers: {
          authToken: getToken().authT,
          authKey: getToken().authk,
        },
      }
    )
    .then((response) => response.data)
    .catch((error) => {
      console.error("Error updating food settings:", error);
      return null;
    });
};

// Delete Food Settings
export const deleteFoodSettings = (id) => {
  return axios
    .delete(
      process.env.REACT_APP_DEFAULTAUTH + "/api/admin/deleteFoodSettings",
      {
        headers: {
          authToken: getToken().authT,
          authKey: getToken().authk,
        },
        data: { id },
      }
    )
    .then((response) => response.data)
    .catch((error) => {
      console.error("Error deleting food settings:", error);
      return null;
    });
};
