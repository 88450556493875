import React, { Component, useEffect, useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import io from "socket.io-client"; // Import the socket.io-client library
import { getToken } from "../../config/TokenHelper";
import {
  changeLayout,
  changeTopbarTheme,
  toggleRightSidebar,
  changeLayoutWidth,
} from "../../store/actions";

// Other Layout related Component
import Navbar from "./Navbar";
import Header from "./Header";
import Footer from "./Footer";
import Rightbar from "../CommonForBoth/Rightbar";
import { ToastContainer, toast } from "react-toastify";

// class Layout extends Component {
const Layout = (props) => {
  console.log("------------------AAAAAA------------");
  const [isMenuOpened, setIsMenuOpened] = useState(false);
  const [socket, setSocket] = useState(null);

  useEffect(() => {
    // Scroll to the top of the page
    window.scrollTo(0, 0);

    const SERVER = "https://socket.hotelslubumbashi.com:5001";
    const socket = io.connect(SERVER, { secure: true });

    const token = getToken();
    const userData = JSON.parse(token.authD);
    console.log("token bhau:", userData._id);

    socket.emit("connection", {
      userId: userData._id,
    });

    socket.on("connected", () => {
      setSocket(socket);
      console.log("Socket Connected");
    });

    socket.on("new-notification-received", (data) => {
      // Handle new notifications
      // This part remains the same as in your class-based component
    });

    // Cleanup when component unmounts
    return () => {
      if (socket) {
        socket.disconnect();
      }
    };
  }, []);

  useEffect(() => {
    // componentDidUpdate(prevProps) {
    if (props.isPreloader === true) {
      this.socket.emit("update", this.props);

      if (this.props.isPreloader === true) {
        document.getElementById("preloader").style.display = "block";
        document.getElementById("status").style.display = "block";

        setTimeout(function () {
          document.getElementById("preloader").style.display = "none";
          document.getElementById("status").style.display = "none";
        }, 2500);
      } else {
        document.getElementById("preloader").style.display = "none";
        document.getElementById("status").style.display = "none";
      }
    } else {
      // Handle logic when preloader is not active
    }
  }, [props]);

  /**
   * Open/close right sidebar
   */
  const toggleRightSidebar = () => {
    this.props.toggleRightSidebar();
  };

  // Disconnect the socket connection when the component unmounts
  // componentWillUnmount() {
  //   if (this.socket) {
  //     this.socket.disconnect();
  //   }
  // }

  // componentDidMount() {
  //   // Scrollto 0,0
  //   window.scrollTo(0, 0);

  //   const title = this.props.location.pathname;
  //   let currentage = title.charAt(1).toUpperCase() + title.slice(2);

  //   document.title = currentage + " | Lubumbashi Admin";

  //   this.props.changeLayout("horizontal");
  //   if (this.props.topbarTheme) {
  //     this.props.changeTopbarTheme(this.props.topbarTheme);
  //   }
  //   if (this.props.layoutWidth) {
  //     this.props.changeLayoutWidth(this.props.layoutWidth);
  //   }
  //   if (this.props.showRightSidebar) {
  //     this.toggleRightSidebar();
  //   }
  // }

  /**
   * Opens the menu - mobile
   */
  const openMenu = () => {
    setIsMenuOpened(!isMenuOpened);
  };

  return (
    <React.Fragment>
      <div id="preloader">
        <div id="status">
          <div className="spinner">
            <i className="ri-loader-line spin-icon"></i>
          </div>
        </div>
      </div>

      <div id="layout-wrapper">
        <Header
          theme={this.props.topbarTheme}
          isMenuOpened={this.state.isMenuOpened}
          toggleRightSidebar={this.toggleRightSidebar}
          openLeftMenuCallBack={this.openMenu}
        />
        <Navbar menuOpen={this.state.isMenuOpened} />
        <div className="main-content">
          {this.props.children}
          <Footer />
        </div>
      </div>
      <Rightbar />
    </React.Fragment>
  );
};

const mapStatetoProps = (state) => {
  return {
    ...state.Layout,
  };
};
export default connect(mapStatetoProps, {
  changeTopbarTheme,
  toggleRightSidebar,
  changeLayout,
  changeLayoutWidth,
})(withRouter(Layout));
