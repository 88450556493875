import React, { Component } from "react";

import {
  Row,
  Col,
  Input,
  Button,
  Alert,
  Container,
  Label,
  FormGroup,
} from "reactstrap";

// Redux
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";

// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation";

// actions
import { checkLogin, apiError } from "../../store/actions";
import UserStore from "../../store/UserStore";

import { ToastContainer, toast } from "react-toastify";
// import images
import logodark from "../../assets/images/logo-dark.png";
import logoLight from "../../assets/images/logo-light.png";
import { AdminLogin } from "../../Endpoint/Admin";
import { setUser } from "../../config/TokenHelper";
class Login extends Component {
  constructor(props) {
    super(props);

    this.state = {
      mobile: "",
      password: "",
      passwordError: "",
      mobileError: "",
      errorMessage: "",
    };
    this.handleUserChange = this.handleUserChange.bind(this);
    this.handlePassChange = this.handlePassChange.bind(this);

    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    const authToken = localStorage.getItem("token");
    if (authToken) {
      window.location.href = "/dashboard";
    }

    console.log("token", authToken);
    console.log(" Hi");
  }

  handleUserChange(evt) {
    this.setState({
      mobile: evt.target.value,
    });
  }

  handlePassChange(evt) {
    this.setState({
      password: evt.target.value,
    });
  }

  handleSubmit(e, values) {
    e.preventDefault(e);
    const userObject = {
      mobile: this.state.mobile,
      password: this.state.password,
    };

    console.log(userObject);

    AdminLogin(userObject)
      .then((response) => {
        console.log("status", response);
        if (response.status !== 200) {
          console.log("error msg ---> ", response.status);

          toast.error(response.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        } else {
          const authToken = response.authToken;
          const authKey = response.authKey;
          // toast.success("login successfull");
          //   console.log("Login Hua", JSON.stringify(response.data));
          UserStore.set(
            () => ({
              token: authToken,
              authkey: authKey,
              user: JSON.stringify(response.data),
            }),
            "login"
          );
          setUser(authToken, authKey, JSON.stringify(response.data));
          window.location.href = "/dashboard";
        }
      })
      .catch((error) => {
        console.log("error ---> ", error);
      });

    this.setState({ mobile: "", password: "" });

    // this.props.checkLogin(values, this.props.history);
  }

  componentDidMount() {
    this.props.apiError("");
    document.body.classList.add("auth-body-bg");
  }

  componentWillUnmount() {
    document.body.classList.remove("auth-body-bg");
  }

  render() {
    var { mobile } = this.state;
    console.log(process.env.REACT_APP_DEFAULTAUTH);
    return (
      <React.Fragment>
        <div className="home-btn d-none d-sm-block"></div>

        <div>
          <Container fluid className="p-0">
            <Row className="no-gutters">
              <Col lg={4}>
                <div className="authentication-page-content p-4 d-flex align-items-center min-vh-100">
                  <div className="w-100">
                    <Row className="justify-content-center">
                      <Col lg={9}>
                        <div>
                          <div className="text-center">
                            <div>
                              <Link to="">
                                <img
                                  src={logodark}
                                  height="100"
                                  width="100"
                                  alt="logo"
                                />
                              </Link>
                            </div>

                            <h4 className="font-size-18 mt-4">
                              Welcome Back !
                            </h4>
                            <p className="text-muted">
                              Sign in to continue to lubumbashi.
                            </p>
                          </div>

                          {this.props.loginError && this.props.loginError ? (
                            <Alert color="danger">
                              {this.props.loginError}
                            </Alert>
                          ) : null}

                          <div className="p-2 mt-5">
                            <AvForm
                              className="form-horizontal"
                              onValidSubmit={this.handleSubmit}
                            >
                              <FormGroup className="auth-form-group-custom mb-4">
                                <i className="ri-user-2-line auti-custom-input-icon"></i>
                                <Label htmlFor="username">mobile</Label>
                                <AvField
                                  name="username"
                                  value={this.state.mobile}
                                  onChange={this.handleUserChange}
                                  type="text"
                                  className="form-control"
                                  id="username"
                                  placeholder="Enter mobile"
                                />
                                <div className="text-danger">
                                  {this.state.mobileError}
                                </div>
                              </FormGroup>

                              <FormGroup className="auth-form-group-custom mb-4">
                                <i className="ri-lock-2-line auti-custom-input-icon"></i>
                                <Label htmlFor="userpassword">Password</Label>
                                <AvField
                                  name="password"
                                  value={this.state.password}
                                  onChange={this.handlePassChange}
                                  type="password"
                                  className="form-control"
                                  id="userpassword"
                                  placeholder="Enter password"
                                />
                              </FormGroup>

                              <div className="mt-4 text-center">
                                <Button
                                  color="primary"
                                  className="w-md waves-effect waves-light"
                                  type="submit"
                                >
                                  Log In
                                </Button>
                              </div>

                              <div className="mt-4 text-center"></div>
                            </AvForm>
                          </div>

                          <div className="mt-5 text-center"></div>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </div>
              </Col>
              <Col lg={8}>
                <div className="authentication-bg">
                  <div className="bg-overlay"></div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <ToastContainer />
      </React.Fragment>
    );
  }
}
const mapStatetoProps = (state) => {
  const { loginError } = state.Login;
  return { loginError };
};

export default withRouter(
  connect(mapStatetoProps, { checkLogin, apiError })(Login)
);
