import React, { Component } from "react";
import { Row, Col, Card, CardBody, ButtonGroup, Button } from "reactstrap";

//Import Charts
import ReactApexChart from "react-apexcharts";
import { monthlyOrdersTable } from "../../Endpoint/Admin";
import "./dashboard.scss";

class RevenueAnalyticsTableBooking extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loaded: false,
      series: [
        // {
        //     name: 'Room Booked',
        //     type: 'column',
        //     data: [23, 42, 35, 27, 43, 22, 17, 31, 22, 22, 12, 16]
        // },
        // {
        //     name: '2020',
        //     type: 'line',
        //     data: [23, 32, 27, 38, 27, 32, 27, 38, 22, 31, 21, 16]
        // }
      ],
      options: {
        chart: {
          toolbar: {
            show: false,
          },
        },
        stroke: {
          width: [0, 3],
          curve: "smooth",
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: "20%",
          },
        },
        dataLabels: {
          enabled: false,
        },

        legend: {
          show: false,
        },
        colors: ["#5664d2", "#1cbb8c"],
        //:'',
        xaxis: {
          categories: [],
        },

        //labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
      },
    };
    this.Bookings = this.Bookings.bind(this);
  }

  Bookings() {
    monthlyOrdersTable()
      .then((result) => {
        const months = [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ];
        let axis = [];
        let values = [];
        let _options = this.state.options;
        let bookingData = result.data[0];
        //  console.log("BookingData-->", bookingData);
        for (let index = 0; index < bookingData.length; index++) {
          const element = bookingData[index];
          axis.push(`${months[element.month - 1]} ${element.year}`);
          values.push(element.count);
        }
        // console.log('Axiss ---> ', axis);
        _options.xaxis.categories = axis;
        this.setState({
          series: [
            {
              name: "Table Booked",
              type: "column",
              data: values,
            },
          ],
          options: _options,
          loaded: true,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  componentDidMount() {
    this.Bookings();
  }

  render() {
    // console.log("ROOM booking",this.state.series);

    return (
      <React.Fragment>
        <Card>
          <CardBody>
            <div className="float-right d-none d-md-inline-block">
              <ButtonGroup className="mb-2">
                {/* <Button size="sm" color="light" type="button">
                  Today
                </Button>
                <Button size="sm" color="light" type="button">
                  Weekly
                </Button> */}
                <Button size="sm" color="light" active type="button">
                  Monthly
                </Button>
              </ButtonGroup>
            </div>
            <h4 className="card-title mb-4">
              Revenue Analytics for Room Booking
            </h4>
            <div>
              {this.state.loaded ? (
                <div id="line-column-chart" className="apex-charts" dir="ltr">
                  <ReactApexChart
                    options={this.state.options}
                    series={this.state.series}
                    type="line"
                    height="280"
                  />
                </div>
              ) : (
                <></>
              )}
            </div>
          </CardBody>

          {/* <CardBody className="border-top text-center">
                        <Row>
                            <Col sm={4}>
                                <div className="d-inline-flex">
                                    <h5 className="mr-2">$12,253</h5>
                                    <div className="text-success">
                                        <i className="mdi mdi-menu-up font-size-14"> </i>2.2 %
                                                    </div>
                                </div>
                                <p className="text-muted text-truncate mb-0">This month</p>
                            </Col>

                            <Col sm={4}>
                                <div className="mt-4 mt-sm-0">
                                    <p className="mb-2 text-muted text-truncate"><i className="mdi mdi-circle text-primary font-size-10 mr-1"></i> This Year :</p>
                                    <div className="d-inline-flex">
                                        <h5 className="mb-0 mr-2">$ 34,254</h5>
                                        <div className="text-success">
                                            <i className="mdi mdi-menu-up font-size-14"> </i>2.1 %
                                                        </div>
                                    </div>
                                </div>
                            </Col>
                            <Col sm={4}>
                                <div className="mt-4 mt-sm-0">
                                    <p className="mb-2 text-muted text-truncate"><i className="mdi mdi-circle text-success font-size-10 mr-1"></i> Previous Year :</p>
                                    <div className="d-inline-flex">
                                        <h5 className="mb-0">$ 32,695</h5>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </CardBody> */}
        </Card>
      </React.Fragment>
    );
  }
}

export default RevenueAnalyticsTableBooking;
