import React, { Component } from "react";
import { MDBDataTable,MDBTableHead,MDBTableBody,MDBTable,MDBBtn } from "mdbreact";
import {AvInput , Card, CardBody, Col,Form, Row, FormGroup, Label, Input, CustomInput,Button,Modal,Container, ModalHeader, ModalBody, ModalFooter  } from "reactstrap";
import { createNotification,getNotificationAdmin,getUserList,updateFoodDeliveryBoy,removeFoodDeliveryBoy } from "../../Endpoint/Admin/index";
//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';
import { ToastContainer, toast } from 'react-toastify';
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import Select from 'react-select'
import { AvForm, AvField } from 'availity-reactstrap-validation';
import { first } from "lodash";


class Notification extends Component {
constructor(props) {
super(props);
this.state = {
  
items: [],
  isLoaded: false,
updatemodal:false,
modal: false,
title:'',
allUser:false,
users:'',
details:'',
userData:[],
success: {

},
breadcrumbItems : [
{ title : "", link : "#" },
{ title : "", link : "#" },
],
customchk: true,
toggleSwitch: true,
tableData: {
columns: [
    {
        label: ' Title ',
        field: 'title',
        sort: 'asc',
        width: 60
        },
  {
    label: ' Details',
    field: 'details',
    sort: 'asc',
    width: 60
    },
    
      {
        label: ' NotiUniqId ',
        field: 'notiUniqId',
        sort: 'asc',
        width: 60
        },
  
  
],
rows: [



]
}

};
this.reader = new FileReader();
this.handleChange = this.handleChange.bind(this);

this.handleSubmit = this.handleSubmit.bind(this);
this.toggle = this.toggle.bind(this);
this.ShortlistedUser = this.ShortlistedUser.bind(this)
  this.updatetoggle = this.updatetoggle.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
  
}
toggle() {
this.setState({
  modal: !this.state.modal,
  title:'',
  allUser:false,
  users:'',
  details:'',
});
  }
  updatetoggle() {
    this.setState({
      updatemodal: !this.state.updatemodal
    });
    }

    handleCloseModal () {
      this.setState({
        updatemodal: false,
        modal:false,
      
      });
  	}
  
     
    //
    ShortlistedUser() {
      this.setState({
        allUser: !this.state.allUser
      })
    }

handleChange=(e)=>{
this.setState({
[e.target.id]:e.target.value
},()=>{

})

}


onChange(selectedOptions){
  const selected=[];

 let selectedOption=(selectedOptions);
 for (let i = 0; i < selectedOption.length; i++){

selected.push(selectedOption[i].value)

 }

this.setState({
  users: [ ...selected],
  users:selected
 })

 
 }


handleSubmit(e) {
e.preventDefault();
const userObject = {
    title:this.state.title,
  allUser:this.state.allUser,
  users:this.state.users,
  details:this.state.details,


};



    createNotification(userObject).then((response) => {
if (response.status !== 200) {


toast.error(response.message, {
position: "top-right",
autoClose: 5000,
hideProgressBar: false,
closeOnClick: true,
pauseOnHover: true,
draggable: true,
progress: undefined,
});

} else {
this.setState({
handleResponse: {
isSuccess: response.status === 200,
message: "success",

},

});
this.getALlluser()
this.handleCloseModal();
toast.success(response.message, {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    });

}
}).catch((error) => {

toast.error("error");
})


}

userList(){
  getUserList().then((result)=>{


this.setState({
  userData:result.data,
    })
    const dropDownValue = this.state.userData.map((response) => ({

      "value" : response._id,
      "label" : response.firstName
    }))
    this.setState(
      {
        dropDownOpt: dropDownValue
      }
    )
    
    }).catch((error)=>{
       
    })
    
}



getALlluser() {
    getNotificationAdmin().then((result) => {
  
  this.setState({
  isLoaded: true,
  items: ( result.data),
  tableData: {
  ...this.state.tableData, rows:result.data,
    list:  result.data.map(x => {
   
    
     return x
  })
  },
  
  });
  });
  
}

componentDidMount() {
this.userList();
    this.getALlluser();
    
  }

  




render() {
const {  handleResponse, invalidImage, items,previewImage, } = this.state;


const content = this.state.allUser 
? null
:  <div>
<Label for="ID">users</Label>
<Select 
options={this.state.dropDownOpt} 
onChange={this.onChange.bind(this)}
isMulti
/>
</div>


// const content = this.state.checked 
// ? <div> Content </div>
// : null;

return (


<React.Fragment>
<div className="page-content">
<Container fluid>

<Breadcrumbs title="" breadcrumbItems={this.state.breadcrumbItems} />

<Row>
<Col xs={12}>
<Card>
<CardBody>
<Button color='primary' onClick={this.toggle}>Add Notification</Button>
<Modal isOpen={this.state.modal} toggle={this.toggle} className={this.props.className}>
<ModalHeader toggle={this.toggle}>Notification Details</ModalHeader>
<ModalBody>
<AvForm onValidSubmit={this.handleSubmit}>

<Row form>
          <Col md={6}>
            <FormGroup>
              <Label for="name">Title</Label>
              <AvField    type="text" value={this.state.title} onChange={this.handleChange}   id="title" name="title"  required />

            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <Label for="ID">Details</Label>
              <AvField   type="text"  value={this.state.details} onChange={this.handleChange}  id="details"  name="details" required />

            </FormGroup>
          </Col>
        </Row>

        <Row form>
          <Col md={6}>
          <FormGroup check>
         
          <Label check>
            <Input type="checkbox"  checked={ this.state.allUser } onChange={ this.ShortlistedUser }/>
            Check me out to send Notification for all Users
          </Label>
        </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
            { content }
            

            </FormGroup>
          </Col>
        </Row>

        

        <div className="text-center">

<Button color="primary" size="sm">Submit</Button>{' '}
</div>

</AvForm> 
</ModalBody>
</Modal>
             

</CardBody>
</Card>
</Col>
</Row>

<Breadcrumbs title="Data Tables" breadcrumbItems={this.state.breadcrumbItems} />

<Row>
<Col xs={12}>
<Card>
<CardBody>
<h4 className="card-title">Notification  Datatable </h4>
<p className="card-title-desc">


</p>

<MDBDataTable  responsive bordered data={this.state.tableData} />


</CardBody>
</Card>
</Col>
</Row>




</Container>
</div>
<ToastContainer />
</React.Fragment>
);
}
}

export default Notification;
