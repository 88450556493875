import React, { Component } from "react";
import { MDBDataTable,MDBTableHead,MDBTableBody,MDBTable,MDBBtn } from "mdbreact";
import {AvInput , Card, CardBody, Col,Form, Row, FormGroup, Label, Input, CustomInput,Button,Modal,Container, ModalHeader, ModalBody, ModalFooter  } from "reactstrap";
import { createAmenityType,updateAmenityType,removeAmenityType,getAmenityTypeList,editAmenityType, updateRoomType } from "../../Endpoint/Admin/index";
//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';

import { ToastContainer, toast } from 'react-toastify';
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';

import { AvForm, AvField } from 'availity-reactstrap-validation';



class FormElements extends Component {
constructor(props) {
super(props);
this.state = {
  invalidFile: false,
  previewImage: undefined,
items: [],
  isLoaded: false,
updatemodal:false,
modal: false,
amenityType:'',
amenityTypeId:null,


success: {

},
breadcrumbItems : [
{ title : "", link : "#" },
{ title : " ", link : "#" },
],
customchk: true,
toggleSwitch: true,
tableData: {
columns: [
  
  {
    label: 'amenityType',
    field: 'type',
    sort: 'asc',
    width: 60
    },
    



   

   
{
label: 'Update',
field: 'update',
sort: 'asc',
width: 100
},


{
label: 'Remove',
field: 'remove',
sort: 'asc',
width: 100
}
],
rows: [
]
}

};
this.reader = new FileReader();
this.handleChange = this.handleChange.bind(this);

this.handleSubmit = this.handleSubmit.bind(this);
this.toggle = this.toggle.bind(this);

  this.handleCloseModal = this.handleCloseModal.bind(this);
  

}
toggle() {
this.setState({
  modal: !this.state.modal,
  amenityType: '',
  amenityTypeId:'',
  

});
  }
  updatetoggle() {
    this.setState({
      updatemodal: !this.state.updatemodal
    });
    }


   

    handleCloseModal () {
      this.setState({
        updatemodal: false,
        modal:false,
      
      });
  	}
  






handleChange=(e)=>{
this.setState({
[e.target.id]:e.target.value
},()=>{

})

}



handleSubmit(e) {
e.preventDefault();

 const aminityData = {
  amenityType:this.state.amenityType,
  amenityTypeId:this.state.amenityTypeId,
 }

 if (this.state.amenityTypeId=='' || this.state.amenityTypeId==null) {

createAmenityType(aminityData).then((response) => {
if (response.status !== 200) {
console.log('error msg ---> ', response)

toast.error(response.message, {
position: "top-right",
autoClose: 5000,
hideProgressBar: false,
closeOnClick: true,
pauseOnHover: true,
draggable: true,
progress: undefined,
});

} else {
this.setState({
handleResponse: {
isSuccess: response.status === 200,
message: "success",
amenityType:'',
amenityTypeId:'',

},

});
this.getALlluser()
console.log(response);
this.handleCloseModal();

toast.success("createAmenityType added successfully");
}
}).catch((error) => {
console.log('error ---> ', error)
toast.error("error");
})
 }else{

  updateAmenityType(aminityData).then((updateResults)=>{
    if (updateResults.status !== 200) {
      console.log('error msg ---> ', updateResults)
      
      toast.error(updateResults.message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      });
      
      } else {
      this.setState({
      handleResponse: {
      isSuccess: updateResults.status === 200,
      message: "success",
      amenityTypeId:'',
      
      },
      
      });
      this.getALlluser()
      
      this.handleCloseModal();
      
      toast.success("Amenity updated successfully");
      }
  }).catch((error) => {
  console.log('error ---> ', error)
  toast.error("error");
  })
 }

}

removeAmenity(id) {
    
  removeAmenityType(id).then((results) => {
   
    this.getALlluser();
  }).catch((error) => {
    console.log('error ---> ', error)
    toast.error("error");
    })
  }

getALlluser() {
  getAmenityTypeList().then((result) => {
    console.log(result);
    this.setState({
    isLoaded: true,
    items: ( result.data),
    tableData: {
    ...this.state.tableData, rows:result.data,
      list:  result.data.map(x => {
     
       x.update = <button className="btn btn-primary" onClick={() => this.editAmenityTypes(x._id.toString(), x)}>Update</button>
       x.remove = <button className="btn btn-danger" onClick={() => this.removeAmenity(x._id.toString())}>Remove</button>
      
       return x
    })
    },
    
    });
    });
    
}

componentDidMount() {

  this.getALlluser();
}
editAmenityTypes(id){
  console.log(id);
  editAmenityType(id).then((results)=>{
    
    this.setState({
      amenityType:results.data.type,
      amenityTypeId:results.data._id,
    })
    
  }).catch((error)=>{
    console.log('error ---> ', error)
      toast.error("error");
  })
  this.toggle()
  }


render() {
const { handleResponse,  items, } = this.state;


return (


<React.Fragment>
<div className="page-content">
<Container fluid>

<Breadcrumbs title="" breadcrumbItems={this.state.breadcrumbItems} />

<Row>
<Col xs={12}>
<Card>
<CardBody>
<Button color='primary' onClick={this.toggle}>Add AmenityType</Button>
<Modal isOpen={this.state.modal} toggle={this.toggle} className={this.props.className}>
<ModalHeader toggle={this.toggle}>AmenityType Details</ModalHeader>
<ModalBody>
<AvForm onValidSubmit={this.handleSubmit}>

<Row form>
          <Col md={12}>
            <FormGroup>
              <Label for="email">amenityType</Label>
              <AvField    type="text" value={this.state.amenityType} onChange={this.handleChange}   id="amenityType" name="amenityType"  required />

            </FormGroup>
          </Col>
          
        </Row>
 



            
<div className="text-center mt-4">

<Button color="primary" size="sm">Submit</Button>{' '}
</div>

</AvForm> 
</ModalBody>
</Modal>
             



</CardBody>
</Card>
</Col>
</Row>
<h4 className="card-title">AmenityType</h4>
<Breadcrumbs title="Data Tables" breadcrumbItems={this.state.breadcrumbItems} />

<Row>
<Col xs={12}>
<Card>
<CardBody>
<h4 className="card-title">AmenityType </h4>
<p className="card-title-desc">


</p>

<MDBDataTable  responsive bordered data={this.state.tableData} />


</CardBody>
</Card>
</Col>
</Row>




</Container>
</div>
<ToastContainer />
</React.Fragment>
);
}
}

export default FormElements;
