import React, { Component } from "react";
import {
  MDBDataTable,
  MDBTableHead,
  MDBTableBody,
  MDBTable,
  MDBBtn,
} from "mdbreact";
import {
  AvInput,
  Card,
  CardBody,
  Col,
  Form,
  Row,
  FormGroup,
  Label,
  Input,
  CustomInput,
  Button,
  Modal,
  Container,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import {
  createRoomType,
  getRoomTypeList,
  editRoomTypeById,
  updateRoomType,
  removeRoomType,
  updateRoomHospitalityService,
} from "../../Endpoint/Admin/index";
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { ToastContainer, toast } from "react-toastify";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider from "react-bootstrap-table2-toolkit";

import { AvForm, AvField } from "availity-reactstrap-validation";
import { first } from "lodash";

class FormElements extends Component {
  constructor(props) {
    super(props);
    this.state = {
      items: [],
      isLoaded: false,
      updatemodal: false,
      modal: false,
      type: "",
      amount: "",
      discount: "",
      additionalCharges: "",
      noOfRooms : "",
      roomTypeId: null,
      success: {},
      breadcrumbItems: [
        { title: "", link: "#" },
        { title: "", link: "#" },
      ],
      customchk: true,
      toggleSwitch: true,
      tableData: {
        columns: [
          {
            label: " Type",
            field: "type",
            sort: "asc",
            width: 60,
          },
          {
            label: " Amount ",
            field: "amount",
            sort: "asc",
            width: 60,
          },

          {
            label: "Discount",
            field: "discount",
            sort: "asc",
            width: 60,
          },
          {
            label : "Totoal No Of Rooms",
            field : "noOfRooms",
            sort : "asc",
            width : 60,
          },
          {
            label: "AdditionalCharges",
            field: "additionalCharges",
            sort: "asc",
            width: 60,
          },

          {
            label: "Update",
            field: "update",
            sort: "asc",
            width: 100,
          },

          {
            label: "Remove",
            field: "remove",
            sort: "asc",
            width: 100,
          },
        ],
        rows: [],
      },
    };
    this.reader = new FileReader();
    this.handleChange = this.handleChange.bind(this);

    this.handleSubmit = this.handleSubmit.bind(this);
    this.toggle = this.toggle.bind(this);

    this.updatetoggle = this.updatetoggle.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
  }
  toggle() {
    this.setState({
      modal: !this.state.modal,
      type: "",
      amount: "",
      discount: "",
      noOfRooms : "",
      additionalCharges: "",
      roomTypeId: "",
    });
  }
  updatetoggle() {
    this.setState({
      updatemodal: !this.state.updatemodal,
    });
  }

  handleCloseModal() {
    this.setState({
      updatemodal: false,
      modal: false,
    });
  }

  handleChange = (e) => {
    this.setState(
      {
        [e.target.id]: e.target.value,
      },
      () => {}
    );
  };

  handleSubmit(e) {
    e.preventDefault();
    const userObject = {
      type: this.state.type,
      amount: this.state.amount,
      discount: this.state.discount,
      noOfRooms : this.state.noOfRooms,
      additionalCharges: this.state.additionalCharges,
      roomTypeId: this.state.roomTypeId,
    };

    if (this.state.roomTypeId == "" || this.state.roomTypeId == null) {
      createRoomType(userObject)
        .then((response) => {
          if (response.status !== 200) {
            toast.error(response.message, {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          } else {
            this.setState({
              handleResponse: {
                isSuccess: response.status === 200,
                message: "success",
                type: "",
                amount: "",
                discount: "",
                noOfRooms : "",
                additionalCharges: "",
                roomTypeId: "",
              },
            });
            this.getALlluser();

            this.handleCloseModal();

            toast.success("RoomType added successfully");
          }
        })
        .catch((error) => {
          toast.error("error");
        });
    } else {
      updateRoomType(userObject)
        .then((updateResults) => {
          if (updateResults.status !== 200) {
            toast.error(updateResults.message, {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          } else {
            this.setState({
              handleResponse: {
                isSuccess: updateResults.status === 200,
                message: "success",
                roomTypeId: "",
              },
            });
            this.getALlluser();

            this.handleCloseModal();

            toast.success("Room Type updated successfully");
          }
        })
        .catch((error) => {
          toast.error("error");
        });
    }
  }
  getALlluser() {
    getRoomTypeList().then((result) => {
      this.setState({
        isLoaded: true,
        items: result.data,
        tableData: {
          ...this.state.tableData,
          rows: result.data,
          list: result.data.map((x) => {
            x.update = (
              <button
                className="btn btn-primary"
                onClick={() => this.editRoomType(x._id.toString(), x)}
              >
                Update
              </button>
            );
            x.remove = (
              <button
                className="btn btn-danger"
                onClick={() => this.removeRoomstype(x._id.toString())}
              >
                Remove
              </button>
            );

            return x;
          }),
        },
      });
    });
  }
  removeRoomstype(id) {
    removeRoomType(id)
      .then((results) => {
        toast.success("Room Type removed successfully");
        this.getALlluser();
      })
      .catch((error) => {
        toast.error("error");
      });
  }
  componentDidMount() {
    this.getALlluser();
  }

  editRoomType(id) {
    editRoomTypeById(id)
      .then((results) => {
        this.setState({
          type: results.data.type,
          amount: results.data.amount,
          discount: results.data.discount,
          noOfRooms : results.data.noOfRooms,
          additionalCharges: results.data.additionalCharges,
          roomTypeId: results.data._id,
        });
      })
      .catch((error) => {
        toast.error("error");
      });
    this.toggle();
  }

  render() {
    const { handleResponse, invalidImage, items, previewImage } = this.state;

    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Breadcrumbs
              title=""
              breadcrumbItems={this.state.breadcrumbItems}
            />

            <Row>
              <Col xs={12}>
                <Card>
                  <CardBody>
                    <Button color="primary" onClick={this.toggle}>
                      Add Room Type
                    </Button>
                    <Modal
                      isOpen={this.state.modal}
                      toggle={this.toggle}
                      className={this.props.className}
                    >
                      <ModalHeader toggle={this.toggle}>
                        Room Type Details
                      </ModalHeader>
                      <ModalBody>
                        <AvForm onValidSubmit={this.handleSubmit}>
                          <Row form>
                            <Col md={6}>
                              <FormGroup>
                                <Label for="name">Type</Label>
                                <AvField
                                  type="text"
                                  value={this.state.type}
                                  onChange={this.handleChange}
                                  id="type"
                                  name="type"
                                  required
                                />
                              </FormGroup>
                            </Col>
                            <Col md={6}>
                              <FormGroup>
                                <Label for="ID">Amount (in usd)</Label>
                                <AvField
                                  type="number"
                                  min={1}
                                  value={this.state.amount}
                                  onChange={this.handleChange}
                                  id="amount"
                                  name="amount"
                                  required
                                />
                              </FormGroup>
                            </Col>
                          </Row>

                          <Row form>
                            <Col md={6}>
                              <FormGroup>
                                <Label for="Mac">Discount (in %)</Label>
                                <AvField
                                  type="number"
                                  min={0}
                                  value={this.state.discount}
                                  onChange={this.handleChange}
                                  id="discount"
                                  name="discount"
                                />
                              </FormGroup>
                            </Col>
                            <Col md={6}>
                              <FormGroup>
                                <Label for="Mac">
                                  AdditionalCharges (in usd)
                                </Label>
                                <AvField
                                  type="number"
                                  min={0}
                                  value={this.state.additionalCharges}
                                  onChange={this.handleChange}
                                  id="additionalCharges"
                                  name="additionalCharges"
                                />
                              </FormGroup>
                            </Col>
                          </Row>
                          <Row form>
                            <Col md={6}>
                              <FormGroup>
                                <Label for="Mac">Total No of Rooms</Label>
                                <AvField
                                  type="number"
                                  min={0}
                                  value={this.state.noOfRooms}
                                  onChange={this.handleChange}
                                  id="noOfRooms"
                                  name="noOfRooms"
                                />
                              </FormGroup>
                            </Col>
                            </Row>
                          <div className="text-center">
                            <Button color="primary" size="sm">
                              Submit
                            </Button>{" "}
                          </div>
                        </AvForm>
                      </ModalBody>
                    </Modal>
                  </CardBody>
                </Card>
              </Col>
            </Row>

            <Breadcrumbs
              title="Data Tables"
              breadcrumbItems={this.state.breadcrumbItems}
            />

            <Row>
              <Col xs={12}>
                <Card>
                  <CardBody>
                    <h4 className="card-title">Room Type Datatable </h4>
                    <p className="card-title-desc"></p>

                    <MDBDataTable
                      responsive
                      bordered
                      data={this.state.tableData}
                    />
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
        <ToastContainer />
      </React.Fragment>
    );
  }
}

export default FormElements;
