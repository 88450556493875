import React, { Component } from "react";
import { MDBDataTable } from "mdbreact";
import {
  Card,
  CardBody,
  Col,
  Row,
  Button,
  Modal,
  Container,
  ModalHeader,
  ModalBody,
  FormGroup,
  Label,
  CustomInput,
} from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { ToastContainer, toast } from "react-toastify";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import {
  createFoodSettings,
  getFoodSettings,
  updateFoodSettings,
  deleteFoodSettings,
} from "../../Endpoint/Admin/index";

class FoodSettings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      items: [],
      modal: false,
      startTime: new Date(),
      endTime: new Date(),
      isActive: true,
      daysOfWeek: [],
      settingId: "",
      breadcrumbItems: [
        { title: "Dashboard", link: "#" },
        { title: "Food Settings", link: "#" },
      ],
      tableData: {
        columns: [
          { label: "Start Time", field: "startTime", sort: "asc", width: 150 },
          { label: "End Time", field: "endTime", sort: "asc", width: 150 },
          { label: "Status", field: "isActive", sort: "asc", width: 100 },
          {
            label: "Days of Week",
            field: "daysOfWeek",
            sort: "asc",
            width: 200,
          },
          { label: "Update", field: "update", sort: "asc", width: 100 },
          { label: "Remove", field: "remove", sort: "asc", width: 100 },
        ],
        rows: [],
      },
    };
    this.toggle = this.toggle.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
    this.editSetting = this.editSetting.bind(this);
    this.removeSetting = this.removeSetting.bind(this);
  }

  componentDidMount() {
    this.getAllSettings();
  }

  toggle() {
    this.setState((prevState) => ({
      modal: !prevState.modal,
      startTime: new Date(),
      endTime: new Date(),
      isActive: true,
      daysOfWeek: [],
      settingId: "",
    }));
  }

  handleChange(e) {
    const { id, value, checked } = e.target;
    const newValue = id === "isActive" ? checked : value;
    this.setState({
      [id]: newValue,
    });
  }

  handleDaysChange(e) {
    const { options } = e.target;
    const daysOfWeek = [];
    for (let i = 0, l = options.length; i < l; i++) {
      if (options[i].selected) {
        daysOfWeek.push(options[i].value);
      }
    }
    this.setState({ daysOfWeek });
  }

  handleCloseModal() {
    this.setState({
      modal: false,
    });
  }

  handleSubmit(e) {
    e.preventDefault();
    const { startTime, endTime, isActive, daysOfWeek, settingId } = this.state;

    // Form validation
    if (startTime >= endTime) {
      toast.error("End time must be after start time");
      return;
    }

    const data = {
      startTime: startTime.toISOString(),
      endTime: endTime.toISOString(),
      isActive,
      daysOfWeek,
    };

    if (!settingId) {
      createFoodSettings(data)
        .then((response) => {
          if (response && response.success) {
            this.getAllSettings();
            this.handleCloseModal();
            toast.success(response.message);
          } else {
            toast.error(
              response ? response.message : "Error creating food settings"
            );
          }
        })
        .catch((error) => {
          toast.error("Error creating food settings");
        });
    } else {
      updateFoodSettings({ id: settingId, ...data })
        .then((response) => {
          if (response && response.success) {
            this.getAllSettings();
            this.handleCloseModal();
            toast.success(response.message);
          } else {
            toast.error(
              response ? response.message : "Error updating food settings"
            );
          }
        })
        .catch((error) => {
          toast.error("Error updating food settings");
        });
    }
  }

  getAllSettings() {
    getFoodSettings()
      .then((result) => {
        if (result && result.success) {
          const settings = result.data.map((setting) => ({
            ...setting,
            startTime: new Date(setting.startTime).toLocaleTimeString([], {
              hour: "2-digit",
              minute: "2-digit",
              hour12: true, // Explicitly set to 12-hour format
            }),
            endTime: new Date(setting.endTime).toLocaleTimeString([], {
              hour: "2-digit",
              minute: "2-digit",
              hour12: true, // Explicitly set to 12-hour format
            }),
            isActive: setting.isActive ? "Active" : "Inactive",
            daysOfWeek: setting.daysOfWeek.join(", "),
            update: (
              <button
                className="btn btn-primary"
                onClick={() => this.editSetting(setting._id, setting)}
              >
                Update
              </button>
            ),
            remove: (
              <button
                className="btn btn-danger"
                onClick={() => this.removeSetting(setting._id)}
              >
                Remove
              </button>
            ),
          }));
          this.setState({
            items: result.data,
            tableData: {
              ...this.state.tableData,
              rows: settings,
            },
          });
        } else {
          toast.error("Error fetching settings");
        }
      })
      .catch((error) => {
        toast.error("Error fetching settings");
      });
  }

  editSetting(id, setting) {
    this.setState({
      startTime: new Date(setting.startTime),
      endTime: new Date(setting.endTime),
      isActive: setting.isActive === "Active",
      daysOfWeek: setting.daysOfWeek,
      settingId: setting._id,
      modal: true,
    });
  }

  removeSetting(id) {
    deleteFoodSettings(id)
      .then((response) => {
        if (response && response.success) {
          this.getAllSettings();
          toast.success(response.message);
        } else {
          toast.error(response ? response.message : "Error deleting settings");
        }
      })
      .catch((error) => {
        toast.error("Error deleting settings");
      });
  }

  render() {
    const { modal, startTime, endTime, isActive, daysOfWeek, tableData } =
      this.state;
    const dayOptions = [
      { value: "Monday", label: "Monday" },
      { value: "Tuesday", label: "Tuesday" },
      { value: "Wednesday", label: "Wednesday" },
      { value: "Thursday", label: "Thursday" },
      { value: "Friday", label: "Friday" },
      { value: "Saturday", label: "Saturday" },
      { value: "Sunday", label: "Sunday" },
    ];

    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Breadcrumbs
              title="Food Settings"
              breadcrumbItems={this.state.breadcrumbItems}
            />
            <Row>
              <Col xs={12}>
                <Card>
                  <CardBody>
                    <Button color="primary" onClick={this.toggle}>
                      Add Food Setting
                    </Button>
                    <Modal isOpen={modal} toggle={this.toggle}>
                      <ModalHeader toggle={this.toggle}>
                        Food Setting Details
                      </ModalHeader>
                      <ModalBody>
                        <AvForm onValidSubmit={this.handleSubmit}>
                          <Row form>
                            <Col md={6}>
                              <FormGroup>
                                <Label for="startTime">Start Time</Label>
                                <DatePicker
                                  selected={startTime}
                                  onChange={(date) =>
                                    this.setState({ startTime: date })
                                  }
                                  showTimeSelect
                                  showTimeSelectOnly
                                  timeIntervals={15}
                                  timeCaption="Time"
                                  dateFormat="h:mm aa"
                                  className="form-control"
                                  name="startTime"
                                  validate={{
                                    required: {
                                      value: true,
                                      errorMessage: "Start time is required",
                                    },
                                  }}
                                />
                              </FormGroup>
                            </Col>
                            <Col md={6}>
                              <FormGroup>
                                <Label for="endTime">End Time</Label>
                                <DatePicker
                                  selected={endTime}
                                  onChange={(date) =>
                                    this.setState({ endTime: date })
                                  }
                                  showTimeSelect
                                  showTimeSelectOnly
                                  timeIntervals={15}
                                  timeCaption="Time"
                                  dateFormat="h:mm aa"
                                  className="form-control"
                                  name="endTime"
                                  validate={{
                                    required: {
                                      value: true,
                                      errorMessage: "End time is required",
                                    },
                                    dateRange: {
                                      min: {
                                        value: () => this.state.startTime,
                                        errorMessage:
                                          "End time must be after start time",
                                      },
                                    },
                                  }}
                                />
                              </FormGroup>
                            </Col>
                          </Row>
                          <Row form>
                            <Col md={12}>
                              <FormGroup>
                                <Label for="daysOfWeek">Days of Week</Label>
                                <AvField
                                  type="select"
                                  name="daysOfWeek"
                                  id="daysOfWeek"
                                  multiple
                                  value={daysOfWeek}
                                  onChange={this.handleDaysChange.bind(this)}
                                  required
                                >
                                  {dayOptions.map((day) => (
                                    <option key={day.value} value={day.value}>
                                      {day.label}
                                    </option>
                                  ))}
                                </AvField>
                              </FormGroup>
                            </Col>
                          </Row>
                          <Row form>
                            <Col md={6}>
                              <FormGroup>
                                <Label for="isActive">Status</Label>
                                <CustomInput
                                  type="checkbox"
                                  id="isActive"
                                  checked={isActive}
                                  onChange={this.handleChange}
                                  label="Active"
                                />
                              </FormGroup>
                            </Col>
                          </Row>
                          <div className="text-center mt-4">
                            <Button color="primary" size="sm">
                              Submit
                            </Button>
                          </div>
                        </AvForm>
                      </ModalBody>
                    </Modal>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <Card>
                  <CardBody>
                    <h4 className="card-title">Food Settings</h4>
                    <MDBDataTable responsive bordered data={tableData} />
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
        <ToastContainer />
      </React.Fragment>
    );
  }
}

export default FoodSettings;
