import React from "react";

export const setUser = (authToken, authKey, data) => {
  localStorage.setItem("token", authToken);
  localStorage.setItem("authkey", authKey);
  localStorage.setItem("user", data);
};

export const getToken = () => {
  const authT = localStorage.getItem("token");
  const authk = localStorage.getItem("authkey");
  const authD = localStorage.getItem("user");
  return { authT, authk, authD };
};

export const removeUserData = () => {
  localStorage.removeItem("token");
  localStorage.removeItem("authkey");
  localStorage.clear();
};
