import React, { Component } from "react";
import { MDBDataTable,MDBTableHead,MDBTableBody,MDBTable,MDBBtn } from "mdbreact";
import {AvInput , Card, CardBody, Col,Form, Row, FormGroup, Label, Input, CustomInput,Button,Modal,Container, ModalHeader, ModalBody, ModalFooter  } from "reactstrap";
import { createFoodAddOn,getFoodAddOnList,getFoodAddOnById,updateFoodAddOn,removeFoodAddOn } from "../../Endpoint/Admin/index";
//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';
import { ToastContainer, toast } from 'react-toastify';
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';

import { AvForm, AvField } from 'availity-reactstrap-validation';
import { first } from "lodash";





class FormElements extends Component {
constructor(props) {
super(props);
this.state = {
  
items: [],
  isLoaded: false,
updatemodal:false,
modal: false,
foodAddOnName:'',
amount:'',
foodAddOnId:'',

success: {

},
breadcrumbItems : [
{ title : "", link : "#" },
{ title : "", link : "#" },
],
customchk: true,
toggleSwitch: true,
tableData: {
columns: [
  {
    label: ' FoodAddOn Name',
    field: 'foodAddOnName',
    sort: 'asc',
    width: 60
    },
    {
      label: ' Amount ',
      field: 'amount',
      sort: 'asc',
      width: 60
      },

                        

{
label: 'Update',
field: 'update',
sort: 'asc',
width: 100
},


{
label: 'Remove',
field: 'remove',
sort: 'asc',
width: 100
}
],
rows: [



]
}

};
this.reader = new FileReader();
this.handleChange = this.handleChange.bind(this);

this.handleSubmit = this.handleSubmit.bind(this);
this.toggle = this.toggle.bind(this);

  this.updatetoggle = this.updatetoggle.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
  
}
toggle() {
this.setState({
  modal: !this.state.modal,
  foodAddOnName:'',
amount:'',
foodAddOnId:'',
});
  }
  updatetoggle() {
    this.setState({
      updatemodal: !this.state.updatemodal
    });
    }

    handleCloseModal () {
      this.setState({
        updatemodal: false,
        modal:false,
      
      });
  	}
  


handleChange=(e)=>{
this.setState({
[e.target.id]:e.target.value
},()=>{

})

}

handleSubmit(e) {
e.preventDefault();
const userObject = {
  
foodAddOnName: this.state.foodAddOnName,
  amount: this.state.amount,
  foodAddOnId:this.state.foodAddOnId,
};
if (this.state.foodAddOnId=='' || this.state.foodAddOnId==null) {


createFoodAddOn(userObject).then((response) => {
if (response.status !== 200) {


toast.error(response.message, {
position: "top-right",
autoClose: 5000,
hideProgressBar: false,
closeOnClick: true,
pauseOnHover: true,
draggable: true,
progress: undefined,
});

} else {
this.setState({
handleResponse: {
isSuccess: response.status === 200,
message: "success",
foodAddOnName:'',
amount:'',
foodAddOnId:''
},

});
this.getALlluser()
this.handleCloseModal();

toast.success("FoodAddOn added successfully");
}
}).catch((error) => {

toast.error("error");
})
}else{
  updateFoodAddOn(userObject).then((updateResults)=>{
    if (updateResults.status !== 200) {
     
      toast.error(updateResults.message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      });
      
      } else {
      this.setState({
      handleResponse: {
      isSuccess: updateResults.status === 200,
      message: "success",
      foodAddOnId:'',
      
      },
      
      });
      this.getALlluser()
      
      this.handleCloseModal();
      
      toast.success("FoodAddOn  updated successfully");
      }
  }).catch((error) => {
  
  toast.error("error");
  })
}

}
getALlluser() {
  getFoodAddOnList().then((result) => {
 
  this.setState({
  isLoaded: true,
  items: ( result.data),
  tableData: {
  ...this.state.tableData, rows:result.data,
    list:  result.data.map(x => {
   
     x.update = <button className="btn btn-primary" onClick={() => this.editFoodAddOn(x._id.toString(), x)}>Update</button>
     x.remove = <button className="btn btn-danger" onClick={() => this.removeFoodAdd(x._id.toString())}>Remove</button>
    
     return x
  })
  },
  
  });
  });
  
}
removeFoodAdd(id) {
    
  removeFoodAddOn(id).then((results) => {
 
  this.getALlluser();
}).catch((error) => {
 
  toast.error("error");
  })
}
componentDidMount() {

    this.getALlluser();
    
  }

  editFoodAddOn(id){
 
    getFoodAddOnById(id).then((results)=>{
      
      this.setState({
        foodAddOnName:results.data.foodAddOnName,
  amount:results.data.amount,
  foodAddOnId:results.data._id,
      })
      
    }).catch((error)=>{
     
        toast.error("error");
    })
    this.toggle()
    }





render() {
const {  handleResponse, invalidImage, items,previewImage, } = this.state;





return (


<React.Fragment>
<div className="page-content">
<Container fluid>

<Breadcrumbs title="" breadcrumbItems={this.state.breadcrumbItems} />

<Row>
<Col xs={12}>
<Card>
<CardBody>
<Button color='primary' onClick={this.toggle}>Add Food AddOn</Button>
<Modal isOpen={this.state.modal} toggle={this.toggle} className={this.props.className}>
<ModalHeader toggle={this.toggle}>Food AddOn Details</ModalHeader>
<ModalBody>
<AvForm onValidSubmit={this.handleSubmit}>

<Row form>
          <Col md={6}>
            <FormGroup>
              <Label for="name">FoodAddOn Name</Label>
              <AvField    type="text" value={this.state.foodAddOnName} onChange={this.handleChange}   id="foodAddOnName" name="foodAddOnName"  required />

            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <Label for="ID">Amount</Label>
              <AvField   type="number" min={1} value={this.state.amount} onChange={this.handleChange}  id="amount"  name="amount"  />

            </FormGroup>
          </Col>
        </Row>

<div className="text-center"> 

<Button color="primary" size="sm">Submit</Button>{' '}

</div>  
</AvForm> 
</ModalBody>
</Modal>
             
</CardBody>
</Card>
</Col>
</Row>

<Breadcrumbs title="Data Tables" breadcrumbItems={this.state.breadcrumbItems} />

<Row>
<Col xs={12}>
<Card>
<CardBody>
<h4 className="card-title">Food AddOn Datatable </h4>
<p className="card-title-desc">


</p>

<MDBDataTable  responsive bordered data={this.state.tableData} />


</CardBody>
</Card>
</Col>
</Row>




</Container>
</div>
<ToastContainer />
</React.Fragment>
);
}
}

export default FormElements;
