import React, { Component } from "react";
import Moment from 'moment';
import { MDBDataTable,MDBTableHead,MDBTableBody,MDBTable,MDBBtn } from "mdbreact";
import {AvInput , FormText,Card, CardBody, Col,Form, Row, FormGroup, Label, Input, CustomInput,Button,Modal,Container, ModalHeader, ModalBody, ModalFooter  } from "reactstrap";
import { createRoomFeature,getRoomFeatureList,editRoomFeatures,updateRoomFeature,removeRoomFeature } from "../../Endpoint/Admin/index";
//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';
import { ToastContainer, toast } from 'react-toastify';
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import { AvForm, AvField } from 'availity-reactstrap-validation';

  
class FormElements extends Component {
constructor(props) {
super(props);
this.state = {
  invalidFile: false,
  previewImage: undefined,
items: [],
  isLoaded: false,
updatemodal:false,
modal: false,
featureName:'',
profile_user: '',
selectedFile: null, // to store selected file
handleResponse: null, // handle the API response
imageUrl: null, // to store uploaded image path
invalidImage: null, 
selectedFileName:'',
roomFeatureId:null,
selectedOption:null,
success: {

},
breadcrumbItems : [
{ title : "", link : "#" },
{ title : " ", link : "#" },
],
customchk: true,
toggleSwitch: true,
tableData: {
columns: [
  {
    label: 'Feature Name',
    field: 'featureName',
    sort: 'asc',
    width: 150
    },
    {
      label: 'RoomFeatureIcon',
      field: 'icon',
      sort: 'asc',
      width: 150
      },
  
{
label: 'Update',
field: 'update',
sort: 'asc',
width: 100
},
{
label: 'Remove',
field: 'remove',
sort: 'asc',
width: 100
}
],
rows: [



]
}

};
this.reader = new FileReader();
this.handleChange = this.handleChange.bind(this);
this.onFileChange = this.onFileChange.bind(this);
this.handleSubmit = this.handleSubmit.bind(this);
this.toggle = this.toggle.bind(this);
 this.editRoomFeature = this.editRoomFeature.bind(this);
   this.removeRoom = this.removeRoom.bind(this);
   this.getALlluser = this.getALlluser.bind(this);


}
toggle() {
this.setState({
  modal: !this.state.modal,
  featureName: '',
  selectedFile:'',
  previewImage:'',
  invalidImage: null, 
  roomFeatureId:''
});
  }
  updatetoggle() {
    this.setState({
      updatemodal: !this.state.updatemodal
    });
    }

    handleCloseModal () {
      this.setState({
        updatemodal: false,
        modal:false,
      
      });
  	}
  



onFileChange(event) {

 

  this.setState({
    selectedFile: event.target.files[0],
    previewImage: URL.createObjectURL(event.target.files[0]),
    
  });
}




handleChange=(e)=>{
this.setState({
[e.target.id]:e.target.value
},()=>{

})
}


//  // handle change event of input file and validate it

onChangeFile = event => {
  const imageFile = event.target.files[0];
 
  if (!imageFile.name.match(/\.(jpg|jpeg|png)$/)) {
    this.setState({ invalidImage: "Please select valid image.",
    previewImage: URL.createObjectURL(event.target.files[0]),
  });
    return false;
  }else{
    this.setState({
      selectedFile: event.target.files[0],
      previewImage: URL.createObjectURL(event.target.files[0]),
      invalidImage:null
    });
  }
   
 
};
//




handleSubmit(e) {
e.preventDefault();






const formData = new FormData();
formData.append("featureName", this.state.featureName);

formData.append("roomFeatureIcon", this.state.selectedFile);
formData.append("roomFeatureId",this.state.roomFeatureId);


if (this.state.roomFeatureId=='' || this.state.roomFeatureId==null) {


createRoomFeature(formData).then((response) => {
  
if (response.status !== 200) {


toast.error(response.message, {
position: "top-right",
autoClose: 5000,
hideProgressBar: false,
closeOnClick: true,
pauseOnHover: true,
draggable: true,
progress: undefined,
});

} else {
this.setState({
handleResponse: {
isSuccess: response.status === 200,
message: "success",
featureName:'',
selectedFile: null, // to store selected file
handleResponse: null, // handle the API response
imageUrl: null, // to store uploaded image path
invalidImage: null, 
selectedFileName:'',
previewImage:'',
roomFeatureId:'',
},

});
this.getALlluser()

this.handleCloseModal();

toast.success("Room feature added successfully");
}
}).catch((error) => {

toast.error("error");
})
}else{


updateRoomFeature(formData).then((updateResults)=>{
  if (updateResults.status !== 200) {
   
    toast.error(updateResults.message, {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    });
    
    } else {
    this.setState({
    handleResponse: {
    isSuccess: updateResults.status === 200,
    message: "success",
    roomFeatureId:'',
    
    },
    
    });
    this.getALlluser()
    
    this.handleCloseModal();
    
    toast.success("Room feature updated successfully");
    }
}).catch((error) => {

toast.error("error");
})
}
}
editRoomFeature(id){

editRoomFeatures(id).then((results)=>{
  
  this.setState({
    featureName:results.data.featureName,
    previewImage:results.data.icon,
    selectedFile:results.data.iconFile,
    roomFeatureId:results.data._id
  })
 
}).catch((error)=>{
 
    toast.error("error");
})
this.toggle()
}


removeRoom(id) {
    
  removeRoomFeature(id).then((results) => {
    toast.success("Room feature removed successfully");
    this.getALlluser();
  }).catch((error) => {
  
    toast.error("error");
    })
  }
  getALlluser() {
    getRoomFeatureList().then((result) => {
 
      this.setState({
      isLoaded: true,
      items: ( result.data),
      tableData: {
      ...this.state.tableData, rows:result.data,
        list:  result.data.map(x => {
       
         x.update = <button className="btn btn-primary" onClick={() => this.editRoomFeature(x._id.toString(), x)}>Update</button>
         x.remove = <button className="btn btn-danger" onClick={() => this.removeRoom(x._id.toString())}>Remove</button>
         x.icon = <img src={x.icon} width="50" height="50" />
        
         return x
      })
      },
      
      });
      });
      
  }

  componentDidMount() {
  
    this.getALlluser();
}

render() {
const {  handleResponse, invalidImage,imageUrl, items,previewImage} = this.state;

return (


<React.Fragment>
<div className="page-content">
<Container fluid>

<Breadcrumbs title="" breadcrumbItems={this.state.breadcrumbItems} />

<Row>
<Col xs={12}>
<Card>
<CardBody>
<Button color='primary' onClick={this.toggle}>Add Room Feature</Button>
<Modal isOpen={this.state.modal} toggle={this.toggle} className={this.props.className}>
<ModalHeader toggle={this.toggle}>Feature Details</ModalHeader>
<ModalBody>
<AvForm onValidSubmit={this.handleSubmit}>
<Row form>
          <Col md={12}>
            <FormGroup>
              <Label for="featureName">Feature Name</Label>
              <AvField    type="text" value={this.state.featureName} onChange={this.handleChange}   id="featureName" name="featureName"  required />

            </FormGroup>
          </Col>
          
        </Row>

<Row form>
<Col md={10}>
<Label htmlFor="example-number-input" className="col-md-12 col-form-label">Room Icon </Label>

<div className="custom-file">
  
<AvField
              tag={CustomInput}
              name="file"
              id="file"
              type="file"
              accept="image/*"
              onChange={this.onChangeFile}
              
               
            />
<Label className="custom-file-label" htmlFor="customFile">Choose file</Label>
</div>   

{previewImage && (
          <div>
            
            <img style={{
          borderRadius: "50%",
          width: 80,
          height: 80,
          // background: "red",
          display: "block"
        }} className="img-fluid" src={previewImage}  alt="" />
          </div>
        )}
</Col>
</Row>

<div className="text-center mt-4">
{invalidImage && <p className="error">{invalidImage}</p>}
<span> {this.state.selectedFileName}</span>    
{handleResponse && (
<p className={handleResponse.isSuccess ? "success" : "error"}>
{handleResponse.message}
</p>
)}               

<Button color="primary" size="sm">Submit</Button>{' '}
</div>

</AvForm> 
</ModalBody>
</Modal>
              
</CardBody>
</Card>
</Col>
</Row>
<h4 className="card-title"></h4>
<Breadcrumbs title="Data Tables" breadcrumbItems={this.state.breadcrumbItems} />

<Row>
<Col xs={12}>
<Card>
<CardBody>

<p className="card-title-desc">


</p>

<MDBDataTable  responsive bordered data={this.state.tableData} />


</CardBody>
</Card>
</Col>
</Row>

</Container>
</div>
<ToastContainer />
</React.Fragment>
);
}
}

export default FormElements;
