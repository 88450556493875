import React, { useState, useEffect } from "react";
import { Container, Row, Col, ButtonGroup, Button } from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import MiniWidgets from "./MiniWidgets";
import RevenueTab from "./RevenueTab";
import SocketComponent from "../../components/SocketComponent/SocketComponent";
import { getTotalData } from "../../Endpoint/Admin/index";
import { ToastContainer, toast } from "react-toastify";

const Dashboard = ({ user }) => {
  const [audio] = useState(
    typeof Audio !== "undefined" &&
      new Audio("https://sevenue.com/files/Chat-Alert.wav")
  );
  const [reports, setReports] = useState([
    { icon: "ri-stack-line", title: "Orders Received", value: "" },
    { icon: "ri-store-2-line", title: "Orders Delivered", value: "" },
    {
      icon: "ri-briefcase-4-line",
      title: "Rooms Booked",
      value: "",
    },
  ]);
  const [breadcrumbItems] = useState([
    { title: "Lubumbashi", link: "#" },
    { title: "Dashboard", link: "#" },
  ]);
  const [filter, setFilter] = useState("today");

  const handleNewNotification = (data) => {
    audio.play();
    toast.success(data, {
      position: "top-right",
      autoClose: false,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const updateReports = (data) => {
    const { orders, deliveries, roomsBooked } = data;

    setReports([
      { icon: "ri-stack-line", title: "Orders Received", value: orders },
      { icon: "ri-store-2-line", title: "Orders Delivered", value: deliveries },
      {
        icon: "ri-briefcase-4-line",
        title: "Rooms Booked",
        value: roomsBooked,
      },
    ]);
  };

  const fetchData = (filter) => {
    getTotalData(filter).then((result) => {
      if (result && result.data) {
        updateReports(result.data);
      } else {
        toast.error("Error fetching data");
      }
    });
  };

  useEffect(() => {
    fetchData(filter);
  }, [filter]);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Dashboard" breadcrumbItems={breadcrumbItems} />
          <ButtonGroup>
            <Button onClick={() => setFilter("today")}>Today's Data</Button>
            <Button onClick={() => setFilter("monthly")}>
              This Month's Data
            </Button>
          </ButtonGroup>
          <Row>
            <Col xl={12}>
              <Row>
                <MiniWidgets reports={reports} />
              </Row>
              {/* revenue Analytics */}
              <RevenueTab />
            </Col>
          </Row>
        </Container>
      </div>
      <ToastContainer />
      <SocketComponent onNewNotification={handleNewNotification} />
    </React.Fragment>
  );
};

export default Dashboard;
