// SocketComponent.js
import React, { useState, useEffect } from "react";
import io from "socket.io-client";
import { getToken } from "../../config/TokenHelper";

const SERVER = "https://socket.hotelslubumbashi.com:5001";

const SocketComponent = ({ onNewNotification }) => {
  const [socket, setSocket] = useState(null);

  useEffect(() => {
    const socket = io.connect(SERVER, { secure: true });
    setSocket(socket);
    const token = getToken();
    const userData = JSON.parse(token.authD);
    console.log("token bhau:", userData._id);
    console.log(getToken());
    socket.emit("connection", {
      userId: userData._id,
    });

    socket.on("connected", () => {
      setSocket(socket);
      console.log("Socket Connected");
    });
    socket.on("new-notification-received", (data) => {
      console.log("77777777777777d7sd7d7d7");
      // Handle new notification
      onNewNotification(data);
    });

    return () => {
      if (socket) {
        socket.disconnect();
      }
    };
  }, [onNewNotification]);

  return null; // No need to render anything for the socket component
};

export default SocketComponent;
