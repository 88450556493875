import React, { Component } from "react";
import { MDBDataTable } from "mdbreact";
import { Card, CardBody, Col, Row, Button, Container } from "reactstrap";
import {
  listroomBookingCancellationRequest,
  approveCancellationRequest,
  disapproveCancellationRequest,
} from "../../Endpoint/Admin/index";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { ToastContainer, toast } from "react-toastify";

class RoomCancel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      items: [],
      isLoaded: false,
      breadcrumbItems: [
        { title: "Dashboard", link: "#" },
        { title: "Room Cancellation Requests", link: "#" },
      ],
      tableData: {
        columns: [
          { label: "Room Name", field: "roomName", sort: "asc", width: 60 },
          { label: "Order Date", field: "createdAt", sort: "asc", width: 60 },
          {
            label: "Customer Name",
            field: "guestName",
            sort: "asc",
            width: 60,
          },
          {
            label: "Customer Email",
            field: "guestEmail",
            sort: "asc",
            width: 60,
          },
          {
            label: "Customer Mobile",
            field: "guestMobile",
            sort: "asc",
            width: 60,
          },
          { label: "Start Date", field: "startDate", sort: "asc", width: 60 },
          { label: "End Date", field: "endDate", sort: "asc", width: 60 },
          {
            label: "Cancellation Reason",
            field: "cancellationReason",
            sort: "asc",
            width: 60,
          },
          { label: "Status", field: "status", sort: "asc", width: 100 },
          { label: "Approve", field: "approve", sort: "asc", width: 100 },
          { label: "Disapprove", field: "disapprove", sort: "asc", width: 100 },
        ],
        rows: [],
      },
    };
    this.approveRequest = this.approveRequest.bind(this);
    this.disapproveRequest = this.disapproveRequest.bind(this);
  }

  componentDidMount() {
    this.getAllRequests();
  }

  approveRequest(roomBookingId) {
    approveCancellationRequest(roomBookingId)
      .then((response) => {
        if (response.status !== 200) {
          toast.error(response.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        } else {
          toast.success(response.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
        this.getAllRequests();
      })
      .catch((error) => {
        toast.error("Error approving request");
      });
  }

  disapproveRequest(roomBookingId) {
    disapproveCancellationRequest(roomBookingId)
      .then((response) => {
        if (response.status !== 200) {
          toast.error(response.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        } else {
          toast.success(response.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
        this.getAllRequests();
      })
      .catch((error) => {
        toast.error("Error disapproving request");
      });
  }

  getAllRequests() {
    listroomBookingCancellationRequest().then((result) => {
      if (result.status === 200) {
        const notifications = result.data.map((x) => {
          const status = x.cancelled ? "Approved" : "Not Approved";
          let approveButton = null;
          let disapproveButton = null;

          if (status === "Approved") {
            disapproveButton = (
              <button
                className="btn btn-danger"
                onClick={() => this.disapproveRequest(x._id.toString())}
              >
                Not Approve
              </button>
            );
          } else {
            approveButton = (
              <button
                className="btn btn-success"
                onClick={() => this.approveRequest(x._id.toString())}
              >
                Approve
              </button>
            );
          }

          return {
            ...x,
            status,
            approve: approveButton,
            disapprove: disapproveButton,
          };
        });

        this.setState({
          isLoaded: true,
          items: result.data,
          tableData: {
            ...this.state.tableData,
            rows: notifications,
          },
        });
      } else {
        toast.error("Error fetching requests");
      }
    });
  }

  render() {
    const { tableData } = this.state;

    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Breadcrumbs
              title="Room Cancellation Requests"
              breadcrumbItems={this.state.breadcrumbItems}
            />
            <Row>
              <Col xs={12}>
                <Card>
                  <CardBody>
                    <h4 className="card-title">Room Cancellation DataTable</h4>
                    <p className="card-title-desc"></p>
                    <MDBDataTable responsive bordered data={tableData} />
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
        <ToastContainer />
      </React.Fragment>
    );
  }
}

export default RoomCancel;
