import React, { Component } from "react";
import { MDBDataTable } from "mdbreact";
import {
  Card,
  CardBody,
  Col,
  Row,
  Button,
  Modal,
  Container,
  ModalHeader,
  ModalBody,
  FormGroup,
  Label,
  CustomInput,
} from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { ToastContainer, toast } from "react-toastify";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import {
  createSMSNotification,
  updateSMSNotification,
  getSMSNotifications,
  deleteSMSNotification,
} from "../../Endpoint/Admin/index";

class SMSNotificationList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      items: [],
      modal: false,
      notificationType: "",
      phoneNumber: "",
      isActive: true,
      notificationId: "",
      breadcrumbItems: [
        { title: "Dashboard", link: "#" },
        { title: "SMS Notifications", link: "#" },
      ],
      tableData: {
        columns: [
          {
            label: "Notification Type",
            field: "notificationType",
            sort: "asc",
            width: 150,
          },
          {
            label: "Phone Number",
            field: "phoneNumber",
            sort: "asc",
            width: 150,
          },
          { label: "Status", field: "isActive", sort: "asc", width: 100 },
          { label: "Update", field: "update", sort: "asc", width: 100 },
          { label: "Remove", field: "remove", sort: "asc", width: 100 },
        ],
        rows: [],
      },
    };
    this.toggle = this.toggle.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
    this.editNotification = this.editNotification.bind(this);
  }

  componentDidMount() {
    this.getAllNotifications();
  }

  toggle() {
    this.setState((prevState) => ({
      modal: !prevState.modal,
      notificationType: "",
      phoneNumber: "",
      isActive: true,
      notificationId: "",
    }));
  }

  handleChange(e) {
    const { id, value, checked } = e.target;
    const newValue = id === "isActive" ? checked : value;
    this.setState({
      [id]: newValue,
    });
  }

  handleCloseModal() {
    this.setState({
      modal: false,
    });
  }

  handleSubmit(e) {
    e.preventDefault();
    const { notificationType, phoneNumber, isActive, notificationId } =
      this.state;
    const data = { notificationType, phoneNumber, isActive };

    if (!notificationId) {
      createSMSNotification(data)
        .then((response) => {
          if (response && response.success) {
            this.getAllNotifications();
            this.handleCloseModal();
            toast.success(response.message);
          } else {
            toast.error(
              response ? response.message : "Error creating notification"
            );
          }
        })
        .catch((error) => {
          toast.error("Error creating notification");
        });
    } else {
      updateSMSNotification(notificationId, data)
        .then((response) => {
          if (response && response.success) {
            this.getAllNotifications();
            this.handleCloseModal();
            toast.success(response.message);
          } else {
            toast.error(
              response ? response.message : "Error updating notification"
            );
          }
        })
        .catch((error) => {
          toast.error("Error updating notification");
        });
    }
  }

  getAllNotifications() {
    getSMSNotifications()
      .then((result) => {
        if (result && result.success) {
          const notifications = result.data.map((notif) => ({
            ...notif,
            isActive: notif.isActive ? "Active" : "Inactive",
            update: (
              <button
                className="btn btn-primary"
                onClick={() => this.editNotification(notif._id, notif)}
              >
                Update
              </button>
            ),
            remove: (
              <button
                className="btn btn-danger"
                onClick={() => this.removeNotification(notif._id)}
              >
                Remove
              </button>
            ),
          }));
          this.setState({
            items: notifications,
            tableData: {
              ...this.state.tableData,
              rows: notifications,
            },
          });
        } else {
          toast.error("Error fetching notifications");
        }
      })
      .catch((error) => {
        toast.error("Error fetching notifications");
      });
  }

  editNotification(id, notif) {
    this.setState({
      notificationType: notif.notificationType,
      phoneNumber: notif.phoneNumber,
      isActive: notif.isActive === true,
      notificationId: notif._id,
      modal: true, // Open the modal
    });
  }

  removeNotification(id) {
    deleteSMSNotification(id)
      .then((response) => {
        if (response && response.success) {
          this.getAllNotifications();
          toast.success(response.message);
        } else {
          toast.error(
            response ? response.message : "Error deleting notification"
          );
        }
      })
      .catch((error) => {
        toast.error("Error deleting notification");
      });
  }

  render() {
    const { modal, notificationType, phoneNumber, isActive, tableData } =
      this.state;

    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Breadcrumbs
              title="SMS Notifications"
              breadcrumbItems={this.state.breadcrumbItems}
            />

            <Row>
              <Col xs={12}>
                <Card>
                  <CardBody>
                    <Button color="primary" onClick={this.toggle}>
                      Add SMS Notification
                    </Button>
                    <Modal isOpen={modal} toggle={this.toggle}>
                      <ModalHeader toggle={this.toggle}>
                        SMS Notification Details
                      </ModalHeader>
                      <ModalBody>
                        <AvForm onValidSubmit={this.handleSubmit}>
                          <Row form>
                            <Col md={6}>
                              <FormGroup>
                                <Label for="notificationType">
                                  Notification Type
                                </Label>
                                <AvField
                                  type="select"
                                  value={notificationType}
                                  onChange={this.handleChange}
                                  id="notificationType"
                                  name="notificationType"
                                  required
                                >
                                  <option value="">Select Type</option>
                                  <option value="foodOrder">Food Order</option>
                                  <option value="roomBooking">
                                    Room Booking
                                  </option>
                                  <option value="tableOrder">
                                    Table Order
                                  </option>
                                </AvField>
                              </FormGroup>
                            </Col>
                            <Col md={6}>
                              <FormGroup>
                                <Label for="phoneNumber">Phone Number</Label>
                                <AvField
                                  type="text"
                                  value={phoneNumber}
                                  onChange={this.handleChange}
                                  id="phoneNumber"
                                  name="phoneNumber"
                                  required
                                />
                              </FormGroup>
                            </Col>
                          </Row>
                          <Row form>
                            <Col md={6}>
                              <FormGroup>
                                <Label for="isActive">Status</Label>
                                <CustomInput
                                  type="checkbox"
                                  id="isActive"
                                  checked={isActive}
                                  onChange={this.handleChange}
                                  label="Active"
                                />
                              </FormGroup>
                            </Col>
                          </Row>
                          <div className="text-center mt-4">
                            <Button color="primary" size="sm">
                              Submit
                            </Button>
                          </div>
                        </AvForm>
                      </ModalBody>
                    </Modal>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <Card>
                  <CardBody>
                    <h4 className="card-title">SMS Notifications</h4>
                    <MDBDataTable responsive bordered data={tableData} />
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
        <ToastContainer />
      </React.Fragment>
    );
  }
}

export default SMSNotificationList;
