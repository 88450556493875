import React, { Component } from "react";

// MetisMenu
import MetisMenu from "metismenujs";
import { withRouter } from "react-router-dom";
import { Link } from "react-router-dom";

//i18n
import { withTranslation } from "react-i18next";

import { connect } from "react-redux";
import {
  changeLayout,
  changeLayoutWidth,
  changeSidebarTheme,
  changeSidebarType,
  changePreloader,
} from "../../store/actions";

class SidebarContent extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    this.initMenu();
  }

  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      if (this.props.type !== prevProps.type) {
        this.initMenu();
      }
    }
  }

  initMenu() {
    new MetisMenu("#side-menu");

    var matchingMenuItem = null;
    var ul = document.getElementById("side-menu");
    var items = ul.getElementsByTagName("a");
    for (var i = 0; i < items.length; ++i) {
      if (this.props.location.pathname === items[i].pathname) {
        matchingMenuItem = items[i];
        break;
      }
    }
    if (matchingMenuItem) {
      this.activateParentDropdown(matchingMenuItem);
    }
  }

  activateParentDropdown = (item) => {
    item.classList.add("active");
    const parent = item.parentElement;

    if (parent) {
      parent.classList.add("mm-active");
      const parent2 = parent.parentElement;

      if (parent2) {
        parent2.classList.add("mm-show");

        const parent3 = parent2.parentElement;

        if (parent3) {
          parent3.classList.add("mm-active"); // li
          parent3.childNodes[0].classList.add("mm-active"); //a
          const parent4 = parent3.parentElement;
          if (parent4) {
            parent4.classList.add("mm-active");
          }
        }
      }
      return false;
    }
    return false;
  };

  render() {
    return (
      <React.Fragment>
        <div id="sidebar-menu">
          <ul className="metismenu list-unstyled" id="side-menu">
            <li className="menu-title">{this.props.t("Menu")}</li>

            <li>
              <Link to="/dashboard" className="waves-effect">
                <i className="ri-dashboard-line"></i>
                <span className="badge badge-pill badge-success float-right"></span>
                <span className="ml-1">{this.props.t("Dashboard")}</span>
              </Link>
            </li>

            <li>
              <Link to="/#" className="has-arrow waves-effect">
                <i className="fas fa-person-booth"></i>
                <span className="ml-1">{this.props.t("Rooms")}</span>
              </Link>
              <ul className="sub-menu" aria-expanded="false">
                <li>
                  <Link to="RoomFeature">{this.props.t("RoomFeature")}</Link>
                </li>
                <li>
                  <Link to="RoomHospitalityService">
                    {this.props.t("RoomHospitalityService")}
                  </Link>
                </li>
                <li>
                  <Link to="AmenityType">{this.props.t("AmenityType")}</Link>
                </li>
                <li>
                  <Link to="Amenities">{this.props.t("Amenities")}</Link>
                </li>
                <li>
                  <Link to="RoomType">{this.props.t("RoomType")}</Link>
                </li>
                <li>
                  <Link to="Room">{this.props.t("Room")}</Link>
                </li>
                <li>
                  <Link to="RoomCancel">{this.props.t("RoomCancel")}</Link>
                </li>
                <li>
                  <Link to="Booking">{this.props.t("Booking")}</Link>
                </li>
              </ul>
            </li>

            <li>
              <Link to="/#" className="has-arrow waves-effect">
                <i className="fas fa-utensils"></i>
                <span className="ml-1">{this.props.t("Food")}</span>
              </Link>
              <ul className="sub-menu" aria-expanded="false">
                <li>
                  <Link to="Foodcuisine">{this.props.t("Foodcuisine")}</Link>
                </li>
                <li>
                  <Link to="FoodAddOn">{this.props.t("FoodAddOn")}</Link>
                </li>
                <li>
                  <Link to="Food">{this.props.t("Food")}</Link>
                </li>

                <li>
                  <Link to="Table">{this.props.t("Table")}</Link>
                </li>
                <li>
                  <Link to="TableBooking">{this.props.t("TableBooking")}</Link>
                </li>
                <li>
                  <Link to="FoodOrder">{this.props.t("FoodOrder")}</Link>
                </li>
                <li>
                  <Link to="FoodSettings">{this.props.t("FoodSettings")}</Link>
                </li>
              </ul>
            </li>

            <li>
              <Link to="Attraction" className=" waves-effect">
                <i className="ri-artboard-2-line"></i>
                <span className="ml-1">{this.props.t("Attraction")}</span>
              </Link>
            </li>

            <li>
              <Link to="Taxi" className=" waves-effect">
                <i className="fa fa-taxi"></i>
                <span className="ml-1">{this.props.t("Taxi")}</span>
              </Link>
            </li>

            <li>
              <Link to="ManageNotification" className=" waves-effect">
                <i className="fa fa-wrench"></i>
                <span className="ml-1">
                  {this.props.t("ManageNotification")}
                </span>
              </Link>
            </li>

            <li>
              <Link to="/#" className="has-arrow waves-effect">
                <i className="fas fa-user"></i>
                <span className="ml-1">{this.props.t("Users")}</span>
              </Link>
              <ul className="sub-menu" aria-expanded="false">
                <li>
                  <Link to="DeliveryBoy">{this.props.t("DeliveryBoy")}</Link>
                </li>
                <li>
                  <Link to="User">{this.props.t("User")}</Link>
                </li>
              </ul>
            </li>

            <li>
              <Link to="Notification" className=" waves-effect">
                <i className="fa fa-bell"></i>
                <span className="ml-1">{this.props.t("Notification")}</span>
              </Link>
            </li>

            <li>
              <Link to="ChatList" className=" waves-effect">
                <i className="fa fa-comments"></i>
                <span className="ml-1">{this.props.t("ChatList")}</span>
              </Link>
            </li>
          </ul>
        </div>
      </React.Fragment>
    );
  }
}

const mapStatetoProps = (state) => {
  return { ...state.Layout };
};

export default withRouter(
  connect(mapStatetoProps, {
    changeLayout,
    changeSidebarTheme,
    changeSidebarType,
    changeLayoutWidth,
    changePreloader,
  })(withTranslation()(SidebarContent))
);
