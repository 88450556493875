import React, { Component } from "react";
import {
  MDBDataTable,
  MDBTableHead,
  MDBTableBody,
  MDBTable,
  MDBBtn,
} from "mdbreact";
import Select from "react-select";
import {
  AvInput,
  Card,
  CardBody,
  Col,
  Form,
  Row,
  FormGroup,
  Label,
  Input,
  CustomInput,
  Button,
  Modal,
  Container,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import {
  listofDeliveryBoy,
  foodOrderList,
  AssignDeliveryBoy,
  ReAssignDeliveryBoy,
  OrderListbyId,
  OrderFullfilled,
  OrderList,
  updateFoodPaymentstatus,
  updatepaymentstatus,
} from "../../Endpoint/Admin/index";
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

import { ToastContainer, toast } from "react-toastify";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider from "react-bootstrap-table2-toolkit";

import { AvForm, AvField } from "availity-reactstrap-validation";

class FoodOrder extends Component {
  constructor(props) {
    super(props);
    this.state = {
      invalidFile: false,

      items: [],
      DBoyData: [],
      FoodOrderData: [],
      isLoaded: false,
      updatemodal: false,
      modal: false,
      orderPlacetype: 4,
      Dboy: "",
      foodorderId: "",
      isRejected: "",
      success: {},
      breadcrumbItems: [
        { title: "", link: "#" },
        { title: " ", link: "#" },
      ],
      customchk: true,
      toggleSwitch: true,
      tableData: {
        columns: [
          {
            label: "Order Date",
            field: "createdAt",
            sort: "asc",
            width: 60,
          },
          {
            label: "Food Item (qty)",
            field: "food",
            sort: "asc",
            width: 60,
          },
          // instructions
          {
            label: "Food Instructions",
            field: "instructions",
            sort: "asc",
            width: 60,
          },
          {
            label: "Amount",
            field: "total",
            sort: "asc",
            width: 60,
          },
          {
            label: "Order In",
            field: "selectPlace",
            sort: "asc",
            width: 60,
          },
          {
            label: "DeliveryAddress",
            field: "deliveryAddress",
            sort: "asc",
            width: 60,
          },
          {
            label: "Customer Name",
            field: "username",
            sort: "asc",
            width: 60,
          },
          {
            label: "Customer Email",
            field: "email",
            sort: "asc",
            width: 60,
          },
          {
            label: "Customer Mobile",
            field: "mobile",
            sort: "asc",
            width: 60,
          },
          {
            label: "Payment (Online/Cash)",
            field: "paymentMethodType",
            sort: "asc",
            width: 60,
          },

          {
            label: "Orderstatus",
            field: "orderstatus",
            sort: "asc",
            width: 150,
          },
          {
            label: "Payment Status ",
            field: "remove",
            sort: "asc",
            width: 100,
          },
          {
            label: "Cancellation Reason",
            field: "cancellationReason",
            sort: "asc",
            width: 150,
          },
          // {
          //   label: 'Payment Status ',
          //   field: 'changeFoodstatus',
          //   sort: 'asc',
          //   width: 100
          //   }
        ],
        rows: [],
      },
    };

    this.handleChange = this.handleChange.bind(this);

    this.handleSubmit = this.handleSubmit.bind(this);
    this.toggle = this.toggle.bind(this);

    this.handleCloseModal = this.handleCloseModal.bind(this);
    this.OrderManagement = this.OrderManagement.bind(this);
  }
  toggle() {
    this.setState({
      modal: !this.state.modal,
      foodorderId: "",
      Dboy: "",
      isRejected: "",
    });
  }

  handleCloseModal() {
    this.setState({
      updatemodal: false,
      modal: false,
    });
  }

  handleChange = (e) => {
    this.setState(
      {
        [e.target.id]: e.target.value,
      },
      () => {}
    );
  };

  listDeliveryBoy() {
    listofDeliveryBoy()
      .then((result) => {
        this.setState({
          DBoyData: result.data,
        });
      })
      .catch((error) => {});
  }
  FoodorderList() {
    const data = {
      orderPlacetype: this.state.orderPlacetype,
    };
    foodOrderList(data)
      .then((result) => {
        this.setState({
          FoodOrderData: result.data,
        });
      })
      .catch((error) => {});
  }

  getALllorder() {
    OrderList().then((result) => {
      console.log(result.data);
      this.setState({
        isLoaded: true,
        items: result.data.isPaymentSuccess,
        tableData: {
          ...this.state.tableData,
          rows: result.data.map((x) => {
            console.log(x.user);
            x.user = x.user;
            const isCancelled = x.item && x.item.isCancelled;
            const cancellationReason = x.item && x.item.cancellationReason;

            x.paymentMethodType = x.paymentMethodType === 1 ? "Cash" : "Online";
            x.isPaymentSuccess = x.isPaymentSuccess === true ? false : true;

            if (isCancelled) {
              // If the order is cancelled, set both order status and payment status to "Order Cancelled"
              x.orderstatus = "Order Cancelled";
              x.remove = "Order Cancelled";
              x.cancellationReason = cancellationReason || "";
            } else {
              // If the order is not cancelled, use the existing logic
              x.remove = (
                <button
                  className="btn btn-danger"
                  onClick={() =>
                    this.StatusChangeRequest(
                      x._id.toString(),
                      x.isPaymentSuccess
                    )
                  }
                >
                  {x.isPaymentSuccess === true ? "Pending" : "Complete"}
                </button>
              );

              if (
                x.isRejected === false &&
                x.isDeliveryBoyAssigned === true &&
                x.orderPlacetype === 4
              ) {
                x.orderstatus = "Already Assigned";
              } else if (
                x.isDeliveryBoyAssigned === false &&
                x.isRejected === false &&
                x.orderPlacetype === 4
              ) {
                x.orderstatus = (
                  <button
                    className="btn btn-danger"
                    onClick={() =>
                      this.OrderManagement(x._id.toString(), x.isRejected)
                    }
                  >
                    Assign
                  </button>
                );
              } else if (
                x.isRejected === true &&
                x.isDeliveryBoyAssigned === true &&
                x.orderPlacetype === 4
              ) {
                x.orderstatus = "Already Reassigned";
              } else if (
                x.isRejected === true &&
                x.isDeliveryBoyAssigned === false &&
                x.orderPlacetype === 4
              ) {
                x.orderstatus = (
                  <button
                    className="btn btn-danger"
                    onClick={() =>
                      this.OrderManagement(x._id.toString(), x.isRejected)
                    }
                  >
                    Reassign
                  </button>
                );
              } else if (x.orderPlacetype !== 4 && x.isDelivered == false) {
                x.orderstatus = (
                  <button
                    className="btn btn-danger"
                    onClick={() =>
                      this.OrderFullFill(x._id.toString(), x.isRejected)
                    }
                  >
                    Close Order
                  </button>
                );
              } else if (x.isDelivered) {
                x.orderstatus = "Order Delivered";
              } else {
                x.orderstatus = "No Status";
              }

              x.cancellationReason = "";
            }

            return x;
          }),
        },
      });
    });
  }

  //
  OrderManagement(id, isRejected) {
    OrderListbyId(id)
      .then((results) => {
        this.setState({
          foodorderId: results.data._id,
          isRejected: results.data.isRejected,
        });
      })
      .catch((error) => {
        toast.error("error");
      });

    this.toggle();
  }

  //OrderFullfilled
  OrderFullFill(id, isRejected) {
    OrderFullfilled(id)
      .then((results) => {
        this.setState({
          foodorderId: results.data._id,
          isRejected: results.data.isRejected,
        });

        if (results.status !== 200) {
          toast.error(results.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        } else {
          toast.success(results.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      })
      .catch((error) => {
        toast.error("error");
      });

    // this.toggle();
  }

  StatusChangeRequest(id, isPaymentSuccess) {
    const data = {
      orderId: id,
      isPaymentSuccess: isPaymentSuccess,
    };
    console.log(data);

    updatepaymentstatus(data)
      .then((response) => {
        console.log(response);
        if (response.status !== 200) {
          toast.error(response.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        } else {
          toast.success(response.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
        this.getALllorder();
      })
      .catch((error) => {
        console.log(error);
        toast.error("error");
      });
  }

  handleSubmit(e) {
    e.preventDefault();

    const Data = {
      DboyuserId: this.state.Dboy,
      foodorderId: this.state.foodorderId,
    };

    if (this.state.isRejected == false) {
      //
      AssignDeliveryBoy(Data)
        .then((response) => {
          console.log(response);
          if (response.status !== 200) {
            toast.error(response.message, {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          } else {
            this.getALllorder();
            this.listDeliveryBoy();
            toast.success(response.message, {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            this.setState({
              handleResponse: {
                isSuccess: response.status === 200,
                message: "success",
                Dboy: "",
                foodorderId: "",
                isRejected: "",
              },
            });

            this.handleCloseModal();
          }
        })
        .catch((error) => {
          toast.error("error");
        });
    } else {
      ReAssignDeliveryBoy(Data)
        .then((response) => {
          if (response.status !== 200) {
            toast.error(response.message, {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          } else {
            this.getALllorder();
            this.listDeliveryBoy();
            toast.success(response.message, {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            this.setState({
              handleResponse: {
                isSuccess: response.status === 200,
                message: "success",
                Dboy: "",
                foodorderId: "",
                isRejected: "",
              },
            });

            this.handleCloseModal();
          }
        })
        .catch((error) => {
          toast.error("error");
        });
    }
  }

  componentDidMount() {
    this.getALllorder();
    this.listDeliveryBoy();
    this.FoodorderList();
  }

  render() {
    const { handleResponse, items, previewImage } = this.state;

    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Breadcrumbs
              title=""
              breadcrumbItems={this.state.breadcrumbItems}
            />

            <Row>
              <Col xs={12}>
                {/* <Card>
<CardBody> */}

                <Modal
                  isOpen={this.state.modal}
                  toggle={this.toggle}
                  className={this.props.className}
                >
                  <ModalHeader toggle={this.toggle}>
                    Assign Delivery Boy Details
                  </ModalHeader>
                  <ModalBody>
                    <AvForm onValidSubmit={this.handleSubmit}>
                      <Row form>
                        <Col md={12}>
                          <FormGroup>
                            <Label for="email">Delivery Boy</Label>
                            <Input
                              type="select"
                              name="Dboy"
                              id="Dboy"
                              value={this.state.Dboy}
                              onChange={this.handleChange}
                              required
                            >
                              <option>Select Delivery Boy</option>
                              {this.state.DBoyData.map((e, key) => {
                                return (
                                  <option key={key} value={e._id}>
                                    {e.firstName}
                                  </option>
                                );
                              })}
                            </Input>
                          </FormGroup>
                        </Col>
                        <Col md={6}>
                          <FormGroup>
                            {/* <Label for="foodOrder">food Order</Label>
              <Input name="foodorderId" id="foodorderId" value={this.state.foodorderId}  readOnly> */}
                            {/* <option>Select food Order</option>   */}
                            {/* {this.state.FoodOrderData.map((e, key) => {  
return <option key={key} value={e._id}>{e.user.firstName}</option>;  
})}   */}
                            {/* </Input> */}
                          </FormGroup>
                        </Col>
                      </Row>

                      <div className="text-center mt-4">
                        <Button color="primary" size="sm">
                          Submit
                        </Button>{" "}
                      </div>
                    </AvForm>
                  </ModalBody>
                </Modal>

                {/* </CardBody>
</Card> */}
              </Col>
            </Row>

            <Breadcrumbs
              title="Data Tables"
              breadcrumbItems={this.state.breadcrumbItems}
            />

            <Row>
              <Col xs={12}>
                <Card>
                  <CardBody>
                    <h4 className="card-title">Order DataTable </h4>
                    <p className="card-title-desc"></p>

                    <MDBDataTable
                      responsive
                      bordered
                      data={this.state.tableData}
                    />
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
        <ToastContainer />
      </React.Fragment>
    );
  }
}

export default FoodOrder;
