import React, { Component } from "react";
import {
  MDBDataTable,
  MDBTableHead,
  MDBTableBody,
  MDBTable,
  MDBBtn,
} from "mdbreact";
import {
  AvInput,
  Card,
  CardBody,
  Col,
  Form,
  Row,
  FormGroup,
  Label,
  Input,
  CustomInput,
  Button,
  Modal,
  Container,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import Select from "react-select";
import {
  listRoomBookingRequest,
  updatePaymentStatus,
} from "../../Endpoint/Admin/index";
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { ToastContainer, toast } from "react-toastify";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import { AvForm, AvField } from "availity-reactstrap-validation";

class Booking extends Component {
  constructor(props) {
    super(props);
    this.state = {
      invalidFile: false,

      items: [],

      isLoaded: false,
      updatemodal: false,
      modal: false,
      roomBookingId: "",
      success: {},
      breadcrumbItems: [
        { title: "", link: "#" },
        { title: " ", link: "#" },
      ],
      customchk: true,
      toggleSwitch: true,
      tableData: {
        columns: [
          {
            label: "Booking Date",
            field: "createdAt",
            sort: "asc",
            width: 60,
          },
          {
            label: "CheckIn Date",
            field: "startDate",
            sort: "asc",
            width: 60,
          },
          {
            label: "Checkout Date",
            field: "endDate",
            sort: "asc",
            width: 60,
          },
          {
            label: "Customer Name",
            field: "guestName",
            sort: "asc",
            width: 60,
          },
          {
            label: "Customer Email",
            field: "guestEmail",
            sort: "asc",
            width: 60,
          },
          {
            label: "Customer Mobile",
            field: "guestMobile",
            sort: "asc",
            width: 60,
          },
          {
            label: "No of Rooms",
            field: "rooms",
            sort: "asc",
            width: 60,
          },
          {
            label: "Room",
            field: "roomName",
            sort: "asc",
            width: 60,
          },
          {
            label: "Room Type",
            field: "roomType",
            sort: "asc",
            width: 60,
          },
          {
            label: "Amount",
            field: "finalOrderAmount",
            sort: "asc",
            width: 60,
          },
          {
            label: "Payment (Online/Cash)",
            field: "paymentMethodType",
            sort: "asc",
            width: 60,
          },
          {
            label: "Payment Status",
            field: "remove",
            sort: "asc",
            width: 100,
          },
        ],
        rows: [],
      },
    };
  }

  StatusChangeRequest(roomBookingId, isPaymentSuccess) {
    updatePaymentStatus(roomBookingId, isPaymentSuccess)
      .then((response) => {
        if (response.status !== 200) {
          toast.error(response.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        } else {
          toast.success(response.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
        this.getALlluser();
      })
      .catch((error) => {
        toast.error("error");
      });
  }

  getALlluser() {
    listRoomBookingRequest().then((result) => {
      this.setState({
        isLoaded: true,
        items: result.data,
        tableData: {
          ...this.state.tableData,
          rows: result.data,
          list: result.data.map((x) => {
            x.paymentMethodType = x.paymentMethodType === 1 ? "Cash" : "Online";
            x.isPaymentSuccess = x.isPaymentSuccess === true ? false : true;
            x.remove =
              x.paymentMethodType === "Cash" && x.isPaymentSuccess ? (
                <button
                  className="btn btn-danger"
                  onClick={() =>
                    this.StatusChangeRequest(
                      x._id.toString(),
                      x.isPaymentSuccess
                    )
                  }
                >
                  {"Pending"}
                </button>
              ) : (
                <>Complete</>
              );
            // x.remove = <button className="btn btn-danger"
            //     onClick={() => this.CancellationRequest(x._id.toString(), x.cancelled)}>
            //     {x.cancelled === true ? 'Not Approved' : 'Approved'}
            // </button>

            return x;
          }),
        },
      });
    });
  }

  componentDidMount() {
    this.getALlluser();
  }

  render() {
    const { handleResponse, items } = this.state;

    console.log("Items", items);

    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Breadcrumbs
              title=""
              breadcrumbItems={this.state.breadcrumbItems}
            />
            <Row>
              <Col xs={12}>
                <Card></Card>
              </Col>
            </Row>
            <Breadcrumbs
              title="Data Tables"
              breadcrumbItems={this.state.breadcrumbItems}
            />
            <Row>
              <Col xs={12}>
                <Card>
                  <CardBody>
                    <h4 className="card-title">Booking DataTable </h4>
                    <p className="card-title-desc"></p>
                    <MDBDataTable
                      responsive
                      bordered
                      data={this.state.tableData}
                    />
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
        <ToastContainer />
      </React.Fragment>
    );
  }
}

export default Booking;
