import React, { Component } from "react";
import {
  MDBDataTable,
  MDBTableHead,
  MDBTableBody,
  MDBTable,
  MDBBtn,
} from "mdbreact";
import Select from "react-select";
import {
  AvInput,
  Card,
  CardBody,
  Col,
  Form,
  Row,
  FormGroup,
  Label,
  Input,
  CustomInput,
  Button,
  Modal,
  Container,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import {
  createFood,
  getFoodCuisineList,
  removeFood,
  getFoodList,
  getRoomTypeList,
  updateFood,
  getFoodById,
  getFoodAddOnList,
} from "../../Endpoint/Admin/index";
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

import { ToastContainer, toast } from "react-toastify";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider from "react-bootstrap-table2-toolkit";

import { AvForm, AvField } from "availity-reactstrap-validation";

class FormMask extends Component {
  constructor(props) {
    super(props);
    this.state = {
      invalidFile: false,

      items: [],
      FoodCuisineData: [],
      FoodAddonData: [],
      isLoaded: false,
      updatemodal: false,
      modal: false,
      foodName: "",
      foodCuisine: "",
      description: "",
      availability: true,
      quantity: "",
      amount: "",
      discount: "",
      additionalCharges: "",
      foodImage: "",
      foodId: null,
      selectedFile: null,
      previewImage: [],
      availableAddOns: "",
      addons: "",
      success: {},
      breadcrumbItems: [
        { title: "", link: "#" },
        { title: " ", link: "#" },
      ],
      customchk: true,
      toggleSwitch: true,
      tableData: {
        columns: [
          {
            label: "Food Name",
            field: "foodName",
            sort: "asc",
            width: 60,
          },

          {
            label: "Update",
            field: "update",
            sort: "asc",
            width: 100,
          },

          {
            label: "Status",
            field: "remove",
            sort: "asc",
            width: 100,
          },
        ],
        rows: [],
      },
    };

    this.reader = new FileReader();
    this.handleChange = this.handleChange.bind(this);
    this.onFileChange = this.onFileChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.toggle = this.toggle.bind(this);
    this.ShortlistedUser = this.ShortlistedUser.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
    this.onChange = this.onChange.bind(this);
  }
  toggle() {
    this.setState({
      modal: !this.state.modal,
      foodName: "",
      foodCuisine: "",
      description: "",
      availability: "",
      quantity: "",
      amount: "",
      discount: "",
      additionalCharges: "",
      foodImage: "",
      selectedFile: "",
      previewImage: "",
      availableAddOns: "",
      invalidImage: null,
      foodId: "",
    });
  }
  updatetoggle() {
    this.setState({
      updatemodal: !this.state.updatemodal,
    });
  }

  onChange(selectedOptions) {
    // const selected = [];

    // let selectedOption = (selectedOptions);
    // for (let i = 0; i < selectedOption.length; i++) {

    //   selected.push(selectedOption[i].value)

    // }

    this.setState({
      availableAddOns: [...selectedOptions],
      // addons: selected
    });
  }

  ShortlistedUser() {
    this.setState({
      availability: !this.state.availability,
    });
  }

  handleCloseModal() {
    this.setState({
      updatemodal: false,
      modal: false,
    });
  }

  handleChange = (e) => {
    this.setState(
      {
        [e.target.id]: e.target.value,
      },
      () => {}
    );
  };

  onFileChange(event) {
    const imageFile = event.target.files;
    for (let i = 0; i < imageFile.length; i++) {
      if (!imageFile[i].name.match(/\.(jpg|jpeg|png)$/)) {
        this.setState({
          invalidImage: "Please select valid image.",
          previewImage: [...event.target.files].map(URL.createObjectURL),
        });
        return false;
      } else {
        this.setState({
          selectedFile: event.target.files,
          previewImage: [...event.target.files].map(URL.createObjectURL),
          invalidImage: null,
        });
      }
    }
  }

  FoodCuisineListList() {
    getFoodCuisineList()
      .then((result) => {
        this.setState({
          FoodCuisineData: result.data,
        });
      })
      .catch((error) => {
        console.log("error ---> ", error);
      });
  }
  FoodAddonList() {
    getFoodAddOnList()
      .then((result) => {
        this.setState({
          FoodAddonData: result.data,
        });
        const dropDownValue = this.state.FoodAddonData.map((response) => ({
          value: response._id,
          label: response.foodAddOnName,
        }));
        this.setState({
          dropDownOpt: dropDownValue,
        });
      })
      .catch((error) => {});
  }

  handleSubmit(e) {
    e.preventDefault();
    let addon = [];

    this.state.availableAddOns &&
      this.state.availableAddOns.map((each) => {
        addon.push(each.value);
        return each;
      });

    console.log(this.state.foodId);

    const formData = new FormData();

    formData.append("foodName", this.state.foodName);
    formData.append("foodCuisine", this.state.foodCuisine);
    formData.append("description", this.state.description);
    formData.append("availability", this.state.availability);
    formData.append("quantity", this.state.quantity);
    formData.append("amount", this.state.amount);
    formData.append("discount", this.state.discount);
    //formData.append("additionalCharges", this.state.additionalCharges);
    formData.append("foodId", this.state.foodId);
    formData.append("availableAddOns", addon);
    for (var x = 0; x < this.state.selectedFile.length; x++) {
      formData.append("foodImage", this.state.selectedFile[x]);
    }

    if (this.state.foodId == "" || this.state.foodId == null) {
      createFood(formData)
        .then((response) => {
          if (response.status !== 200) {
            toast.error(response.message, {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          } else {
            this.setState({
              handleResponse: {
                isSuccess: response.status === 200,
                message: "success",
                foodName: "",
                foodCuisine: "",
                description: "",
                availability: false,
                quantity: "",
                amount: "",
                discount: "",
                additionalCharges: "",
                foodImage: "",
                selectedFile: "",
                previewImage: "",
                reviews: "",
                foodId: "",
                availableAddOns: "",
              },
            });
            this.getALlluser();

            this.handleCloseModal();

            toast.success("Food added successfully");
          }
        })
        .catch((error) => {
          toast.error("error");
        });
    } else {
      updateFood(formData)
        .then((updateResults) => {
          if (updateResults.status !== 200) {
            toast.error(updateResults.message, {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          } else {
            this.setState({
              handleResponse: {
                isSuccess: updateResults.status === 200,
                message: "success",
                foodId: "",
              },
            });
            this.getALlluser();

            this.handleCloseModal();

            toast.success("Food updated successfully.");
          }
        })
        .catch((error) => {
          toast.error("error");
        });
    }
  }

  removeFood(id, isActive) {
    const data = {
      foodId: id,
      isActive: isActive,
    };

    removeFood(data)
      .then((results) => {
        if (results.status !== 200) {
          toast.error(results.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        } else {
          toast.success("Food status changed successfully");
        }
        this.getALlluser();
      })
      .catch((error) => {
        toast.error("error");
      });
  }

  getALlluser() {
    getFoodList().then((result) => {
      this.setState({
        isLoaded: true,
        items: result.data,
        tableData: {
          ...this.state.tableData,
          rows: result.data,
          list: result.data.map((x) => {
            x.isActive = x.isActive === true ? false : true;
            x.update = (
              <button
                className="btn btn-primary"
                onClick={() => this.editfood(x._id.toString(), x)}
              >
                Update
              </button>
            );
            x.remove = (
              <button
                className="btn btn-danger"
                onClick={() => this.removeFood(x._id.toString(), x.isActive)}
              >
                {x.isActive === true ? "Deactive" : "Active"}
              </button>
            );

            return x;
          }),
        },
      });
    });
  }
  editfood(id) {
    getFoodById(id)
      .then((results) => {
        console.log("edit", results);
        let copyfoodfile = [];
        let copyfoodrpreview = [];
        let addonscopy = [];
        for (let i = 0; i < results.data.foodImages.length; i++) {
          copyfoodrpreview.push(results.data.foodImages[i].image);
        }

        addonscopy =
          results.data.availableAddOns &&
          results.data.availableAddOns.map((response) => ({
            value: response._id,
            label: response.foodAddOnName,
          }));

        this.setState({
          foodName: results.data.foodName,
          previewImage: copyfoodrpreview,
          //selectedFile:results.data.foodImages[0].image,

          foodId: results.data._id,
          foodCuisine: results.data.foodCuisine._id,
          description: results.data.description,
          availability: results.data.availability,
          quantity: results.data.quantity,
          amount: results.data.amount,
          discount: results.data.discount,
          availableAddOns: addonscopy,
        });
      })
      .catch((error) => {
        console.log(error);
        toast.error("error");
      });
    this.toggle();
  }
  componentDidMount() {
    this.getALlluser();
    this.FoodCuisineListList();
    this.FoodAddonList();
  }

  render() {
    const { handleResponse, invalidImage, items, previewImage } = this.state;

    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Breadcrumbs
              title=""
              breadcrumbItems={this.state.breadcrumbItems}
            />

            <Row>
              <Col xs={12}>
                <Card>
                  <CardBody>
                    <Button color="primary" onClick={this.toggle}>
                      Add Food
                    </Button>
                    <Modal
                      isOpen={this.state.modal}
                      toggle={this.toggle}
                      className={this.props.className}
                    >
                      <ModalHeader toggle={this.toggle}>
                        Food Details
                      </ModalHeader>
                      <ModalBody>
                        <AvForm onValidSubmit={this.handleSubmit}>
                          <Row form>
                            <Col md={6}>
                              <FormGroup>
                                <Label for="email">Food Name</Label>
                                <AvField
                                  type="text"
                                  value={this.state.foodName}
                                  onChange={this.handleChange}
                                  id="foodName"
                                  name="foodName"
                                  required
                                />
                              </FormGroup>
                            </Col>
                            <Col md={6}>
                              <FormGroup>
                                <Label for="roomType">Food Cuisine</Label>
                                <Input
                                  type="select"
                                  name="foodCuisine"
                                  id="foodCuisine"
                                  value={this.state.foodCuisine}
                                  onChange={this.handleChange}
                                  required
                                >
                                  <option>Select FoodCuisine</option>
                                  {this.state.FoodCuisineData.map((e, key) => {
                                    return (
                                      <option key={key} value={e._id}>
                                        {e.cuisineName}
                                      </option>
                                    );
                                  })}
                                </Input>
                              </FormGroup>
                            </Col>
                          </Row>
                          <Row form>
                            <Col md={6}>
                              <FormGroup>
                                <Label for="username">Description</Label>
                                <AvField
                                  type="text"
                                  value={this.state.description}
                                  onChange={this.handleChange}
                                  id="description"
                                  name="description"
                                />
                              </FormGroup>
                            </Col>
                            <Col md={6}>
                              <FormGroup
                                style={{ paddingLeft: 20, paddingTop: 40 }}
                              >
                                <Label check>
                                  <Input
                                    type="checkbox"
                                    checked={this.state.availability}
                                    onChange={this.ShortlistedUser}
                                  />
                                  Availability
                                </Label>
                              </FormGroup>
                            </Col>
                          </Row>
                          <Row form>
                            <Col md={6}>
                              <FormGroup>
                                <Label for="username">Quantity</Label>
                                <AvField
                                  type="text"
                                  value={this.state.quantity}
                                  onChange={this.handleChange}
                                  id="quantity"
                                  name="quantity"
                                  required
                                />
                              </FormGroup>
                            </Col>
                            <Col md={6}>
                              <FormGroup>
                                <Label for="University">Amount (in usd)</Label>
                                <AvField
                                  type="number"
                                  min={1}
                                  value={this.state.amount}
                                  onChange={this.handleChange}
                                  id="amount"
                                  name="amount"
                                  required
                                />
                              </FormGroup>
                            </Col>
                          </Row>
                          <Row form>
                            <Col md={6}>
                              <FormGroup>
                                <Label for="username">
                                  Discount Amount (in usd)
                                </Label>
                                <AvField
                                  type="number"
                                  min={0}
                                  value={this.state.discount}
                                  onChange={this.handleChange}
                                  id="discount"
                                  name="discount"
                                />
                              </FormGroup>
                            </Col>
                            <Col md={6}>
                              <FormGroup>
                                {/* <Label for="roomType">AvailableAddOns</Label>

                                <Select
                                  options={this.state.dropDownOpt}
                                  onChange={this.onChange.bind(this)}
                                  isMulti
                                /> */}
                                <Label
                                  htmlFor="example-number-input"
                                  className="col-md-12 col-form-label"
                                >
                                  Food Image{" "}
                                </Label>

                                <div className="custom-file">
                                  <AvField
                                    tag={CustomInput}
                                    name="file"
                                    id="file"
                                    type="file"
                                    accept="image/*"
                                    onChange={this.onFileChange}
                                    multiple
                                  />
                                  <Label
                                    className="custom-file-label"
                                    htmlFor="customFile"
                                  >
                                    Choose file
                                  </Label>
                                </div>
                                {(previewImage || []).map((url) => (
                                  <img
                                    style={{
                                      borderRadius: "50%",
                                      width: 80,
                                      height: 80,
                                      // background: "red",
                                      display: "block",
                                    }}
                                    className="img-fluid"
                                    src={url}
                                    alt=""
                                  />
                                ))}
                              </FormGroup>
                            </Col>
                          </Row>

                          <Row form>
                            <Col>
                              <Label for="roomType">AvailableAddOns</Label>

                              <Select
                                className="dropdown"
                                placeholder="Select Option"
                                options={this.state.dropDownOpt}
                                value={this.state.availableAddOns}
                                onChange={this.onChange}
                                isMulti
                                isClearable
                              />
                              {/* <Label htmlFor="example-number-input" className="col-md-12 col-form-label">Food Image  </Label>

                              <div className="custom-file">

                                <AvField
                                  tag={CustomInput}
                                  name="file"
                                  id="file"
                                  type="file"
                                  accept="image/*"
                                  onChange={this.onFileChange}
                                  multiple
                                  required
                                />
                                <Label className="custom-file-label" htmlFor="customFile">Choose file</Label>
                              </div>
                              {(previewImage || []).map(url => (
                                <img style={{
                                  borderRadius: "50%",
                                  width: 80,
                                  height: 80,
                                  // background: "red",
                                  display: "block"
                                }} className="img-fluid" src={url} alt="" />
                              ))} */}
                            </Col>
                          </Row>
                          <div className="text-center mt-4">
                            {invalidImage && (
                              <p className="error">{invalidImage}</p>
                            )}
                            <span> {this.state.selectedFileName}</span>
                            {handleResponse && (
                              <p
                                className={
                                  handleResponse.isSuccess ? "success" : "error"
                                }
                              >
                                {handleResponse.message}
                              </p>
                            )}
                            <Button color="primary" size="sm">
                              Submit
                            </Button>{" "}
                          </div>
                        </AvForm>
                      </ModalBody>
                    </Modal>
                  </CardBody>
                </Card>
              </Col>
            </Row>

            <Breadcrumbs
              title="Data Tables"
              breadcrumbItems={this.state.breadcrumbItems}
            />

            <Row>
              <Col xs={12}>
                <Card>
                  <CardBody>
                    <h4 className="card-title">Food DataTable </h4>
                    <p className="card-title-desc"></p>

                    <MDBDataTable
                      responsive
                      bordered
                      data={this.state.tableData}
                    />
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
        <ToastContainer />
      </React.Fragment>
    );
  }
}

export default FormMask;
