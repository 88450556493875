import React, { Component } from "react";
import {
  MDBDataTable,
  MDBTableHead,
  MDBTableBody,
  MDBTable,
  MDBBtn,
} from "mdbreact";
import {
  AvInput,
  Card,
  CardBody,
  Col,
  Form,
  Row,
  FormGroup,
  Label,
  Input,
  CustomInput,
  Button,
  Modal,
  Container,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import {
  createFoodCuisine,
  updateFoodCuisine,
  getFoodCuisineById,
  getFoodCuisineList,
  removeFoodCuisine,
  updateFoodAddOn,
} from "../../Endpoint/Admin/index";
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

import { ToastContainer, toast } from "react-toastify";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider from "react-bootstrap-table2-toolkit";

import { AvForm, AvField } from "availity-reactstrap-validation";

class FormElements extends Component {
  constructor(props) {
    super(props);
    this.state = {
      invalidFile: false,
      previewImage: undefined,
      items: [],
      isLoaded: false,
      updatemodal: false,
      modal: false,
      cuisineName: "",
      foodCuisineId: null,
      selectedFile: null, // to store selected file
      handleResponse: null, // handle the API response
      imageUrl: null, // to store uploaded image path
      invalidImage: null,
      selectedFileName: "",

      success: {},
      breadcrumbItems: [
        { title: "", link: "#" },
        { title: " ", link: "#" },
      ],
      customchk: true,
      toggleSwitch: true,
      tableData: {
        columns: [
          {
            label: " Cuisine Name",
            field: "cuisineName",
            sort: "asc",
            width: 60,
          },
          {
            label: "Cuisine Image",
            field: "cuisineImage",
            sort: "asc",
            width: 150,
          },
          {
            label: "Update",
            field: "update",
            sort: "asc",
            width: 100,
          },

          {
            label: "Remove",
            field: "remove",
            sort: "asc",
            width: 100,
          },
        ],
        rows: [],
      },
    };
    this.reader = new FileReader();
    this.handleChange = this.handleChange.bind(this);
    this.onFileChange = this.onFileChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.toggle = this.toggle.bind(this);
    this.getALlluser = this.getALlluser.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
  }
  toggle() {
    this.setState({
      modal: !this.state.modal,
      cuisineName: "",
      foodCuisineId: "",
      previewImage: "",
      invalidImage: "",
      selectedOption: null,
    });
  }
  updatetoggle() {
    this.setState({
      updatemodal: !this.state.updatemodal,
    });
  }

  handleCloseModal() {
    this.setState({
      updatemodal: false,
      modal: false,
    });
  }

  onFileChange(event) {
    const imageFile = event.target.files[0];

    if (!imageFile.name.match(/\.(jpg|jpeg|png)$/)) {
      this.setState({
        invalidImage: "Please select valid image.",
        previewImage: URL.createObjectURL(event.target.files[0]),
      });
      return false;
    } else {
      this.setState({
        selectedFile: event.target.files[0],
        previewImage: URL.createObjectURL(event.target.files[0]),
        invalidImage: null,
      });
    }
  }

  handleChange = (e) => {
    this.setState(
      {
        [e.target.id]: e.target.value,
      },
      () => {}
    );
  };

  handleSubmit(e) {
    e.preventDefault();

    const formData = new FormData();
    formData.append("cuisineName", this.state.cuisineName);
    formData.append("foodCuisineId", this.state.foodCuisineId);

    formData.append("foodCuisine", this.state.selectedFile);

    if (this.state.foodCuisineId == "" || this.state.foodCuisineId == null) {
      createFoodCuisine(formData)
        .then((response) => {
          if (response.status !== 200) {
            toast.error(response.message, {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          } else {
            this.setState({
              handleResponse: {
                isSuccess: response.status === 200,
                message: "success",
                cuisineName: "",
                selectedFile: null, // to store selected file
                handleResponse: null, // handle the API response
                imageUrl: null, // to store uploaded image path
                invalidImage: null,
                selectedFileName: "",
                previewImage: "",
                foodCuisineId: "",
              },
            });
            this.getALlluser();

            this.handleCloseModal();

            toast.success(" Food cuisine added successfully");
          }
        })
        .catch((error) => {
          toast.error("error");
        });
    } else {
      updateFoodCuisine(formData)
        .then((updateResults) => {
          if (updateResults.status !== 200) {
            toast.error(updateResults.message, {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          } else {
            this.setState({
              handleResponse: {
                isSuccess: updateResults.status === 200,
                message: "success",
                foodCuisineId: "",
              },
            });
            this.getALlluser();

            this.handleCloseModal();

            toast.success("Food cuisine  updated successfully");
          }
        })
        .catch((error) => {
          toast.error("error");
        });
    }
  }

  getALlluser() {
    getFoodCuisineList().then((result) => {
      this.setState({
        isLoaded: true,
        items: result.data,
        tableData: {
          ...this.state.tableData,
          rows: result.data,
          list: result.data.map((x) => {
            x.update = (
              <button
                className="btn btn-primary"
                onClick={() => this.editfoodCuisine(x._id.toString(), x)}
              >
                Update
              </button>
            );
            x.remove = (
              <button
                className="btn btn-danger"
                onClick={() => this.removefoodcuisine(x._id.toString())}
              >
                Remove
              </button>
            );
            x.cuisineImage = (
              <img src={x.cuisineImage} width="50" height="50" />
            );
            return x;
          }),
        },
      });
    });
  }

  componentDidMount() {
    this.getALlluser();
  }

  editfoodCuisine(id) {
    getFoodCuisineById(id)
      .then((results) => {
        this.setState({
          cuisineName: results.data.cuisineName,
          previewImage: results.data.cuisineImage,
          selectedFile: results.data.cuisineImage,
          foodCuisineId: results.data._id,
        });
      })
      .catch((error) => {
        toast.error("error");
      });
    this.toggle();
  }

  removefoodcuisine(id) {
    removeFoodCuisine(id)
      .then((results) => {
        this.getALlluser();
      })
      .catch((error) => {
        toast.error("error");
      });
  }

  render() {
    const { handleResponse, invalidImage, items, previewImage } = this.state;

    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Breadcrumbs
              title=""
              breadcrumbItems={this.state.breadcrumbItems}
            />

            <Row>
              <Col xs={12}>
                <Card>
                  <CardBody>
                    <Button color="primary" onClick={this.toggle}>
                      Add Food Cuisine
                    </Button>
                    <Modal
                      isOpen={this.state.modal}
                      toggle={this.toggle}
                      className={this.props.className}
                    >
                      <ModalHeader toggle={this.toggle}>
                        Food Cuisine Details
                      </ModalHeader>
                      <ModalBody>
                        <AvForm onValidSubmit={this.handleSubmit}>
                          <Row form>
                            <Col md={12}>
                              <FormGroup>
                                <Label for="email"> Cuisine Name</Label>
                                <AvField
                                  type="text"
                                  value={this.state.cuisineName}
                                  onChange={this.handleChange}
                                  id="cuisineName"
                                  name="cuisineName"
                                  required
                                />
                              </FormGroup>
                            </Col>
                          </Row>

                          <Row form>
                            <Col md={10}>
                              <Label
                                htmlFor="example-number-input"
                                className="col-md- col-form-label"
                              >
                                Food Cuisine{" "}
                              </Label>

                              <div className="custom-file">
                                <AvField
                                  tag={CustomInput}
                                  name="file"
                                  id="file"
                                  type="file"
                                  accept="image/*"
                                  onChange={this.onFileChange}
                                />
                                <Label
                                  className="custom-file-label"
                                  htmlFor="customFile"
                                >
                                  Choose file
                                </Label>
                              </div>
                              {previewImage && (
                                <div>
                                  <img
                                    style={{
                                      borderRadius: "50%",
                                      width: 80,
                                      height: 80,
                                      // background: "red",
                                      display: "block",
                                    }}
                                    className="img-fluid"
                                    src={previewImage}
                                    alt=""
                                  />
                                </div>
                              )}
                            </Col>
                          </Row>

                          <div className="text-center mt-4">
                            {invalidImage && (
                              <p className="error">{invalidImage}</p>
                            )}
                            <span> {this.state.selectedFileName}</span>
                            {handleResponse && (
                              <p
                                className={
                                  handleResponse.isSuccess ? "success" : "error"
                                }
                              >
                                {handleResponse.message}
                              </p>
                            )}
                            <Button color="primary" size="sm">
                              Submit
                            </Button>{" "}
                          </div>
                        </AvForm>
                      </ModalBody>
                    </Modal>
                  </CardBody>
                </Card>
              </Col>
            </Row>

            <Breadcrumbs
              title="Data Tables"
              breadcrumbItems={this.state.breadcrumbItems}
            />

            <Row>
              <Col xs={12}>
                <Card>
                  <CardBody>
                    <h4 className="card-title">Food Cuisine Datatable </h4>
                    <p className="card-title-desc"></p>

                    <MDBDataTable
                      responsive
                      bordered
                      data={this.state.tableData}
                    />
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
        <ToastContainer />
      </React.Fragment>
    );
  }
}

export default FormElements;
